import React, { useState } from "react";

const CustomPopover = ({ aucEnable,
    setAucEnable,
    binEnable,
    setBinEnable,
    aucPrice,
    setAucPrice,
    binPrice,
    setBinPrice,
    startASAP,
    setStartASAP,
    startDate,
    setStartDate,
    endDate,
    startTime,
    setEndDate,
    setStartTime,
    endTime,
    setEndTime}) => {

  return (
    <fieldset className="p-3" style={{ borderStyle: "solid", borderRadius: "5px", borderColor: "black", borderWidth: "2px" }}>
      <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}>
        {" "}
        Pricing & Purchase Details{" "}
      </legend>
      <div className="p-1">
        <div className="p-0" style={{ backgroundColor: "#E2E2E2" }}>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="col-4 exclude-col">
                  <p className="ms-3 m-0 required">Enable timed auction</p>
                </td>
                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                  <div className="row gx-4 gy-2 align-items-center">
                    <div className="col-auto">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="auction-checkmark"
                        checked={aucEnable}
                        onChange={(e) => {
                          setAucEnable(!aucEnable);
                          setAucPrice("");
                        }}
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Auction input"
                        placeholder="Enter starting price"
                        id="auction-price"
                        disabled={!aucEnable}
                        value={aucPrice}
                        onChange={(e) => setAucPrice(e.target.value.replace(/[^0-9]/g, ""))}
                        style={aucEnable ? { backgroundColor: "white" } : { backgroundColor: "#E2E2E2" }}
                      />
                    </div>
                  </div>

                </td>
              </tr>

              <tr>
                <td className="col-4 exclude-col">
                  <p className="ms-3 m-0 required">Enable buy-it-now </p>
                </td>
                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                  <div className="row gx-4 gy-2 align-items-center">
                    <div className="col-auto">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="bin-checkmark"
                        checked={binEnable}
                        onChange={(e) => {
                          setBinEnable(!binEnable);
                          setBinPrice("");
                        }}
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="BIN input"
                        placeholder="Enter BIN price"
                        id="bin-price"
                        disabled={!binEnable}
                        value={binPrice}
                        onChange={(e) => setBinPrice(e.target.value.replace(/[^0-9]/g, ""))}
                        style={binEnable ? { backgroundColor: "white" } : { backgroundColor: "#E2E2E2" }}
                      />
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>

          <div className="row m-4">
            <div className="col-md text-center justify-content-center">
              <div className="form-check-inline">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="startdate-checkmark"
                  checked={startASAP}
                  onChange={() => setStartASAP(!startASAP)}
                />
              </div>
              <label className="form-check-label required" htmlFor="startdate-checkmark">
                Start Listing ASAP
              </label>
              <small className="row text-center justify-content-center">De-select for custom start date/time.</small>
            </div>
          </div>

          <table className="table table-bordered">
            <tbody>
              {!startASAP && (
                <tr>
                  <td className="col-4 exclude-col">
                    <p className="ms-3 m-0 required">Start Date & Time </p>
                  </td>
                  <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                    <div className="row gx-4 gy-2 align-items-center">
                      <div className="col-auto">
                        <input
                          type="date"
                          id="start-date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          style={{ backgroundColor: "white" }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="time"
                          id="start-time"
                          className="form-control"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ backgroundColor: "white" }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="col-4 exclude-col">
                  <p className="ms-3 m-0 required">End Date & Time </p>
                </td>
                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                  <div className="row gx-4 gy-2 align-items-center">
                    <div className="col-auto">
                      <input
                        type="date"
                        id="end-date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="time"
                        id="end-time"
                        className="form-control"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </fieldset>
  );
};

export default CustomPopover;
