import React from 'react'
import './Faqs.css';

function FAQsComponent() {
    return (
        <>
            <div className='container-fluid p-0'>
                <div className='mainContainer'>
                    <div className='row'>
                        <div className='col-12 mb-10 '>
                            <div className='textContainer'>
                                <h1>FAQ's</h1>

                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                                        What do I need to sign up on NY Cannabis Exchange?
                                        </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        You need to be a licensed NYS cultivator, processor, or dispensary.  As long as you’re licensed by NYS you’ll need to insert your license number along with all your personal details associated with the State issued license.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-controls="collapseTwo">
                                        I don’t have a license in NY, can I join the exchange?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        No, the site is exclusively for the NYS legal market.  Only licensed B2B parties may join and engage in the auctions.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        How does delivery work?
                                        </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Delivery is arranged between the buyer and seller.  Each seller has transport options listed in their auction listing, and it is the responsibility of the buyer to arrange for transportation at the close of the auction.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        What fees are associated with being on the NY Cannabis Exchange?
                                        </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Currently there is only a 1% fee, charged to BOTH the buyer and seller at the close of the auction.   This introductory rate may increase in the future.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                        Why don’t people circumvent the auction and go direct?
                                        </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        There are several reasons.  The first is that a seller wants to get the MOST for their product, not simply unload to one of many interested buyers.  For buyers there is the security of buying through the site if issues occur with the transaction.  Finally, anyone that attempts to circumvent a posted auction will be banned from future engagement on the platform.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                        How do I pay?
                                        </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Since there are many restrictions around cannabis we have chosen not to act as an intermediary for payments.  Each seller lists their method of acceptable payment (cash, check, certified check, etc) and it is the responsibility of the buyer to pay and complete the auction transaction.
                                        </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                        Can other employees at our facility join?
                                        </button>
                                        </h2>
                                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                        Yes, employees and those with express permission to act on behalf of the license holder may engage in the marketplace, so long as they are working under a license.
                                        </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQsComponent