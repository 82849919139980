import React, { useState, useEffect } from "react";
import "./Modal.css";
import Modal from 'react-modal';

interface ModalProps {
    showModal: boolean
    headerText: string
    closeCallback: () => void
    children?: React.ReactNode;
}

const ModalComponent = (props: ModalProps) => {
    const { showModal, headerText, closeCallback } = props;

    const [modalIsOpen, setModal] = React.useState(showModal);

    // if props value has changed, override local state with props value (a.k.a getDerivedStateFromProps)
    useEffect(() => setModal(showModal), [showModal]);

    const closeModal = () => {
        setModal(false);
    }

    useEffect(() => {// component did mount
    }, []);
    const afterOpenModal = () => { }

    return (
        <React.Fragment>
            {showModal && <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={closeModal}
                onAfterClose={closeCallback}
                portalClassName={'NYCE-modal'}
                ariaHideApp={false}
            >
                {/* Header */}
                <div className="NYCE-modal-header row justify-content-center">
                    {headerText}
                </div>
                <button type="button" className="NYCE-modal-close-btn btn-close" aria-label="Close" onClick={() => closeModal()}></button>

                {/* Body */}
                <div className="NYCE-modal-body">
                    {props.children}
                </div>
                {/* Footer */}
                {/* yet to add flow */}
            </Modal>}
        </React.Fragment>
    );
};

export default ModalComponent