import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { Tag, Row, Col, Card } from 'antd';
import AntTable from "./tables/AntTable.jsx";



const AdminLogs = () => {
    const navigate = useNavigate();

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}logs`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => setLogs(data.data))
            .catch((err) => console.log(err));
    }, []);


    const columnInfo = [{name: "ID", width: "10%"}, {name: "Datetime", width: "15%"}, {name: "Action", width: "10%"}, {name: "Username", width: "10%"}, {name: "Description", width: "30%"}]
    const data = logs.map(item => {
        return {
            key: item.id,
            id: item.id,
            datetime: item.datetime,
            action: item.action,
            username: item.username,
            description: item.log
        }
       })


    return(
        <div style = {{padding: "20px"}}>

            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Logs </h5>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actions = {[]} />
                </Col>
            </Row>
        </div>
    )
};


export default AdminLogs
