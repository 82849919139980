import React, { useState, useEffect } from "react";
import logo from "../../assets/images/new-york-cannabis-exchange.png";
import axios from "axios";
import { Link, useNavigate, BrowserRouter as Router, useLocation } from "react-router-dom";
import { Tooltip } from 'antd';


import { getCsrfCookieValue } from "../../utils";
import { passwordValidation } from "../../utils"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  

function PasswordResetComponent() {
    const query = useQuery();
    const userName = query.get("userName")
    const passwordResetToken = query.get("passwordResetToken")
    const navigate = useNavigate();

    const [urlValidated, setUrlValidated] = useState(0);
    const [userPassword, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errAlert, setErrAlert] = useState("");
    const [pwdAlert, setPwdAlert] = useState("");
    const [confirmPwdAlert, setConfirmPwdAlert] = useState("");

    // let { userName, passwordResetToken } = useParams();


    useEffect(() => {
      valdiateUrl();
    }, [])

    const valdiateUrl = () => {
        axios
            .get(`${process.env.REACT_APP_URL}password/reset/validate/${userName}/${passwordResetToken}`, {} )
            .then((res) => {
                console.log("Validation successful");
                setUrlValidated(1);
            })
            .catch((err) => {
                console.log("Valdiation failed");
                setUrlValidated(-1);
            });
    }
    const submitPasswordResetEvent = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            submitPasswordReset();
        }
    };

    const submitPasswordReset = () => {
        if(pwdAlert || confirmPwdAlert) return;
        
        const data = {
            "username": userName,
            "new-password": confirmPassword,
            "password-reset-token": passwordResetToken
        };
        if(userPassword == confirmPassword){
            if(passwordValidation(userPassword).status){
                axios
                    .post(`${process.env.REACT_APP_URL}password/reset`, data, {
                        headers: { "X-CSRFToken": getCsrfCookieValue() },
                        withCredentials: true,
                    })
                    .then((res) => {
                        navigate("/password/reset/success", { refresh: true });
                    })
                    .catch((err) => {
                        setErrAlert(err.response.data);
                    });
            }
            setErrAlert(passwordValidation(userPassword).msg);
        }

    }

    const handlePassword = (value) => {
        setPassword(value);
        validatePassword(value);
    }

    const handleConfirmPassword = (value) => {
        setConfirmPassword(value);
        validateConfirmPassword(value);
    }

    const validatePassword = (value) => {
        if (value === "") {
            setPwdAlert("Please enter a password");
        }else if(!passwordValidation(value).status){
            setPwdAlert(passwordValidation(value).msg);
        }else{
            setPwdAlert("");
        }
    }

    const validateConfirmPassword = (value) => {
        if (value === "") {
            setConfirmPwdAlert("Please enter a password");
        }else if (userPassword != value) {
            setConfirmPwdAlert("Password does not match");
        }else{
            setConfirmPwdAlert("");
        }
    }


    return (
        <>
            {
                urlValidated == 0 ? (
                <p className="text-center m-5 "> Valdiating url, please wait... </p>
                ) :  urlValidated == -1 ? (
                    <div>
                <p className="text-center m-5 " style = {{color: "red"}}> This password reset link has been expired or invalid. </p>
                <Link to="/password/reset/request">
                <p className="text-center m-5 "> Click here to request new one</p>
                </Link></div>
                ): (
                    <>
                        <div className="container-fluid p-0">
                            <div className="mainContainer">
                                <div className="row">
                                    <div className="Auth-form-container">
                                        <form className="Auth-form">
                                            <div className="Auth-form-content">
                                                <h3 className="Auth-form-title">Set New Password</h3>
                                                
                                                <div className="form-group mt-3 ">
                                                    <div style = {{display: "flex", justifyContent: "space-between"}}>
                                                        <p className="label m-0">Password *</p>
                                                        <Tooltip style = {{float: "end"}} title={
                                                            <>
                                                                Password requirements: <br/>
                                                                &#x2022; Password Length must be between 8 and 64 <br/>
                                                                &#x2022; Contains at least one small alphabet<br/>
                                                                &#x2022; Contains at least one capital alphabet <br/>
                                                                &#x2022; Contains at least one digit <br/>
                                                                &#x2022; Contains no whitespaces <br/>
                                                                &#x2022; Contains at least one special character
                                                            </>
                                                        }>
                                                            <span>
                                                                <i class="fa fa-info-circle"></i>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        className="form-control mt-1"
                                                        placeholder="Enter password"
                                                        value={userPassword}
                                                        onChange={(e) => handlePassword(e.target.value)}
                                                    />
                                                    <p className="error">{pwdAlert}</p>
                                                    
                                                </div>

                                                <div className="form-group mt-3 ">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control mt-1"
                                                        placeholder="Confirm password"
                                                        value={confirmPassword}
                                                        onChange={(e) => handleConfirmPassword(e.target.value)}
                                                        onKeyDown={(e) => submitPasswordResetEvent(e)}
                                                    />
                                                    <p className="error">{confirmPwdAlert}</p>
                                                </div>


                                                <div className="d-grid gap-2 mt-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={submitPasswordReset}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>

                                                <p className="error mt-2">{errAlert}</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default PasswordResetComponent