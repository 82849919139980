import React from 'react';
import { Link } from "react-router-dom";
function PasswordResetRequestSentComponent() {
    return (
        <>
            <div className="card vw-100">
                <div className="card-body text-center">Thanks for requesting a password reset. Please check your email for further instructions.   If you do not receive an email within a few minutes, please check your spam folder or contact us.
                <br></br>
                <Link to="/">
                    <p className="log-in text-right mt-2">Go to Home</p>
                </Link>
                </div>

            </div>
        </>
    )
}

export default PasswordResetRequestSentComponent