import React, { useState, useEffect } from "react";
import logo from "../../assets/images/new-york-cannabis-exchange.png";
import "./Login.css";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";

import { getCsrfCookieValue } from "../../utils";
function LoginComponent(props) {
    const navigate = useNavigate();

    const [loginResponse, setLoginResponse] = useState({});
    const [userName, setUserName] = useState("");
    const [userPassword, setPassword] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);

    const [errAlert, setErrAlert] = useState("");
    function loginUser() {
        const data1 = {
            username: userName,
            password: userPassword,
        };

        axios
            .post(`${process.env.REACT_APP_URL}login/`, data1, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            })
            .then((res) => {
                props.updateOnLoginCheck(res.data.business_type,userName);

                let params = (new URL(document.location)).searchParams;
                let next = params.get("next") ?? "/listings";
                navigate(next, { refresh: true });
            })
            .catch((err) => {
                setErrAlert(err.response.data);
            });
    }

    // Only returning 1 string error - no need for k/v pair and multiple inputs.

    const loginUserEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            loginUser();
        }
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="mainContainer">
                    <div className="row">
                        <div style={{ textAlign: "center" }}>
                            <h1>Welcome to NYCE!</h1>
                            <img src={logo} width="300" height="225" alt="NYCE" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="Auth-form-container">
                            <form className="Auth-form">
                                <div className="Auth-form-content">
                                    <h3 className="Auth-form-title">Sign In</h3>
                                    <div className="form-group mt-3">
                                        <label>Username</label>
                                        <input
                                            type="email"
                                            className="form-control mt-1"
                                            placeholder="Enter username"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mt-3 ">
                                        <label>Password</label>
                                        <input
                                            type={togglePassword === true ? "text" : "password"}
                                            className="form-control mt-1"
                                            placeholder="Enter password"
                                            value={userPassword}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={(e) => loginUserEnter(e)}
                                        />
                                        {/* <div className="input-group-btn"> */}
                                        {/* <button  onClick={() => setTogglePassword(!togglePassword)}>
                                    {
                                        togglePassword === false ?
                                            <i className="fa fa-eye-slash"></i> :
                                            <i className="fa fa-eye"></i>
                                    }
                                </button> */}
                                        {/* </div> */}
                                        <p className="error mt-2">{errAlert}</p>
                                    </div>
                                    <div className="d-grid gap-2 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => loginUser()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <div className="d-flex">
                                        <Link to="/password/reset/request" className="display-inline-flex mt-2">{'Forgot Password?'}</Link>
                                    </div>
                                    <div className="d-flex">
                                        <Link to="/register" className="display-inline-flex mt-2">{'Create Account'}</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginComponent;
