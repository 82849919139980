import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Button, Input, Popover, Space } from "antd";
import logo from "../../assets/images/nyce-logo-light.png";
import { StorageState, getCsrfCookieValue } from "../../utils";
import ContactUsComponent from "./ContactUs";
const { Search } = Input;
interface IProps {
  sessionState: StorageState;
  updateOnLogout: () => void;
  navSearchListing: (e: string) => void;
}

const _active_style = (props: { isActive: boolean }) => ({
  borderBottom: props.isActive ? "3px solid #ffffff" : "none",
  paddingBottom: props.isActive ? "0.25rem" : "0.5rem",
});

const Navbar = (props: IProps) => {
  const { sessionState, updateOnLogout, navSearchListing } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isHomeActive, setHomeActive] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState("");
  const logout = () => {
    axios
      .get(`${process.env.REACT_APP_URL}logout`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((res) => {
        // Clear the local session storage variable as well (Django handles removing cookie)
        updateOnLogout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const navSearchListing = (payload:any) => {

  //   };
  useEffect(() => {
    const isHome = location.pathname === "/";
    setHomeActive(isHome);
  }, [location.pathname]);
  const onClickNavButtons = () => {
    setInputValue("");
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand p-0 me-2" to="/">
          <img src={logo} width="50" alt="NYCE" />
          {/* NYCE */}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* List of various options */}
        <div className="collapse navbar-collapse" id="navbarsExample04">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {/* Home */}
            <li className="nav-item">
              <NavLink
                onClick={onClickNavButtons}
                to="/"
                className="nav-link active"
                style={_active_style({ isActive: isHomeActive && sessionState.loggedIn })}>
                {sessionState.loggedIn ? "Home" : "New York Cannabis Exchange"}
              </NavLink>
            </li>

            {/*  */}
            {sessionState.loggedIn && (
              <>
                {/* View Listings */}
                <li className="nav-item">
                  <NavLink onClick={onClickNavButtons} to="/listings" className="nav-link active" style={_active_style}>
                    {"Listings"}
                  </NavLink>
                </li>

                {/* View Bids */}
                <li className="nav-item">
                  <NavLink onClick={onClickNavButtons} to="/bids" className="nav-link active" style={_active_style}>
                    {"My Bids"}
                  </NavLink>
                </li>

                {/* View Purchased */}
                <li className="nav-item">
                  <NavLink onClick={onClickNavButtons} to="/purchased" className="nav-link active" style={_active_style}>
                    {"My Purchases"}
                  </NavLink>
                </li>

                {!sessionState.isDispensary && (
                  <>
                    {/* Only render Create Listing and My Listings if NOT dispensary! */}
                    <li className="nav-item">
                      <NavLink onClick={onClickNavButtons} to="/create" className="nav-link active" style={_active_style}>
                        {"Create Listing"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink onClick={onClickNavButtons} to="/mylistings" className="nav-link active" style={_active_style}>
                        {"My Listings"}
                      </NavLink>
                    </li>
                  </>
                )}

                {sessionState.isAdmin && (
                  <>
                    {/* Admin */}
                    <li className="nav-item">
                      <NavLink onClick={onClickNavButtons} to="/admin" className="nav-link active" style={_active_style}>
                        {"Admin"}
                      </NavLink>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>

          <ul className="navbar-nav ms-auto mb-2 mb-md-0">
            {!sessionState.loggedIn && (
              <>
                {" "}
                {!sessionState.loggedIn && (
                  <li className="nav-item">
                    <Space direction="vertical">
                      <Search placeholder="login to search" style={{ width: 180 }} />
                    </Space>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink onClick={onClickNavButtons} to="/register" className="nav-link active" style={_active_style}>
                    {"Register"}
                  </NavLink>
                </li>
                <li className="nav-item" id="login">
                  <NavLink onClick={onClickNavButtons} to="/login" className="nav-link active" style={_active_style}>
                    {"Login"}
                  </NavLink>
                </li>
              </>
            )}
            {sessionState.loggedIn && (
              <li className="nav-item">
                <Space direction="vertical">
                  <Search
                    placeholder="Search Auction "
                    value={inputValue}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    onSearch={navSearchListing}
                    style={{ width: 170 }}
                  />
                </Space>
              </li>
            )}

            <li className="nav-item" id="contactus">
              <ContactUsComponent />
            </li>
          </ul>

          {sessionState.loggedIn && (
            <Popover
              title=""
              trigger="click"
              content={
                <>
                  <Button type="primary" onClick={() => navigate("/settings")} style={{ width: "100%", marginBottom: "10px" }}>
                    {"My Profile"}
                  </Button>
                  <br></br>
                  <Button type="primary" danger style={{ width: "100%" }} onClick={logout}>
                    {"Log Out"}
                  </Button>
                </>
              }>
              <span style={{ color: "white", paddingRight: 10, textDecoration: "none", cursor: "pointer" }}>{"Settings"}</span>
            </Popover>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
