import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/new-york-cannabis-exchange.png";
import "./Landing.css";
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";
import CarouselComponent from "./Carousel.js";
import { Button, Input, Space } from "antd";
import { IntroVideoComponent } from "../AboutUs/IntroVideo";
const { Search } = Input;

function Home(props) {
  const { sessionState, navSearchListing } = props;
  const navigate = useNavigate();
  // favorites use a different API route method w/ promises
  const [favoritesData, setFavoritesData] = useState([]);

  // hot auctions and upcoming auctions return the listing data directly in the one call.
  const [hotAuctions, setHotAuctions] = useState([]);
  const [upcomingAuctions, setUpcomingAuctions] = useState([]);

  const getFavoritesList = async () => {
    return await axios
      .get(`${process.env.REACT_APP_URL}getFavoritesList`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      // .get('${process.env.REACT_APP_URL}checkFromFavorites', {params: {user_name: user_name, product_id: product_id}})

      .then((res) => {
        setFavoritesData(res.data);
      });
    // .catch((err) => console.log(err));
  };

  const getHotAuctions = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}hotlistings`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data);
        setHotAuctions(res.data);
      });
  };

  const getUpcomingAuctions = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}upcominglistings`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data);
        setUpcomingAuctions(res.data);
      });
  };

  useEffect(() => {
    (async () => {
      await getHotAuctions();
      await getUpcomingAuctions();

      // Having this line before getHotAuctions and getUpcomingListings breaks functionality
      // I think it is because it redirects BEFORE getting to call getHotAuctions, etc. and then they don't ever get run.
      await getFavoritesList();
    })();
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div style={{ overflow: "hidden" }}>
          <div>
            {/* Intro */}
            <div className="bg-gradient p-0" style={styles.aboutNyceContainer}>
              <div className="d-flex flex-wrap justify-content-around">
                <div className="d-inline-flex align-items-center justify-content-center flex-column ppx-5">
                  <img src={logo} width="250px" />
                </div>
                <div className="d-inline-flex align-items-center justify-content-center flex-column" style={styles.aboutNyceTextContainer}>
                  <div className="d-inline-flex p-3">
                    {"Auction home for B2B buying and selling recreational cannabis products for the licensed New York State marketplace."}
                  </div>
                  {!sessionState?.loggedIn && (
                    <div className="d-inline-flex p-3">
                      <Button onClick={() => navigate("/register")} size="large" style={{ background: "#738FFF", color: "white" }}>
                        {"Sign Up"}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!sessionState?.loggedIn && <IntroVideoComponent />}

            {/* <p>
                            Welcome to the New York State Cannabis Exchange, your
                            home for buying and selling recreational cannabis
                            products for the New York State market. This one stop
                            auction platform allows B2B buyers and sellers to
                            connect through our cannabis auction platform. Whether
                            you’re a farmer looking to maximize profits, a processor
                            looking for biomass, or a dispensary looking for high
                            end product, the NYCE is the place to find the answer to
                            your needs.
                        </p> */}

            {/* Hot Auctions */}

            <div className="row auctionsContainer" style={styles.auctionsContainer}>
              <h3 style={{ textAlign: "center" }}>
                {!sessionState?.loggedIn ? (
                  <Space direction="vertical">
                    <Search placeholder="login to search" style={{ width: 180 }} />
                  </Space>
                ) : (
                  <Space direction="vertical">
                    <Search
                      placeholder="Search Auction "
                      //   value={inputValue}
                      //   onChange={(e) => setInputValue(e.target.value)}
                      onSearch={navSearchListing}
                      style={{ width: 170 }}
                    />
                  </Space>
                )}
              </h3>
              <div className="aboutDescription">
                <h3 style={{ textAlign: "center" }}>Hot Listings</h3>
              </div>
              <div className="carouselContainer">
                <div className="row mb-3">
                  {hotAuctions.length > 0 ? (
                    <CarouselComponent content="dynamic" data={hotAuctions} favourites={false} />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>No Hot Listings</h5>
                  )}
                </div>
              </div>
            </div>

            {/* Favorites */}
            {sessionState?.loggedIn && (
              <div className="row favoritesContainer" style={styles.favoritesContainer}>
                <div className="aboutDescription">
                  <h3 style={{ textAlign: "center" }}>Favorites List</h3>
                </div>
                <div className="carouselContainer">
                  <div className="row mb-3">
                    {favoritesData.length > 0 ? (
                      <CarouselComponent content="dynamic" data={favoritesData} favourites={true} />
                    ) : (
                      <h5 style={{ textAlign: "center" }}>No Favorites Added</h5>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Ongoing Auctions */}
            {/* <div className="row auctionsContainer" style={styles.auctionsContainer}>
                            <div className="aboutDescription">
                                <h3 style={{ textAlign: "center" }}>Upcoming Auctions</h3>
                            </div>
                            <div className="carouselContainer">
                                <div className="row">
                                    <div className="row md-3 mb-3">
                                        <CarouselComponent content="static" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="row auctionsContainer" style={styles.auctionsContainer}>
              <div className="aboutDescription">
                <h3 style={{ textAlign: "center" }}>Upcoming Listings</h3>
              </div>
              <div className="carouselContainer">
                <div className="row mb-3">
                  {upcomingAuctions.length > 0 ? (
                    <CarouselComponent content="dynamic" data={upcomingAuctions} favourites={false} />
                  ) : (
                    <h5 style={{ textAlign: "center" }}>No Upcoming Listings</h5>
                  )}
                </div>
              </div>
            </div>

            {/* What We Do */}
            {/* <div className=" bg-gradient" style={styles.whatWeDoContainer}>
                            <div className="aboutDescription">
                                <h4 style={{ textAlign: "center" }}>What We Do</h4>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center">
                                <div className="d-flex flex-column justify-content-center mx-5 mb-5">
                                    <p className="d-flex m-0" style={{textAlign: 'center'}}>
                                        {'New York Cannabis Exchange is a B2B auction service that enables cannabis growers and distributors to easily auction the cannabis products they produce.'}
                                    </p>
                                    <p className="d-flex m-0" style={{textAlign: 'center'}}>
                                        {'This enables growers to get a fair price for their product and dispensaries to have easy access to growers in NYS.'}
                                    </p>
                                </div>
                            </div>
                        </div> */}
          </div>
          {/* <div style={{ textAlign: "center" }}>
                <h1>Welcome to NYCE!</h1>
                <img src={logo} width="300" height="225" alt="NYCE" />
            </div> */}
        </div>
      </div>
    </>
  );
}

export default Home;

const styles = {
  aboutNyceContainer: {
    // backgroundColor: "#212529",
    backgroundColor: "#dcdfe3",
    // backgroundColor: "#e7e9ec",
    paddingLeft: 20,
    paddingRight: 10,
    // color: "white",
    fontSize: 20,
    minHeight: "200px",
  },
  aboutNyceTextContainer: {
    // fontFamily: 'Roboto Slab',
    fontSize: "24px",
    fontWeight: "bold",
    minWidth: "300px",
    maxWidth: "50%",
    textAlign: "center",
  },
  auctionsContainer: {},
  whatWeDoContainer: {
    backgroundColor: "#212529",
    color: "white",
    fontSize: 20,
    // height: 350,
  },
  favoritesContainer: {
    // height: 650,
  },
};
