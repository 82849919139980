import axios from "axios";
import React, { useReducer } from "react";
import { getCsrfCookieValue } from "../../utils";
import { showToastUtil, TOAST_TYPE } from "../Banner/BannerToastUtil";
import ModalComponent from "../Modal/Modal";

interface Props {
}

enum Fields {
    NAME = 'name',
    EMAIL = 'email',
    PHONE = 'phone',
    BUSINESS = 'business',
    DESC = 'desc',
}

enum BusinessTypes {
    OTHER = 'Other',
    CULTIVATOR = 'Cultivator',
    PROCESSOR = 'Processor',
    DISPENSARY = 'Dispensary',
}

enum Actions {
    ShowModal,
    CloseModal,
    OnChange,
    FormErrors,
    ApiErrors,
}

type IAction = {
    type: Actions,
    value?: any,
    field?: Fields
}

interface IState {
    //modal state
    showModal: boolean,
    //form state
    form: { [k: string]: string }
    //error state
    errors: { [k: string]: string }
    apiErrorMsg: string
}

const INIT_STATE: IState = {
    showModal: false,
    form: {
        [Fields.NAME]: '',
        [Fields.EMAIL]: '',
        [Fields.PHONE]: '',
        [Fields.BUSINESS]: BusinessTypes.OTHER,
        [Fields.DESC]: '',
    },
    errors: {},
    apiErrorMsg: ''
}

function reducer(state: IState, action: IAction): IState {
    const { type } = action;
    switch (type) {
        case Actions.ShowModal:
            return {
                ...state,
                showModal: true
            }
        case Actions.CloseModal:
            return INIT_STATE;
        case Actions.OnChange:
            if (action.field) {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        [action.field]: action.value
                    }
                }
            } else return state;
        case Actions.FormErrors:
            return {
                ...state,
                errors: action.value ?? {}
            }
        case Actions.ApiErrors:
            return {
                ...state,
                apiErrorMsg: 'Sorry! We encountered an error. Please try again.'
            }
        default:
            return state;
    }
}

const validateForm = (currState: IState) => {
    let errors: { [k: string]: string } = {},
        isValid: boolean = true;
    const { form } = currState;

    if (!form[Fields.DESC] || !form[Fields.NAME].match(/^[a-zA-Z ]*$/)) {
        isValid = false;
        errors[Fields.NAME] = "*Please enter alphabet characters only.";
    }

    const pattern: RegExp = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
    if (!pattern.test(form[Fields.EMAIL])) {
        isValid = false;
        errors[Fields.EMAIL] = "*Please enter valid email address.";
    }

    if (!form[Fields.PHONE].match(/^[0-9]{10}$/)) {
        isValid = false;
        errors[Fields.PHONE] = "*Please enter valid phone number.";
    }

    if (!form[Fields.DESC]) {
        isValid = false;
        errors[Fields.DESC] = "*Please enter your message.";
    }

    return errors;

}

const ContactUsComponent = (props: Props) => {
    const { } = props;
    const [state, dispatch] = useReducer(reducer, { ...INIT_STATE });

    const onFormSubmit = async (e: any) => {
        e?.preventDefault();
        const errors = validateForm(state);
        if (Object.keys(errors).length !== 0) {
            dispatch({ type: Actions.FormErrors, value: errors })
        } else {
            const { form } = state;
            const payload = {
                'name': form[Fields.NAME],
                'email': form[Fields.EMAIL],
                'phone_number': form[Fields.PHONE],
                'business_type': form[Fields.BUSINESS],
                'description': form[Fields.DESC],
            }
            axios.post(`${process.env.REACT_APP_URL}contactus`, payload, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            }).then(() => { // close modal on success
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Thank you for reaching out to us!\nWe will get back to you shortly.' });
                dispatch({ type: Actions.CloseModal });
            }).catch(() => {
                dispatch({ type: Actions.ApiErrors });
            });
        }
    };

    return (
        <React.Fragment>
            <a
                onClick={() => dispatch({ type: Actions.ShowModal })}
                className="nav-link active"
                style={{ color: 'white', cursor: 'pointer' }}
            >{'Contact Us'}</a>
            <ModalComponent
                showModal={state.showModal}
                headerText={'Contact Us'}
                closeCallback={() => dispatch({ type: Actions.CloseModal })}
            >
                <React.Fragment>
                    <div className="fw-bold py-1" style={{ fontSize: '1.25rem'}}>{`Let's get this conversation started!`}</div>
                    <div className="fw-bold py-1">{`Tell us a bit about yourself, and we'll get in touch as soon as we can.`}</div>
                    <form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={(e) => onFormSubmit(e)}
                    // className={}
                    >
                        <div className="form-group">
                            <label className="required">Full Name</label>
                            <input
                                className="form-control"
                                type="text"
                                name={Fields.NAME}
                                placeholder="Enter your Full Name"
                                required={true}
                                value={state.form[Fields.NAME]}
                                onChange={(e) => {
                                    dispatch({ type: Actions.OnChange, field: Fields.NAME, value: e.target.value })
                                }}
                            />
                            <div className="errorMsg">{state.errors[Fields.NAME]}</div>
                        </div>
                        <div className="form-group">
                            <label className="required">Email</label>
                            <input
                                className="form-control"
                                type="email"
                                name={Fields.EMAIL}
                                placeholder="Enter your email address"
                                required={true}
                                value={state.form[Fields.EMAIL]}
                                onChange={(e) => {
                                    dispatch({ type: Actions.OnChange, field: Fields.EMAIL, value: e.target.value })
                                }}
                            />
                            <div className="errorMsg">{state.errors[Fields.EMAIL]}</div>
                        </div>
                        <div className="form-group">
                            <label className="required">Phone Number</label>
                            <input
                                className="form-control"
                                type="text"
                                name={Fields.PHONE}
                                placeholder="Enter 10 digit phone number (US)"
                                required={true}
                                value={state.form[Fields.PHONE]}
                                onChange={(e) => {
                                    dispatch({ type: Actions.OnChange, field: Fields.PHONE, value: e.target.value })
                                }}
                            />
                            <div className="errorMsg">{state.errors[Fields.PHONE]}</div>
                        </div>
                        {/* <div className="row mb-2 text-center justify-content-center"> */}
                        <div className="form-group">
                            <label className="required">Business Type</label>
                            <select
                                className="w-100 form-select"
                                id="contactus_businesstype"
                                value={state.form[Fields.BUSINESS]}
                                onChange={(e) => {
                                    dispatch({ type: Actions.OnChange, field: Fields.BUSINESS, value: e.target.value })
                                }}
                            >
                                <option value={BusinessTypes.CULTIVATOR}>{BusinessTypes.CULTIVATOR}</option>
                                <option value={BusinessTypes.PROCESSOR}>{BusinessTypes.PROCESSOR}</option>
                                <option value={BusinessTypes.DISPENSARY}>{BusinessTypes.DISPENSARY}</option>
                                <option value={BusinessTypes.OTHER}>Other</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="required">Your Message</label>
                            <textarea
                                className="form-control"
                                name={Fields.DESC}
                                placeholder="Please enter your message"
                                required={true}
                                rows={5}
                                value={state.form[Fields.DESC]}
                                onChange={(e) => {
                                    dispatch({ type: Actions.OnChange, field: Fields.DESC, value: e.target.value })
                                }}
                            />
                            <div className="errorMsg">{state.errors[Fields.DESC]}</div>
                        </div>
                        <div className="errorMsg">{state.apiErrorMsg}</div>
                        <div className="d-grid gap-2 mt-3">
                            <button
                                type="button"
                                className="btn btn-primary fw-bold NYCE-modal-btn"
                                onClick={(e) => onFormSubmit(e)}
                            >Send</button>
                        </div>
                    </form>
                </React.Fragment>
            </ModalComponent>

        </React.Fragment>
    );
};

export default ContactUsComponent