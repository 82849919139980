import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import profilepicture from "../assets/images/Example_Profile_Picture.PNG";
import { useParams } from "react-router-dom";
import { Tag, Row, Col, Card, Rate, Input, Button, Pagination } from 'antd';
import Review from "./Review";
import "./Profile.css";

import { getCsrfCookieValue } from "../utils";
import ListingCardComponent from "../screens/Listings/ListingCardComponent";
import { FilesComponent } from "../screens/View-Listing/ViewListing";
import ListingsBrowserComponent from "../screens/Listings/ListingBrowserComponent";

import {UserOutlined} from '@ant-design/icons';

function SellerProfile() {
    const { TextArea } = Input;
    const [name, SetName] = useState("");
    const [phone, SetPhone] = useState("");
    const [company, SetCompany] = useState("");
    const [address, SetAddress] = useState("");
    const [email, SetEmail] = useState("");
    const [responseDATA, SetResponseDATA] = useState({});
    const [responseListing, SetResponseListing] = useState([]);
    const [reviews, SetReviews] = useState([]);
    const [samePerson, SetSamePerson] = useState(false);
    const [user, SetUser] = useState("");
    const [avgRating, SetAvgRating] = useState(0.0);

    // const [files, setFiles] = useState([]);
    const [images, setImages] = useState("");

    let routeParams = useParams();
    const getSeller = async () => {
        return await axios
            .post(
                `${process.env.REACT_APP_URL}view-users`,
                { username: routeParams.seller_id },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log(res.data);
                // setUserTable(res.data);
                // setFiles([...res.data.files]);
                setImages(res.data.images);

                return res.data;
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        (async () => {
            const sell = await getSeller();
            SetResponseDATA(sell);
            SetName(sell.first_name + " " + sell.last_name);
            SetAddress(sell.city + ", " + sell.state + ", " + sell.zip);
        })();
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}get-all-listings`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => {
                SetResponseListing(data.data);
            });
        // .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}reviews`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => {
                var temp = [];
                var sum = 0;
                var count = 0;
                for (let i = 0; i < data.data.length - 1; i++) {
                    temp.push(data.data[i]);
                    if (data.data[i].seller_name === routeParams.seller_id) {
                        sum += data.data[i].rating;
                        count += 1;
                    }
                }
                SetReviews(temp);
                if (count !== 0) {
                    var avg_rating = sum / count;
                    avg_rating = parseFloat(avg_rating.toFixed(1));
                    console.log("avg_rating", avg_rating, typeof avg_rating);
                    SetAvgRating(avg_rating);
                }
                if (data.data[data.data.length - 1].Auser === routeParams.seller_id) {
                    SetSamePerson(true);
                }
            });
        // .catch((err) => console.log(err));
    }, []);

    const [rate, SetRate] = useState("1");
    const [reviewText, SetReviewText] = useState("");

    const handleChange = function (value) {
        SetRate(value);
    };

    const handleUpdate = function (value) {
        console.log("handleUpdate", value);
        SetReviewText(value);
    };

    // const avg = function () {
    //     var sum = 0
    //     var avg_rating = 0
    //     for (let i = 0; i < reviews.length; i++) {
    //         sum = sum + reviews[i].rating;
    //     }
    //     if (sum > 0) {
    //         avg_rating = sum / reviews.length;
    //         avg_rating = avg_rating.toFixed(1);
    //         SetAvgRating(avg_rating);
    //     }
    // }

    const handleReview = function () {
        if (reviewText !== "") {
            var username = window.localStorage.getItem("user_name");
            if (username === null) {
                username = "anonymous";
            }
            var review = {
                user_name: username,
                seller_name: routeParams.seller_id,
                rating: rate,
                review_text: reviewText,
            };

            axios
                .post(`${process.env.REACT_APP_URL}reviews`, review, {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    window.location.reload();
                });
            // .catch((error) => console.log(error));
        }
    };

    const styleChange = function (star_value) {
        for (let star = 1; star <= 5; star++) {
            document.getElementById(star.toString()).style.color = "black";
        }
        for (let i = 1; i <= parseInt(star_value); i++) {
            document.getElementById(i.toString()).style.color = "orange";
        }
        SetRate(star_value);
    };

    const ratingChange = function (star_value) {
        console.log("rating change", star_value);
        SetRate(star_value);
    };

    var [reviewlength, SetReviewLength] = useState(true);
    var numReviews = 0;
    const defaultPageSize = 5
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const [reviewsInRange, setreviewsInRange] = useState([])
    const [reviewsCount, setreviewsCount] = useState(reviews.length);

    useEffect(() => {
        let filtered_reviews = reviews.filter((review) => review.seller_name == routeParams.seller_id )
        const inrange = filtered_reviews.slice((page - 1) * pageSize, page * pageSize);
        
        setreviewsCount(filtered_reviews.length);
        setreviewsInRange([...inrange]);
    }, [reviews]);

    useEffect(() => {
        let filtered_reviews = reviews.filter((review) => review.seller_name == routeParams.seller_id )
        const inrange = filtered_reviews.slice((page - 1) * pageSize, page * pageSize);
        
        setreviewsInRange([...inrange]);
    }, [page, pageSize]);

    return (
        <div style = {{padding: "20px"}}>

            <Row justify = "center" style = {{marginTop: "0px"}}>
                <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                    <div style = {{display: "flex", alignItems: "center"}}>
                        <h6 style = {{margin: "0px", padding: "5px"}}> Seller Profile </h6>
                    </div>
                </Card>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "5px"}}>
                <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                    <Row justify = "space-between">
                        <Col xs = {24} md={11}>
                            <Row justify = "space-between" style = {{padding: "10px"}} gutter = {[0, 32]}>
                                <Col flex="auto"  style={{ textAlign: 'center' }}>
                                    {/* Profile Pic - if one exists!*/}
                                    {images && (
                                        <img  src={images} style={{ display: 'block', margin: '0 auto' }} width="250" height="280"></img>
                                    )}
                                    {!images && (
                                        <img
                                            src={profilepicture}
                                            style={{ display: 'block', margin: '0 auto' }}
                                            width="120"
                                            height="145"
                                        ></img>
                                    )}
                                    <Rate allowHalf disabled value={avgRating} /> ({avgRating})
                                </Col>
                                <Col flex="auto" >
                                    <h5 className="ms-1 mb-2"> {responseDATA ? responseDATA.company_name : null}</h5>
                                    <p className="m-1 p-0">Primary Contact: {name}</p>
                                    <p className="m-1 p-0">Address: {address}</p>
                                    <p className="m-1 p-0">Phone: {responseDATA ? responseDATA.phone_number : null}</p>
                                    <p className="m-1 p-0">Email: {responseDATA ? responseDATA.email : null}</p>
                                </Col>
                                <Col flex="auto">
                                    {!samePerson && (
                                        <>
                                            <h6>Leave a review</h6>
                                            <>Overall Rating: <Rate onChange={ratingChange} /></><br></br>
                                            <TextArea  rows={4} onChange={(e) => handleUpdate(e.target.value)} style={{ width: '100%' }} placeholder = "Share your experience here " /><br></br>
                                            <Button style={{width: "100%", marginTop: "5px"}} type="primary" onClick={handleReview}>Submit</Button>
                                        </>
                                    )}
                                </Col>
                            </Row>

                            {/* <Row align = "middle">
                              <Col flex="auto">
                                <Rate onChange={ratingChange} />
                              </Col>
                              <Col flex="auto">
                                <TextArea  rows={4} onChange={(e) => handleUpdate(e.target.value)} style={{ width: '100%' }}/>
                              </Col>
                            </Row> */}
                            <div style={{maxHeight: "500px", overflow: "auto", marginTop: "15px"}}>
                                {reviewsInRange.map((l) => {
                                    if (l.seller_name == routeParams.seller_id) {
                                        numReviews = numReviews + 1;
                                        return (
                                            <Card style={{width: "99%",
                                            backgroundColor: "rgb(0,0,0,5%)",
                                            boxShadow: "2px 2px 4px rgb(0,0,0,40%)",
                                            marginBottom: "10px"}}
                                            bodyStyle = {{padding: "10px"}}>
                                                <div style={{display: "flex"}}>
                                                    <UserOutlined style={{ fontSize: '24px'}} />
                                                    <span style={{marginLeft:"15px"}}><h6>{l.user_name}</h6></span>
                                                    <span style={{marginLeft:"15px", marginTop: "-7px"}}><Rate disabled value={l.rating} /></span><br></br>
                                                </div>
                                                <span>{l.review_text}</span>
                                            </Card>
                                        );
                                    }
                                })}
                            </div>
                                <div style={{textAlign: "center"}}>
                                    {numReviews !== 0 &&
                                    <Pagination style={{marginLeft:"-5%"}}
                                    defaultCurrent={1} total={reviewsCount} 
                                    pageSize={pageSize}
                                    pageSizeOptions={[5, 10, 20, 50, 100]}// not working - fail library
                                    current={page}
                                    onChange={(page, pageSize) => {
                                        setPage(page);
                                        setPageSize(pageSize);
                                    }}
                                    showTotal={(total, [lo, hi]) => <>
                                        <div style={{ float: 'right' }}>{`${lo}-${hi} of ${total}`}</div>
                                    </>}
                                    />}
                                </div>
                                
                                {numReviews === 0 && <h5>No Reviews Found</h5>}
                        </Col>
                        <Col xs = {24} md={13}>
                            <ListingsBrowserComponent listings={responseListing} defaultPageSize={2}></ListingsBrowserComponent>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </div>
    );
}

export default SellerProfile;