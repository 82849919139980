import React, {useEffect, useState} from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;

function DragAndDrop(props) {

    const {fileList} = props;

    const draggerProps = {
        multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            let newFileList = fileList.slice();
            newFileList.splice(index, 1);
            props.setFileList(newFileList);
        },

        beforeUpload: file => {
          props.setFileList([...fileList, file]);
          return false;
        },

        fileList: fileList,
        
        accept: props.accept
    };


  return (
    <div style = {{maxWidth: "450px"}}>
        <Dragger {...draggerProps} >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
                Support for a single upload of {' '} 
                <strong>
                    <React.Fragment>{props.accept}</React.Fragment>
                </strong>
            </p>
        </Dragger>

    </div>
    
  )
}

export default DragAndDrop