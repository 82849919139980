import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tag, Row, Col, Card, Button, Popover, Modal } from "antd";
import AntTable from "./tables/AntTable.jsx";
import { getCsrfCookieValue } from "../utils";
import CustomPopover from "./modal/CustomPopover.js";
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";

//I used this tutorial to create the searchable table
//https://www.youtube.com/watch?v=MY6ZZIn93V8&list=LL&index=2&t=1002s

const ViewAllUsers = () => {
  const navigate = useNavigate();
  const [reListPopup, setReListPopup] = useState(false);
  const [listingsTable, setUsersTable] = useState([]);

  const getAllListings = () => {
    axios
      .get(`${process.env.REACT_APP_URL}get-all-listings`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((data) => setUsersTable(data.data));
  };

  useEffect(() => {
    getAllListings();
  }, []);

  function navigateListing(product_id) {
    navigate("/admin/individual-listing", { state: { product_id } });
  }

  function navigateAdmin() {
    navigate("/admin");
  }

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = listingsTable.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(listingsTable.length / recordsPerPage);

  const columnInfo = [
    { name: "Title", width: "20%" },
    { name: "Description", width: "30%" },
    { name: "Status", width: "10%" },
  ];
  const data = listingsTable.map((item) => {
    return {
      key: item.product_id,
      title: item.name,
      description: item.description,
      status: item.is_auc_running ? <Tag color="green"> ONGOING</Tag> : <Tag color="volcano"> CLOSED </Tag>,
    };
  });

  console.log("data", data);

  const handleDelete = (productId) => {
    console.log("handleDelete called in parent component", productId);
    axios.post(
      `${process.env.REACT_APP_URL}delete-listing`,
      { product_id: productId },
      {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      }
    );
    getAllListings();
  };
  const handleView = (product_id) => {
    console.log("handleView called in parent component", product_id);
    navigate("/admin/listings/" + product_id, { state: { product_id } });
  };
  const printDate = (date, addExtraDays = 0) => {
    // Confirm date is actually a Date object
    let d = new Date(date);
    d.setDate(d.getDate() + addExtraDays);
    let local = d.toLocaleDateString("en-us");
    let [month, day, year] = local.split("/");

    // Fix the date for front-end purposes.
    if (day.length == 1) day = "0" + day;
    if (month.length == 1) month = "0" + month;

    let output = [year, month, day].join("-");
    return output;
  };

  const printTime = (date, addExtraHours = 0) => {
    let d = new Date(date);
    d.setHours(d.getHours() + addExtraHours);

    // let output = d.toISOString().substring(11, 16);
    let output = d.toTimeString().substring(0, 5);
    return output;
  };
  const [aucEnable, setAucEnable] = useState(false);
  const [binEnable, setBinEnable] = useState(false);
  const [aucPrice, setAucPrice] = useState("");
  const [binPrice, setBinPrice] = useState("");
  const [startASAP, setStartASAP] = useState(true);
  const [product__id, setProductId] = useState("");
  const [startDate, setStartDate] = useState(printDate(new Date()));
  const [endDate, setEndDate] = useState(printDate(new Date(), 5));
  const [startTime, setStartTime] = useState(printTime(new Date(), 1));
  const [endTime, setEndTime] = useState(printTime(new Date()));
  const [modal, setmodal] = useState(false);

  const handleRelist = async (productId) => {
    console.log("handleRelist called in parent component", product__id);
    let formData = new FormData(); // Add everything to the FormData attribute
    // data.append("auction_enable", aucEnable);
    formData.append("auction_price", aucPrice);
    // formData.append("bin_enable", binEnable);
    formData.append("bin_price", binPrice);
    if (!startASAP) {
      formData.append("start_datetime", new Date(startDate + " " + startTime).toISOString());
    } else {
      formData.append("start_datetime", "");
    }
    formData.append("end_datetime", new Date(endDate + " " + endTime).toISOString());
    // formData.append("start_asap", startASAP);
    formData.append("product_id", product__id);
    console.warn(Object.fromEntries(formData));
    await axios
      .post(`${process.env.REACT_APP_URL}relist-Auctioned-Item`, formData, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((res) => {
        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Your Item is relist successfully!" });
        setmodal(false);
        getAllListings();
        setProductId("");
      })
      .catch((error) => {
        showToastUtil({ status: TOAST_TYPE.ERROR });
        setmodal(false);
        getAllListings();
        setProductId("");
      });
  };
  const handleModal = (productId) => {
    setProductId(productId);
    setmodal(true);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col xs={24} md={22}>
          <Card style={{ width: "100%" }} bodyStyle={{ padding: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h5 style={{ margin: "0px", padding: "5px" }}> Auction List </h5>
            </div>
          </Card>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col xs={23} md={22}>
          <AntTable
            data={data}
            columnInfo={columnInfo}
            actions={["View", "Delete", "Relist"]}
            handleView={handleView}
            getAllListings={getAllListings}
            handleDelete={handleDelete}
            handleModal={handleModal}
          />
          <Modal title="Relist item" open={modal} onOk={handleRelist} onCancel={() => setmodal(false)} okText="Submit" cancelText="Cancel">
            <CustomPopover
              aucEnable={aucEnable}
              setAucEnable={setAucEnable}
              binEnable={binEnable}
              setBinEnable={setBinEnable}
              aucPrice={aucPrice}
              setAucPrice={setAucPrice}
              binPrice={binPrice}
              setBinPrice={setBinPrice}
              startASAP={startASAP}
              setStartASAP={setStartASAP}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              startTime={startTime}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
            />
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default ViewAllUsers;
