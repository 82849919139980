import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Popconfirm, Popover, Modal } from "antd";
import { React, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import CustomPopover from "../modal/CustomPopover";
import { getCsrfCookieValue } from "../../utils";
import axios from "axios";

function AntTable(props) {
  const { columnInfo, data } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText(undefined);
    confirm({ closeDropdown: false });
  };


    const getColumnSearchProps = (dataIndex) => {
        if(props.disableSearch == true) return;
        
        return{
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
    
                    <Space>
                        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90}} >
                            Search
                        </Button>
                        <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
    
                        <Button type="link" size="small" onClick={() => { close() }} >
                            close
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
    
            render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069',padding: 0 }}
                             searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
        }
    }

    // const getColumnSearchProps = (dataIndex) => ({
    //     if(props.disableSearch == true){

    //     }else{
        
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: 'block',
    //                 }}
    //             />

    //             <Space>
    //                 <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90}} >
    //                     Search
    //                 </Button>
    //                 <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
    //                     Reset
    //                 </Button>

    //                 <Button type="link" size="small" onClick={() => { close() }} >
    //                     close
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     }

    //     filterIcon: (filtered) => (
    //         <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    //     ),
    //     onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },

    //     render: (text) =>
    //     searchedColumn === dataIndex ? (
    //         <Highlighter highlightStyle={{ backgroundColor: '#ffc069',padding: 0 }}
    //                      searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}
    //         />
    //     ) : (
    //         text
    //     ),
    // });

    const columns = columnInfo.map( item => {
        return{
            title: item.name,
            dataIndex: item.name.replace(/\s/g, "").toLowerCase(),
            key: item.name.replace(/\s/g, "").toLowerCase(),
            width: item.width,
            ...getColumnSearchProps(item.name.replace(/\s/g, "").toLowerCase()),
        }
    })
    if(props.actions.length >= 1){


        columns.push({
            title: <div style={{ display: 'flex', justifyContent: 'center' }}>Action</div>,
            dataIndex: 'action',
            width: props.actionWidth || "15%",
            render: (_, record) =>
                data.length >= 1 ? (
                     <div style={{display:'flex',justifyContent: 'center'}}>

                        {props.actions.includes("View") &&
                            <Button type="link" size="small" style = {{marginRight: "20px"}}  onClick={() => props.handleView(record.key)}>View</Button>
                        }
                        {props.actions.includes("Delete") &&
                            <Popconfirm title="Sure to delete?" onConfirm={() => props.handleDelete(record.key)}>
                                <Button type="link" size="small" style = {{marginRight: "20px", color: "red"}}> Delete </Button>
                            </Popconfirm>
                        }
                        {props.actions.includes("Relist") && (
                            <>
                            <Button onClick={() => props.handleModal(record.key)} type="link" size="small" style={{ marginRight: "20px", color: "green" }}>
                            Relist
                            </Button>
                            </>
                        )}
                        {props.actions.includes("Approve") &&
                            <Popconfirm title="Sure to Approve?" onConfirm={() => props.handleApprove(record.key)}>
                                <Button type="link" size="small" style = {{marginRight: "20px", color: "green"}} >Approve</Button>
                            </Popconfirm>
                        }
                        {props.actions.includes("Reject") &&
                            <Popconfirm title="Sure to Reject?" onConfirm={() => props.handleReject(record.key)}>
                                <Button type="link" size="small" style = {{marginRight: "20px", color: "red"}} >Reject</Button>
                            </Popconfirm>
                        }
                        {props.actions.includes("Remove") &&
                            <Popconfirm title="Sure to Remove?" onConfirm={() => props.handleRemove(record.key)}>
                                <Button type="link" size="small" style = {{marginRight: "20px", color: "red"}} >Remove</Button>
                            </Popconfirm>
                        }

                    </div>
                ) : null,
        })
    }

    return <Table  columns={columns} dataSource={data} pagination = {props.pagination == false ? false : true}/>;
}

export default AntTable
