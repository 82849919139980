import React, {useEffect, useState} from 'react';
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";

function TermsAndConditionsComponent() {
  const [staticContent, setStaticContent] = useState();
  const fetchContent = () => {
        axios
        .get(`${process.env.REACT_APP_URL}static-content?name=Terms_And_Conditions`, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        })
        .then((data) => {
            setStaticContent(data.data.content);
        })
        .catch((err) => console.log(err));
   }
   if(staticContent == null)
     fetchContent();

//    useEffect(() => {
//        fetchContent();
//    })

  return (
    <>
      <div style={{padding:20}}>
        <div className="container-fluid">
          <div className="row terms-and-conditions-section">
            <div className="col-xs-12 text-center">
              <h1 className="title">Terms and Conditions</h1>
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: staticContent}}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditionsComponent