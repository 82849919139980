import React, { useEffect, useState } from "react";
import DetailedAttribute from "../../json_information/listing_info.json";

import axios from "axios";
import ListingFilter from "./ListingFilter";
import "./Listings.css";

import { getCsrfCookieValue } from "../../utils";
import ListingsBrowserComponent from "./ListingBrowserComponent";


const SelectedFilter = ({ category_name, category_value }) => (
        <button className="btn btn-default px-3 mt-2" type="button">
            <span className="categoryName">{category_name}: </span>
            <span>{category_value}</span>
        </button>
    );

// Call the component with the URL to access for the listings! (multiple on the backend).
function ListingsComponent({ url }) {
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [listings, setListings] = useState([]);
    const [numlistings, setNumListings] = useState(1);
    const [quality, setQuality] = useState(
        DetailedAttribute.quality_level.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    const makeSubmit = (valueStore, key) => {
        handleSubmit(() => {
            // Set current filter object 
            let arr = [];
            valueStore?.filter(x => x.checked === true)?.map(x => {
                arr.push([key, x.option]);
            });
            let newFilters = appliedFilters.filter(x => x[0] !== key);
            setAppliedFilters([
                ...newFilters,
                ...arr
            ])
        });
    }

    useEffect(() => {
        makeSubmit(quality, 'Quality');
    }, [quality]);


    const [seedsAndPlants, setSeedsAndPlants] = useState(
        DetailedAttribute.seed_plants.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    useEffect(() => {
        makeSubmit(seedsAndPlants, 'Seeds & Plants');
    }, [seedsAndPlants]);

    const [extracts, setExtracts] = useState(
        DetailedAttribute.extracts.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    useEffect(() => {
        makeSubmit(extracts, 'Extracts');
    }, [extracts]);

    const [finishedProducts, setFinishedProducts] = useState(
        DetailedAttribute.finished_products.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    useEffect(() => {
        makeSubmit(finishedProducts, 'Finished Products');
    }, [finishedProducts]);


    const [strainType, setStrainType] = useState(
        DetailedAttribute.strain_type.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );


    useEffect(() => {
        makeSubmit(strainType, 'Strain Type');
    }, [strainType]);

    
    const [biomass, setBiomass] = useState(
        DetailedAttribute.biomass.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );
    
    useEffect(() => {
        makeSubmit(biomass, 'Biomass');
    }, [biomass]);

    const [environment, setEnvironment] = useState(
        DetailedAttribute.environment.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    useEffect(() => {
        makeSubmit(environment, 'Environment');
    }, [environment]);

    const [trim, setTrim] = useState(
        DetailedAttribute.trim.map((attribute) => {
            return {
                option: attribute,
                checked: false,
            };
        })
    );

    useEffect(() => {
        makeSubmit(trim, 'Trim');
    }, [trim]);

    // const getAllListings = () => {
    //     axios
    //         .get(`${process.env.REACT_APP_URL}${url}`, { withCredentials: true })
    //         .then((data) => {
    //             console.log(data.data);
    //             setListings(data.data);
    //         })
    //         .catch((err) => console.log(err));
    //     console.log(quality);
    // };

    // Loads the page w/ filters empty
    useEffect(() => {
        handleSubmit();
    }, []);

    const handleSubmit = (cb) => {
        let filterParams = {
            quality: getSelectedFilters(quality),
            seeds_plants: getSelectedFilters(seedsAndPlants),
            extracts: getSelectedFilters(extracts),
            finished_products: getSelectedFilters(finishedProducts),
            strain: getSelectedFilters(strainType),
            biomass: getSelectedFilters(biomass),
            environment: getSelectedFilters(environment),
            trim: getSelectedFilters(trim),
        };
        axios
            .post(`${process.env.REACT_APP_URL}${url}`, filterParams, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => {
                setListings(data.data);
                setNumListings(data.data.length);
                if (cb && typeof cb == 'function') cb();
            })
            .catch((err) => console.log(err));
    };

    const handleClear = () => {
        resetFilter(quality, setQuality);
        resetFilter(seedsAndPlants, setSeedsAndPlants);
        resetFilter(extracts, setExtracts);
        resetFilter(finishedProducts, setFinishedProducts);
        resetFilter(strainType, setStrainType);
        resetFilter(biomass, setBiomass);
        resetFilter(environment, setEnvironment);
        resetFilter(trim, setTrim);
        setAppliedFilters([]);
        // getAllListings();
        handleSubmit();
    };

    // useEffect(() => {
    //     // console.log(quality, extracts, seedsAndPlants);
    // }, [quality, extracts, seedsAndPlants]);

    return (
        // The "container" className is necessary to get it centering the rows/cols properly! I forgot about this
        // "justify-content-center" keeps things centered when the screen gets smaller
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            {/* <nav className="navbar m-0"> */}
            {/* <div className="container-fluid">
                <div className="row mt-3 mb-3"> */}
            <div className=" container-fluid mt-3">
                <nav
                    className="navbar navbar-expand-md navbar-dark"
                    style={{ backgroundColor: '#dedede' }}
                    aria-label="Fourth navbar example"
                >
                    <div className="container-fluid">


                        <a className="navbar-brand px-4" style={{ color: 'black' }} href="">
                            Filters
                        </a>
                        <button
                            className="navbar-toggler"
                            style={{ borderColor: 'black'}}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample05"
                            aria-controls="navbarsExample05"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExample05">
                            <ul className="navbar-nav me-auto mb-2 mb-md-0"
                                style={{ maxWidth: '90vw', display: 'flex', flexWrap: 'wrap' }}>

                                <li className="nav-item px-2 py-1">
                                    <ListingFilter
                                        filterName={"Quality"}
                                        filterOptions={quality}
                                        selectionFunction={setQuality}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Seeds/Plants"}
                                        filterOptions={seedsAndPlants}
                                        selectionFunction={setSeedsAndPlants}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Extracts"}
                                        filterOptions={extracts}
                                        selectionFunction={setExtracts}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Finished Products"}
                                        filterOptions={finishedProducts}
                                        selectionFunction={setFinishedProducts}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Strain Type"}
                                        filterOptions={strainType}
                                        selectionFunction={setStrainType}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Biomass"}
                                        filterOptions={biomass}
                                        selectionFunction={setBiomass}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Environment"}
                                        filterOptions={environment}
                                        selectionFunction={setEnvironment}
                                    />
                                </li>
                                <li className="nav-item px-1 py-1">
                                    <ListingFilter
                                        filterName={"Trim"}
                                        filterOptions={trim}
                                        selectionFunction={setTrim}
                                    />
                                </li>
                                {/* <li className="nav-item px-1 py-1">
                                    <button className="btn btn-success" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </li> */}
                                <li className="nav-item px-1 py-1">
                                    <button className="btn btn-warning" onClick={handleClear}>
                                        Clear
                                    </button>
                                </li>
                            </ul>
                        </div>
                        {/* <br></br> */}
                    </div>
                </nav>
                <nav className="navbar navbar-expand-md"
                    style={{ backgroundColor: '#dedede' }}
                    aria-label="Fourth navbar example">
                    <div className="container-fluid">
                        {/* <div className="row"> */}
                        <a className="navbar-brand px-4" style={{ color: 'black' }} href="">
                            Selected Filters
                        </a>
                        <div className="collapse navbar-collapse" id="navbarsExample05">
                            {appliedFilters?.length < 1 && <h5>No Filters Selected</h5>}
                            <ul className="navbar-nav me-auto mb-2 mb-md-0"
                                style={{ maxWidth: '90vw', display: 'flex', flexWrap: 'wrap' }}>
                                <li className="nav-item px-1 py-1">
                                    {
                                        appliedFilters?.map(x => {
                                            return <span style={{margin: '5px'}}><SelectedFilter category_name={x[0]} category_value={x[1]} /></span>
                                                
                                        })
                                    }

                                </li>
                                {/* <li className="nav-item px-1 py-1">
                                    <button className="btn btn-default" type="button">
                                        <span>Autoflower ferminized seeds<i className="fa-solid fa-xmark-large" color="red"></i></span>
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                        {/* </div> */}
                    </div>
                </nav>
            </div>
            <ListingsBrowserComponent listings={listings}></ListingsBrowserComponent>
        </div>
    );
}

const getSelectedFilters = (data) => {
    let output = [];
    for (let item of data) {
        if (item.checked) {
            output.push(item.option);
        }
    }
    return output;
};

const resetFilter = (filter, setter) => {
    let temp = filter;
    for (let filter of temp) {
        filter.checked = false;
    }
    setter(temp);
};

export const readableTime = (date) => {
    let day = date.toDateString();
    // console.log(date.toTimeString().split(" ")[0].split(":"));

    let [hours, minutes, seconds] = date.toTimeString().split(" ")[0].split(":");

    hours = parseInt(hours);
    let ampm = "A.M.";

    if (hours == 12) {
        ampm = "P.M.";
    } else if (hours > 12) {
        hours -= 12;
        ampm = "P.M";
    } else if (hours == 0) {
        ampm = "A.M.";
        hours = 12;
    }

    return day + " " + hours + ":" + minutes + " " + ampm + " ";
    // return hours + ":" + minutes + ":" + seconds + " " + ampm + " " + day
    // return date;
};




export default ListingsComponent;
