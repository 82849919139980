import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./register.css";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { showToastUtil, TOAST_TYPE } from '../components/Banner/BannerToastUtil';
import { passwordValidation } from "../utils"
import { Tooltip } from 'antd';


function RegisterForm() {
    const navigate = useNavigate();

    const [username, SetUsername] = useState("");
    const [first, SetFirst] = useState("");
    const [last, SetLast] = useState("");
    const [password, SetPassword] = useState("");
    const [confirmpassword, SetConfirmPassword] = useState("");
    const [email, SetEmail] = useState("");
    const [licensenumber, SetLicensenumber] = useState("");
    const [company, SetCompany] = useState("");
    const [street, SetStreet] = useState("");
    const [city, SetCity] = useState("");
    const [state, SetState] = useState("");
    const [zip, SetZip] = useState("");
    const [website, SetWebsite] = useState("");
    const [phone, SetPhone] = useState("");
    const [ein, SetEIN] = useState("");
    const [business, SetBusiness] = useState("cultivator");
    const [termsConditionsFlag, SetTermsConditionsFlag] = useState(false);

    const [missingToggle, SetMissingToggle] = useState("");
    const handleclick = function (value, name) {
        if (!/^[\u0000-\u007f]*$/.test(value)) {
            alert("Please don't enter a non-ascii character");
        }
        if (name === "username") {
            SetUsername(value);
        } else if (name === "firstName") {
            SetFirst(value);
        } else if (name === "lastName") {
            SetLast(value);
        } else if (name === "password") {
            SetPassword(value);
            validatePassword(value);
        } else if (name === "confirmpassword") {
            SetConfirmPassword(value);
            validateConfirmPassword(value);
        } else if (name === "email") {
            SetEmail(value);
        } else if (name === "licensenumber") {
            SetLicensenumber(value);
        } else if (name === "company") {
            SetCompany(value);
        } else if (name === "street") {
            SetStreet(value);
        } else if (name === "city") {
            SetCity(value);
        } else if (name === "website") {
            SetWebsite(value);
        } else if (name === "state") {
            SetState(value);
        } else if (name === "zip") {
            SetZip(value.replace(/[^0-9]/g, ""));
        } else if (name === "phone") {
            SetPhone(value.replace(/[^0-9]/g, ""));
        } else if (name === "ein") {
            SetEIN(value);
        }
    };

    const handleChange = function (value) {
        SetBusiness(value);
    };

    // Alerts
    const [usernameAlert, SetUsernameAlert] = useState("");
    const [firstAlert, SetFirstAlert] = useState("");
    const [lastAlert, SetLastAlert] = useState("");
    const [passwordAlert, SetPasswordAlert] = useState("");
    const [confirmpasswordAlert, SetConfirmPasswordAlert] = useState("");
    const [emailAlert, SetEmailAlert] = useState("");
    const [licensenumberAlert, SetLicensenumberAlert] = useState("");
    const [companyAlert, SetCompanyAlert] = useState("");
    const [streetAlert, SetStreetAlert] = useState("");
    const [cityAlert, SetCityAlert] = useState("");
    const [stateAlert, SetStateAlert] = useState("");
    const [zipAlert, SetZipAlert] = useState("");
    // const [addressAlert, SetAddressAlert] = useState("");
    const [websiteAlert, SetWebsiteAlert] = useState("");
    const [phoneAlert, SetPhoneAlert] = useState("");
    const [einAlert, SetEINAlert] = useState("");
    const [businessAlert, SetBusinessAlert] = useState("");
    const [termsConditionsAlert, SetTermsConditionsAlert] = useState("");

    const handleSubmit = function () {
        // Reset alerts
        SetUsernameAlert("");
        SetFirstAlert("");
        SetLastAlert("");
        SetPasswordAlert("");
        SetConfirmPasswordAlert("");
        SetEmailAlert("");
        SetLicensenumberAlert("");
        SetCompanyAlert("");
        SetStreetAlert("");
        SetCityAlert("");
        SetStateAlert("");
        SetZipAlert("");
        // SetAddressAlert("");
        SetWebsiteAlert("");
        SetPhoneAlert("");
        SetEINAlert("");
        SetBusinessAlert("");
        SetTermsConditionsAlert("");
        SetMissingToggle("");

        let uiValid = true;

        if (username === "") {
            SetUsernameAlert("Please enter a username");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (first === "") {
            SetFirstAlert("Please enter a first name");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (last === "") {
            SetLastAlert("Please enter a last name");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (email === "") {
            SetEmailAlert("Please enter an email");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (website === "") {
            SetWebsiteAlert("Please enter a website");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        } else {
            // if (!website.includes("https://") && !website.includes("http://")) {
            //     SetWebsiteAlert("Please enter a proper website address with http:// or https://");
            //     SetMissingToggle("Please review the form above for errors");
            // }
        }
        if (password === "") {
            SetPasswordAlert("Please enter a password");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        else if(!passwordValidation(password).status){
            SetPasswordAlert(passwordValidation(password).msg);
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (confirmpassword === "") {
            SetConfirmPasswordAlert("Please enter a password");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        else if(!passwordValidation(confirmpassword).status){
            SetConfirmPasswordAlert(passwordValidation(confirmpassword).msg);
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (licensenumber === "") {
            SetLicensenumberAlert("Please enter a license number");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (company === "") {
            SetCompanyAlert("Please enter a company name");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (street === "") {
            SetStreetAlert("Please enter a street");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (city === "") {
            SetCityAlert("Please enter a city");
            uiValid = false;
            SetMissingToggle("Please review the form above for errors");
        }
        if (state === "") {
            SetStateAlert("Please enter a state");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (zip === "") {
            SetZipAlert("Please enter a zipcode");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }

        if (phone === "") {
            SetPhoneAlert("Please enter a phone number");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (ein === "") {
            SetEINAlert("Please enter an EIN number");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (termsConditionsFlag === false) {
            SetTermsConditionsAlert("Please accept the Terms and Conditons.");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        var register = {
            username: username,
            first_name: first,
            last_name: last,
            email: email,
            password: password,
            phone_number: phone,
            license_number: licensenumber,
            account_status: false,
            ein_number: ein,
            website: website,
            company_name: company,
            business_type: business,
            // business_type: "admin",  // Testing validity.
            street: street,
            city: city,
            state: state,
            zip: zip,
            images: document.getElementById("images-upload").files[0],
        };

        // Files
        let filesUpload = document.getElementById("files-upload");
        if (filesUpload.files.length === 0) {
            // console.log("NO FILES");
            register["files"] = "";
        } else {
            // console.log("FILES", filesUpload.files);
            for (let i in filesUpload.files) {
                // console.log("UPLOADED 1 FILE");
                register[`file${i}`] = filesUpload.files[i];
            }
        }

        if (password != confirmpassword) {
            uiValid = false;
            SetConfirmPasswordAlert("Password does not match");
            SetMissingToggle("Please check errors above");
        }

        if (uiValid) {
            axios
                .post(`${process.env.REACT_APP_URL}register-user`, register, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-CSRFToken": getCsrfCookieValue()
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if ("SUCCESS" in res.data) {
                        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Thank you for signing up! We wanted to let you know that we'll be sending you an email as soon as your account is approved." });
                        navigate("/");
                    } else {
                        // console.log("\n\n\nERROR");
                        // let temp = [];

                        // for (let i in res.data) {
                        //     console.log(i);
                        //     console.log(res.data[i]);

                        //     temp.push(res.data[i]);
                        // }

                        let alerts = {
                            username: SetUsernameAlert,
                            first_name: SetFirstAlert,
                            last_name: SetLastAlert,
                            email: SetEmailAlert,
                            password: SetPasswordAlert,
                            phone_number: SetPhoneAlert,
                            license_number: SetLicensenumberAlert,
                            // account_status: false,
                            ein_number: SetEINAlert,
                            website: SetWebsiteAlert,
                            company_name: SetCompanyAlert,
                            business_type: SetBusinessAlert,
                            street: SetStreetAlert,
                            city: SetCityAlert,
                            state: SetStateAlert,
                            zip: SetZipAlert,
                        };

                        // Set the alerts if they were returned
                        for (let i in alerts) {
                            if (i in res.data) {
                                alerts[i](res.data[i][0]);
                            }
                        }

                        SetMissingToggle("Please review the form above for errors");
                    }
                });
            // .catch((error) => {
            //     console.log(error);
            // });
        } else {
            // console.log(register);
            // alert(string);
        }
    };

    const backToLogin = () => {
        navigate("/login");
    }

    const validatePassword = (value) => {
        if (value === "") {
            SetPasswordAlert("Please enter a password");
        }else if(!passwordValidation(value).status){
            SetPasswordAlert(passwordValidation(value).msg);
        }else{
            SetPasswordAlert("");
        }
    }

    const validateConfirmPassword = (value) => {
        if (value === "") {
            SetConfirmPasswordAlert("Please enter a password");
        }else if (password != value) {
            SetConfirmPasswordAlert("Password does not match");
        }else{
            SetConfirmPasswordAlert("");
        }
    }

    return (
        <div className="row justify-content-center align-items-center mt-5 mb-5">
            <div className="col-sm-12 col-md-10 col-lg-8">
                <div className="card container-md p-5" id="main" style={{ maxWidth: "750px", borderRadius: "10px" }}>
                    <h4 style={{ textAlign: "left", marginBottom: "30px" }}>Registration Form</h4>

                    <div className="row g-5 mb-3">
                        <fieldset className="border p-3" style={{ borderRadius: "5px" }}>
                            <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}> Your Info </legend>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">Username *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={username}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="username"
                                    />
                                    <p className="error">{usernameAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">First Name *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={first}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="firstName"
                                    />
                                    <p className="error">{firstAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">Last Name *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={last}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="lastName"
                                    />
                                    <p className="error">{lastAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">Email *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={email}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="email"
                                    />
                                    <p className="error">{emailAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    
                                    <div style = {{display: "flex", justifyContent: "space-between"}}>
                                        <p className="label m-0">Password *</p>
                                        <Tooltip style = {{float: "end"}} title={
                                            <>
                                                Password requirements: <br/>
                                                &#x2022; Password Length must be between 8 and 64 <br/>
                                                &#x2022; Contains at least one small alphabet<br/>
                                                &#x2022; Contains at least one capital alphabet <br/>
                                                &#x2022; Contains at least one digit <br/>
                                                &#x2022; Contains no whitespaces <br/>
                                                &#x2022; Contains at least one special character
                                            </>
                                        }>
                                            <span>
                                                <i class="fa fa-info-circle"></i>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <input
                                        type="password"
                                        className="form-control mt-1"
                                        value={password}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="password"
                                    />
                                    <p className="error">{passwordAlert}</p>
                                </div>

                               

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">Confirm Password *</p>
                                    <input
                                        type="password"
                                        className="form-control mt-1"
                                        value={confirmpassword}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="confirmpassword"
                                    />
                                    <p className="error">{confirmpasswordAlert}</p>
                                </div>
                            </div>

                        </fieldset>
                    </div>


                    <div className="row g-5">
                        <fieldset className="border p-3" style={{ borderRadius: "5px" }}>
                            <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}>Company Info</legend>


                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> License Number *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={licensenumber}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="licensenumber"
                                    />
                                    <p className="error">{licensenumberAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> Company Name *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={company}
                                        name="company"
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                    />
                                    <p className="error">{companyAlert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> Website *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={website}
                                        name="website"
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                    />
                                    <p className="error">{websiteAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0">  Business Type *</p>
                                    <select
                                        className="text-center form-select mt-1"
                                        name="business"
                                        id="business"
                                        style={{ backgroundColor: "#EFEFEF" }}
                                        // value={business}
                                        onChange={(e) => handleChange(e.target.value)}
                                    >
                                        <option selected>Select the business type</option>
                                        <option value="cultivator">Cultivator</option>
                                        <option value="processor">Processor</option>
                                        <option value="dispensary">Dispensary</option>
                                    </select>
                                    <p className="error">{businessAlert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12">
                                    <p className="label m-0">  Street *</p>
                                    <input
                                        type="street"
                                        className="form-control mt-1"
                                        id="iStreet"
                                        placeholder="Street"
                                        value={street}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="street"
                                    />
                                    <p className="error">{streetAlert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0"> City *</p>
                                    <input
                                        type="city"
                                        className="form-control mt-1"
                                        id="iCity"
                                        placeholder="City"
                                        value={city}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="city"
                                    />
                                    <p className="error">{cityAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0"> State *</p>
                                    <input
                                        type="state"
                                        className="form-control mt-1"
                                        id="iState"
                                        placeholder="State"
                                        value={state}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="state"
                                    />
                                    <p className="error">{stateAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0"> Zip Code *</p>
                                    <input
                                        type="zip"
                                        className="form-control mt-1"
                                        id="iZip"
                                        placeholder="Zip Code"
                                        value={zip}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="zip"
                                    />
                                    <p className="error">{zipAlert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> Phone Number *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        id="iPhone"
                                        value={phone}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="phone"
                                    />
                                    <p className="error">{phoneAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> EIN Number *</p>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        id="ein_val"
                                        value={ein}
                                        onChange={(e) => handleclick(e.target.value, e.target.name)}
                                        name="ein"
                                    />
                                    <p className="error">{einAlert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4">
                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> Upload license(s)</p>
                                    <input className="form-control" type="file" id="files-upload" multiple />
                                    <small id="image-help" className="form-text text-muted">
                                        Supported file types: .png or .jpg
                                    </small>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0"> Upload profile picture</p>
                                    <input className="form-control" type="file" id="images-upload" />
                                    <small id="image-help" className="form-text text-muted">
                                        Supported file types: .png or .jpg
                                    </small>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className="row mt-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className='d-inline-flex'>
                                <input type={'checkbox'} className='d-inline-block' value={termsConditionsFlag}
                                    onChange={(e) => {
                                        const newVal = e.target.value == 'true' ? false : e.target.value == 'false' ? true : false;
                                        SetTermsConditionsFlag(newVal);
                                    }}
                                />
                                <div className='d-inline-block ms-3'>
                                    <span>{'I have read and agreed to the '}</span>
                                    <a href="/termsNconditions" target={'_blank'} >{'Terms and Conditions'}</a>
                                    <span>{'.'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <p className="d-block error">{termsConditionsAlert}</p>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">

                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary"
                                style={{ float: "right" }}
                            >
                                Register
                            </button>



                            <button
                                type="button"
                                onClick={backToLogin}
                                className="btn btn-outline-primary me-4"
                                style={{ float: "right" }}
                            >
                                Back to Login
                            </button>



                        </div>
                    </div>

                    <div className="row">
                        <p className="error" style={{ textAlign: "right" }}> </p>
                    </div>


                    {missingToggle ?
                        <div className="row mb-2 text-center justify-content-center">
                            <h5 className="" style={{ color: "red" }}>
                                {missingToggle}
                            </h5>
                        </div>
                        : ""}
                </div>

            </div>
        </div>
    );
}

export default RegisterForm;