import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tag, Row, Col, Card } from 'antd';
import AntTable from "./tables/AntTable.jsx";
import { getCsrfCookieValue } from "../utils";

const ViewAllUsers = () => {
    const navigate = useNavigate();
    const [reviewTable, setReviewTable] = useState([]);
    
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}reviews`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => setReviewTable(data.data))
            // .catch((err) => console.log(err));
    }, []);


    const columnInfo = [{name: "Review ID", width: "10%"}, {name: "UserName", width: "10%"}, {name: "SellerName", width: "10%"}, {name: "Rating", width: "10%"}, {name: "Review Text", width: "30%"}]
    const data = reviewTable.map(item => {
        return {
            key: item.review_id,
            reviewid: item.review_id,
            username: item.user_name,
            sellername: item.seller_name,
            rating: item.rating,
            reviewtext: item.review_text
        }
       })


    return(
        <div style = {{padding: "20px"}}>

            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Reviews </h5>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actions = {[]} />
                </Col>
            </Row>
        </div>
    )


   
};

export default ViewAllUsers;
