import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tag, Row, Col, Card } from 'antd';
import { getCsrfCookieValue } from "../utils";
import AntTable from "./tables/AntTable.jsx";

function EditRequests() {
    const navigate = useNavigate();
    const [editTable, setEditTable] = useState([]);

    const fetchEditRequests = () => {
        axios
        .get(`${process.env.REACT_APP_URL}get-all-edits`, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        })
        .then((edits) => setEditTable(edits.data))
        .catch((err) => {});
    }

    useEffect(() => {
        fetchEditRequests();
    }, []);

    const columnInfo = [{name: "Title", width: "20%"}, {name: "Description", width: "25%"}, {name: "Reason", width: "20%"}]
    const data = editTable.map(item => {
        return {
            key: item.product_id,
            title: item.name,
            description: item.description,
            reason: item.reason_for_change
        }
    })

    console.log("editTable", editTable);

    const handleView = (product_id) => {
        // navigate("/admin/individual-edit", { state: product_id });
        navigate("/admin/edit-requests/"+product_id, { state: product_id });
    }

    const handleApprove = (product_id) => {
        axios
        .post(
            `${process.env.REACT_APP_URL}accept-edit`,
            {edits: {'product_id': product_id}},
            {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            }
        )
        .then((res) => {
            fetchEditRequests();
        })
    }
    
    const handleReject = (product_id) => {
        axios
        .post(
            `${process.env.REACT_APP_URL}remove-edit`,
            { product_id: product_id },
            {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            }
        )
        .then((res) => {
            fetchEditRequests();
        })
    }

    return (
        <div style = {{padding: "20px"}}>

            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Data Maintenance </h5>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actionWidth = {"25%"} actions = {['View', 'Approve', 'Reject']} handleView = {handleView} handleApprove = {handleApprove} handleReject = {handleReject}/>
                </Col>
            </Row>
        </div>
    )
}

export default EditRequests