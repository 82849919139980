import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./BaseAdminTemplate.css";
import { downloadCSVfile, getCsrfCookieValue } from "../utils";
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  EditOutlined,
  UnorderedListOutlined,
  StarOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import ViewAllListingsComponent from "./ViewAllListings.js";
import IndividualListingComponent from "./IndividualListing.js";
import EditRequests from "./EditRequests.js";
import ViewEditRequest from "./ViewEditRequest.js";
import ViewAllNewUsersComponent from "./ViewAllNewUsers.js";
import IndividualNewUserComponent from "./IndividualNewUser.js";
import ViewAllExistingUsersComponent from "./ViewAllExistingUsers.js";
import IndividualExistingUserComponent from "./IndividualExistingUser.js";
import ViewAllReviewsComponent from "./ViewAllReviews.js";
import IndividualReviewComponent from "./IndividualReview.js";
import AdminLogs from "./AdminLogs.js";
import MailTemplatesView from "./MailTemplatesView";
import ConfigureGlobalSettings from "./ConfigureGlobalSettings";
import UpdateUserAccess from "./UpdateUserAccess";

function getItem(label, key, icon, children, type) {
  return { key, icon, children, label, type };
}

const items = [
  getItem("Auction List", "1", <UnorderedListOutlined />),
  getItem("Data Maintenance", "2", <EditOutlined />),
  getItem("Users", "sub1", <UserOutlined />, [getItem("New Users", "3"), getItem("Existing Users", "4")]),
  getItem("Reviews", "5", <StarOutlined />),
  getItem("Logs", "6", <FileSearchOutlined />),
  getItem("Update User Access", "7", <EditOutlined />),
  getItem("Edit Content Templates", "8", <EditOutlined />),
  getItem("Global Configuration", "9", <EditOutlined />),
];

function Admin() {
  var pageUrl = window.location.href;
  const [first, ...rest] = pageUrl.split("/admin");
  var pagePath = rest.join("/admin");
  const location = useLocation();
  const navigate = useNavigate();

  const downloadContactUsCsv = async () => {
    showToastUtil({ status: TOAST_TYPE.INFO, message: "Your Download will begin shortly.!" });
    axios
      .get(`${process.env.REACT_APP_URL}contactus-data`, {
        headers: { "X-CSRFToken": getCsrfCookieValue() },
        withCredentials: true,
      })
      .then((response) => {
        const successful = downloadCSVfile(response.data, "Contactus");
        if (!successful) {
          throw Error("");
        } else {
          showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Your File was downloaded successfully!" });
        }
      })
      .catch(() => {
        showToastUtil({ status: TOAST_TYPE.ERROR });
      });
  };

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // const [auctionTable, setAuctionTable] = useState("loadingAuctions");
  // useEffect(() => {
  //     axios
  //         .get(`${process.env.REACT_APP_URL}completed-listings`, {
  //             headers: {
  //                 "X-CSRFToken": getCsrfCookieValue(),
  //             },
  //             withCredentials: true,
  //         })
  //         .then((auction_data) => setAuctionTable(auction_data.data))
  //         .catch((err) => { });
  // }, []);

  const menu_components = {
    1: <ViewAllListingsComponent />,
    2: <EditRequests />,
    3: <ViewAllNewUsersComponent />,
    4: <ViewAllExistingUsersComponent />,
    5: <ViewAllReviewsComponent />,
    6: <AdminLogs />,
    7: <UpdateUserAccess />,
    8: <MailTemplatesView />,
    9: <ConfigureGlobalSettings />,
  };

  const changeAdminPage = (menu) => {
    if (menu.key == 1) navigate("/admin/listings", {});
    else if (menu.key == 2) navigate("/admin/edit-requests", {});
    else if (menu.key == 3) navigate("/admin/new-users", {});
    else if (menu.key == 4) navigate("/admin/existing-users", {});
    else if (menu.key == 5) navigate("/admin/reviews", {});
    else if (menu.key == 6) navigate("/admin/logs", {});
    else if (menu.key == 7) navigate("/admin/update-user-access", {});
    else if (menu.key == 8) navigate("/admin/mail-templates", {});
    else if (menu.key == 9) navigate("/admin/configure-global", {});
    else navigate("/admin/listings", {});
    updateRender(menu.key);
  };

  const [render, updateRender] = useState(1);

  var validPath = true;
  var subPage = false;
  var activeTab = 1;
  if (pagePath && pagePath.startsWith("/")) {
    var pathList = pagePath.substring(1).split("/");
    if (pathList.length >= 1) {
      if (pathList[0] == "listings") {
        if (pathList.length > 1) menu_components[1] = <IndividualListingComponent />;
        activeTab = 1;
      } else if (pathList[0] == "edit-requests") {
        if (pathList.length > 1) menu_components[2] = <ViewEditRequest />;
        activeTab = 2;
      } else if (pathList[0] == "new-users") {
        if (pathList.length > 1) menu_components[3] = <IndividualNewUserComponent />;
        activeTab = 3;
      } else if (pathList[0] == "existing-users") {
        if (pathList.length > 1) menu_components[4] = <IndividualExistingUserComponent />;
        activeTab = 4;
      } else if (pathList[0] == "reviews") {
        if (pathList.length > 1) menu_components[5] = <IndividualReviewComponent />;
        activeTab = 5;
      } else if (pathList[0] == "logs") {
        activeTab = 6;
      } else if (pathList[0] == "update-user-access") {
        activeTab = 7;
      } else if (pathList[0] == "mail-templates") {
        activeTab = 8;
      } else if (pathList[0] == "configure-global") {
        activeTab = 9;
      } else {
        activeTab = 1;
        navigate("/admin/listings", {});
      }
    } else {
      activeTab = 1;
      navigate("/admin/listings", {});
    }
  } else {
    activeTab = 1;
    navigate("/admin/listings", {});
  }

  useEffect(() => {
    updateRender(activeTab);
    // if(location.state){
    //     updateRender(location.state);
    // }
  }, [location.state]);

  // const match = useMatch('/admin');
  return (
    <div className="d-flex flex-grow-1">
      <div className="menuDiv">
        <div>
          <Button
            type="Primary"
            onClick={toggleCollapsed}
            style={{
              marginBottom: 16,
            }}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu
            defaultSelectedKeys={[activeTab.toString()]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
            onClick={changeAdminPage}
          />
        </div>
      </div>
      <div className="contentDiv">{menu_components[render]}</div>
    </div>
  );
}

export default Admin;
