import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { Row, Col, Card, Button, Image, Popconfirm } from 'antd';
import AntTable from "./tables/AntTable.jsx";


function ViewEditRequest() {
    const location = useLocation();
    const navigate = useNavigate();


    const [orgData, setOrgData] = useState({});
    const [editData, setEditData] = useState({});
    
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}listings?product_id=` + String(location.state), {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => setOrgData(data.data))
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}get-all-edits`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            // .then((edits) => setEditData(edits.data[0]))
            .then ((edits) => setEditData( edits.data.filter(item => item.product_id == String(location.state)) [0]))
    }, []);


    const fetchEditedValue = (key) => {
        if(!Array.isArray(editData[key]) && orgData[key] === editData[key] ){
            return '-';
        }else if(!Array.isArray(editData[key])){
            return editData[key];
        }else{
            return(
                <div>
                    {
                        editData[key].filter(item => item.status != 2).map(item => 
                            <div display = "flex" style = {{marginBottom: "15px"}}>
                                <Image height={200} src={item.file}/>
                            </div>
                        )
                    }
                </div>
            )
        } 
         
    }
    
    const fetchOldValue = (key) => {
      if(orgData.hasOwnProperty(key)){
        if(Array.isArray(orgData[key])){
            return(
                <div>
                    {
                        orgData[key].map(item => 
                            <div display = "flex" style = {{marginBottom: "15px"}}>
                                <Image height={200} src={item.file} />
                            </div>
                        )
                    }
                </div>
            )
        }else{
            return orgData[key];
        } 
      }else{
        return editData[key]
      }
    }



    const oldVsNewData = () => {
        let data = [];

        const {reason_for_change, name, description, quality, seeds_plants,biomass, thc, cbd, environment, extracts, finished_products,
              lot_weight, strain, transportation, trim, zip_code, images} = editData;
        const edit_data = {reason_for_change, name, description, quality, seeds_plants,biomass, thc, cbd, environment, extracts, finished_products,
            lot_weight, strain, transportation, trim, zip_code, images}
        for (let key in edit_data) {
            data.push({
                attribute: key,
                oldvalue: fetchOldValue(key),
                editedvalue: fetchEditedValue(key)
            })
        }

        return data;
    }


    const columnInfo = [{name: "Attribute", width: "20%"}, {name: "Old Value", width: "30%"}, {name: "Edited Value", width: "30%"}]
    
    
    const data = oldVsNewData();

    const navigateViewAllEdits = () => {
        navigate("/admin/edit-requests");
    }

    const ApproveEdit = () => {
        axios
            .post(
                `${process.env.REACT_APP_URL}accept-edit`,
                {edits: {'product_id': orgData["product_id"]}},
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                navigateViewAllEdits();
            })
    }

    const RejectEdit = () => {
        axios
        .post(
            `${process.env.REACT_APP_URL}remove-edit`,
            { product_id: orgData["product_id"] },
            {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            }
        )
        .then((res) => {
            navigateViewAllEdits();
        })
    }



    return (
         <div style = {{padding: "20px"}}>
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Edit Request Details</h5>
                            <Button onClick = {navigateViewAllEdits} size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Go to Requests</Button>
                            <Popconfirm title="Sure to approve?" onConfirm={ApproveEdit}>
                                <Button size = "middle" style = {{background: '#84D677', color: "white", marginLeft: "15px"}}> Approve </Button>
                            </Popconfirm>
                            <Popconfirm title="Sure to reject?" onConfirm={RejectEdit}>
                                <Button  size = "middle" style = {{background: '#FF7575', color: "white", marginLeft: "15px"}}> Reject </Button>
                            </Popconfirm>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actions = {[]}/>
                </Col>
            </Row>
        </div>
    )
}

export default ViewEditRequest