import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from 'antd';
import axios from "axios";
import { GlobalConfiguration } from "../utils";
import { getCsrfCookieValue } from "../utils";
import { TOAST_TYPE, showToastUtil } from "../components/Banner/BannerToastUtil";

interface Props { }

const ConfigureGlobalSettings = (props: Props) => {

    const [globalConfig, setGlobalConfig] = useState<GlobalConfiguration>({ company: {}, invoice: {} });

    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}get-config/`;
        axios.get(url, {
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            withCredentials: true,
        }).then((response) => {
            const config: GlobalConfiguration = response.data;
            setGlobalConfig(config);
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR, message: "Failed to get default global configuration." });
        });
    }, []);

    const onChange = (value: string | string[] | number | boolean, identifier: string) => {
        const keys = identifier.split(',');
        const main_key = keys[0], sub_key = keys[1];
        if (sub_key === 'terms_and_conditions') {
            value = (value as string).split('\n')
        }
        const config: GlobalConfiguration = {
            ...globalConfig,
            [main_key]: {
                ...globalConfig[main_key],
                [sub_key]: value,
            }
        };
        setGlobalConfig(config)
    }

    const saveChanges = () => {
        const url = `${process.env.REACT_APP_URL}save-config/`;
        axios.put(url, globalConfig, {
            headers: { "X-CSRFToken": getCsrfCookieValue(), },
            withCredentials: true,
        }).then((response) => {
            showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Global configurations updated successfully!" });
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR, message: "Failed to save global configuration." });
        });
    }


    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <Row justify="center" style={{ marginTop: "20px" }}>
                    <Col xs={24} md={22}>
                        <Card style={{ width: "100%" }} bodyStyle={{ padding: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <h5 style={{ margin: "0px", padding: "5px" }}> Configure Global Settings</h5>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: "10px" }}>
                    <Col xs={24} md={22}>
                        <Card style={{ width: "100%" }} bodyStyle={{ padding: "20px" }}>
                            <fieldset className="d-flex p-3" style={{ borderStyle: "solid", borderRadius: "5px", borderColor: "black", borderWidth: "2px" }}>
                                <legend className="float-none w-auto p-2 m-0" style={{ fontSize: "1rem", fontWeight: 450 }}>{'Company Details'}</legend>
                                <div className="d-flex flex-grow-1 flex-wrap">
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Name:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,name"
                                            value={globalConfig.company.name}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Logo:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,logo"
                                            value={globalConfig.company.logo}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Address Line 1:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,address_line_1"
                                            value={globalConfig.company.address_line_1}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Address Line 2:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,address_line_2"
                                            value={globalConfig.company.address_line_2}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'City:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,city"
                                            value={globalConfig.company.city}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'State:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,state"
                                            value={globalConfig.company.state}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'Zip Code:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,zip_code"
                                            value={globalConfig.company.zip_code}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'Phone:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,phone"
                                            value={globalConfig.company.phone}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'Email:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,email"
                                            value={globalConfig.company.email}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4 p-1">
                                        <p className="label m-0">{'Currency:'}</p>
                                        <input
                                            type="text"
                                            className="form-control my-1"
                                            name="company,currency"
                                            value={globalConfig.company.currency}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset className="d-flex p-3" style={{ borderStyle: "solid", borderRadius: "5px", borderColor: "black", borderWidth: "2px" }}>
                                <legend className="float-none w-auto p-2 m-0" style={{ fontSize: "1rem", fontWeight: 450 }}>{'Invoice Details'}</legend>

                                <div className="d-flex flex-grow-1 flex-wrap">
                                    <div className="col-sm-12 p-1 mb-2">
                                        <div className="label d-inline-flex m-0">{`Auto Email:\u00A0\u00A0\u00A0${globalConfig.invoice.auto_email ? 'Enabled' : 'Disabled'}`}</div>
                                        <input
                                            type="checkbox"
                                            className="d-inline-flex form-check-input p-2"
                                            name="invoice,auto_email"
                                            checked={globalConfig.invoice.auto_email}
                                            onChange={(e) => onChange(!(globalConfig.invoice.auto_email), e.target.name)}
                                            style={{ marginLeft: "5px" }}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Terms & Conditions:'}</p>
                                        <textarea
                                            className="form-control my-1"
                                            rows={3}
                                            name="invoice,terms_and_conditions"
                                            value={globalConfig.invoice.terms_and_conditions?.join('\n')}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-1">
                                        <p className="label m-0">{'Disclaimer:'}</p>
                                        <textarea
                                            className="form-control my-1"
                                            rows={3}
                                            name="invoice,disclaimer"
                                            value={globalConfig.invoice.disclaimer}
                                            onChange={(e) => onChange(e.target.value, e.target.name)}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div className="d-flex justify-content-center p-2">
                                <Button className="d-flex" onClick={() => saveChanges()} size="large" style={{ background: '#1ABE00', color: "white" }}>{'Save Changes'}</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default ConfigureGlobalSettings;