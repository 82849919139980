import React, { useEffect, useState, useRef } from "react";
import { PlusOutlined, FilterOutlined, CloseOutlined, CloseCircleOutlined, ExpandOutlined } from "@ant-design/icons";
import DetailedAttribute from "../../json_information/listing_info.json";
import { Button, Select, Space, Tag, Popover, Divider, Card, Row, Col, Input } from "antd";
import "./ListingsComponentNew.css";
import { contentQuotesLinter } from "@ant-design/cssinjs/lib/linters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faCompress, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { getCsrfCookieValue } from "../../utils";
import ListingsBrowserComponent from "./ListingBrowserComponent";
import { useNavigate } from "react-router-dom";
const { Search } = Input;

function ListingsComponentNew({ url, searchKey }) {
  const [appliedFilters, setAppliedFilters] = useState({}); // {type: [sub_types]}
  const [addFiltersModal, setAddFiltersModal] = useState({ show: false, selectedType: [], selectedSubType: [] });
  const [listings, setListings] = useState([]);
  useEffect(() => {
    fetchListings(searchKey);
  }, [searchKey]);

  useEffect(() => {
    setListings([]);
    if (searchKey === undefined) {
      fetchListings();
    } else {
      fetchListings(searchKey);
    }
  }, [url]);

  const fetchListings = (searchKey) => {
    let filterParams = {
      quality: appliedFilters["quality_level"] || [],
      seeds_plants: appliedFilters["seed_plants"] || [],
      extracts: appliedFilters["extracts"] || [],
      finished_products: appliedFilters["finished_products"] || [],
      strain: appliedFilters["strain_type"] || [],
      biomass: appliedFilters["biomass"] || [],
      environment: appliedFilters["environment"] || [],
      trim: appliedFilters["trim"] || [],
    };
    let updated_API_URL = searchKey === undefined ? "" : `?search=${searchKey}`;
    axios
      .post(`${process.env.REACT_APP_URL}${url}${updated_API_URL}`, filterParams, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((data) => {
        setListings(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchListings(searchKey);
  }, [appliedFilters]);

  const handleChange = (values) => {
    setAddFiltersModal({ ...addFiltersModal, selectedSubType: values });
  };

  const handleSelectType = (value) => {
    setAddFiltersModal({ ...addFiltersModal, selectedType: value, selectedSubType: appliedFilters[value] || [] });
  };

  const handleAddFilters = () => {
    let newAppliedFilters = { ...appliedFilters };
    newAppliedFilters[addFiltersModal.selectedType] = addFiltersModal.selectedSubType;
    setAppliedFilters(newAppliedFilters);
    setAddFiltersModal({ ...addFiltersModal, selectedType: [], selectedSubType: [] });
    hide();
  };

  const handleClearFilters = () => {
    setAppliedFilters({});
  };

  const handleFilterTypeChange = (key) => {
    let newAppliedFilters = { ...appliedFilters };
    if (key in newAppliedFilters) {
      delete newAppliedFilters[key];
      setAppliedFilters(newAppliedFilters);
    } else console.log("error in code");
  };

  const handleFilterValChange = (type, val) => {
    let newAppliedFilters = { ...appliedFilters };
    var index = newAppliedFilters[type].indexOf(val);
    if (index !== -1) {
      newAppliedFilters[type].splice(index, 1);
    }
    if (newAppliedFilters[type].length == 0) delete newAppliedFilters[type];
    setAppliedFilters(newAppliedFilters);
  };

  const [open, setOpen] = useState(false);
  const hide = () => {
    setAddFiltersModal({ ...addFiltersModal, selectedType: [], selectedSubType: [] });
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    if (!newOpen) setAddFiltersModal({ ...addFiltersModal, selectedType: [], selectedSubType: [] });
    setOpen(newOpen);
  };

  const rowRef = useRef(null);
  const cardRef = useRef(null);
  const [showExpandIcon, setShowExpandIcon] = useState(false);
  const [expandIconActivated, setExpandIconActivated] = useState(false);

  useEffect(() => {
    if (!cardRef.current || !rowRef.current) return;

    let shouldShowExpandIcon;
    if (showExpandIcon && expandIconActivated) {
      shouldShowExpandIcon = cardRef.current.scrollHeight > 75;
    } else {
      shouldShowExpandIcon = cardRef.current.scrollHeight < rowRef.current.scrollHeight;
    }

    setShowExpandIcon(shouldShowExpandIcon);
    if (!shouldShowExpandIcon) setExpandIconActivated(false);
  }, [appliedFilters]);

  return (
    <div>
      <Card
        ref={cardRef}
        style={{ borderRadius: "15px", margin: "10px" }}
        bodyStyle={
          showExpandIcon && expandIconActivated ? { padding: "0.5rem" } : { padding: "0.5rem", overflow: "auto", maxHeight: "51px" }
        }>
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div style={{ width: "calc(100% - 50px)" }}>
            <Row gutter={[8, 16]} align="middle" ref={rowRef}>
              <Col>
                <Space direction="vertical">
                  <Search
                    placeholder="Search Auction"
                    onSearch={fetchListings}
                    style={{ width: 170 }}                    
                  />
                </Space>
              </Col>
              <Col>
                <span>Filters</span>
              </Col>

              <Popover
                content={
                  <>
                    <Card bodyStyle={{ padding: "8px" }} title="Add Filters" headStyle={{ backgroundColor: "#EFEFEF", padding: "5px" }}>
                      <Row justify="start">
                        <Col span={18}>
                          <Row>
                            Filter Type*:
                            <Select
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Select Filter Type"
                              onChange={handleSelectType}
                              value={addFiltersModal.selectedType}
                              options={Object.keys(DetailedAttribute).map((filterKey) => ({ value: filterKey, id: filterKey }))}
                            />
                          </Row>

                          <Row>
                            Filter Value*:
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Select Filter Value"
                              onChange={handleChange}
                              value={addFiltersModal.selectedSubType}
                              options={
                                DetailedAttribute[addFiltersModal.selectedType] &&
                                DetailedAttribute[addFiltersModal.selectedType].map((filterKey) => ({ value: filterKey, id: filterKey }))
                              }
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="end" style={{ marginTop: "15px" }}>
                        <Button type="primary" style={{ backgroundColor: "black", marginLeft: "0.2rem" }} onClick={hide}>
                          Cancel
                        </Button>
                        <Button type="primary" style={{ backgroundColor: "black", marginLeft: "0.2rem" }} onClick={handleAddFilters}>
                          Add
                        </Button>
                      </Row>

                      {/* <div style={{display : "grid"}}>
                                                Filter Type*:
                                                <Select
                                                    allowClear
                                                    // style={{ width: '100%' }}
                                                    placeholder="Select Filter Type"
                                                    onChange={handleSelectType}
                                                    value = {addFiltersModal.selectedType}
                                                    options={Object.keys(DetailedAttribute).map((filterKey) => ({value: filterKey, id: filterKey}))}
                                                />

                                                Filter Value*:
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    // style={{ width: '100%' }}
                                                    placeholder="Select Filter Value"
                                                    onChange={handleChange}
                                                    value = {addFiltersModal.selectedSubType}
                                                    options={ DetailedAttribute[addFiltersModal.selectedType] && 
                                                            DetailedAttribute[addFiltersModal.selectedType].map((filterKey) => ({value: filterKey, id: filterKey}))}
                                                />

                                                <div style={{display: "inline-flex", marginTop: '0.5rem'}}>
                                                    <Button type="primary" style={{ backgroundColor: 'black', marginLeft: '0.2rem'}} onClick={hide}>Cancel</Button>
                                                    <Button type="primary" style={{ backgroundColor: 'black', marginLeft: '0.2rem'}} onClick={handleAddFilters}>Add</Button>
                                                </div>
                                            </div> */}
                    </Card>
                  </>
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                // placement="bottomLeft"
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: "black", marginLeft: "0.2rem", height: "25px", display: "flex", alignItems: "center" }}>
                  <PlusOutlined />
                </Button>
              </Popover>

              <Col style={{ marginRight: "10px" }}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "black", fontSize: "1rem", height: "25px", display: "flex", alignItems: "center" }}
                  onClick={handleClearFilters}>
                  <FontAwesomeIcon icon={faFilterCircleXmark} size="sm" style={{ color: "white" }} />
                </Button>
                {/* <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" /> */}
              </Col>

              {/* <Col>
                                <Divider type="vertical" style={{ margin: "0px", height: '1.5rem', borderInlineStart: '2px solid rgba(0, 0, 0, 0.6)'}}/>
                            </Col> */}

              {/* <Col> */}
              {Object.keys(appliedFilters).length > 0 ? (
                // <Row gutter = {[16, 8]}>
                <>
                  {Object.keys(appliedFilters).map((filterType) => (
                    <Col
                      key={filterType}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        padding: "5px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "4px",
                        marginRight: "10px",
                      }}>
                      <span style={{ fontSize: "12px", fontWeight: "400", marginRight: "5px" }}>{filterType}</span>
                      {appliedFilters[filterType]
                        ? appliedFilters[filterType].map((filterVal) => (
                            <Tag key={filterVal} closable onClose={() => handleFilterValChange(filterType, filterVal)}>
                              {filterVal}
                            </Tag>
                          ))
                        : ""}
                      <CloseCircleOutlined style={{ cursor: "pointer" }} onClick={() => handleFilterTypeChange(filterType)} />
                    </Col>
                  ))}
                </>
              ) : (
                // </Row>

                "No Filters are applied."
              )}
              {/* </Col> */}
            </Row>
          </div>

          <div style={{ width: "50px" }}>
            {
              showExpandIcon && !expandIconActivated && (
                <Button
                  onClick={() => {
                    setExpandIconActivated(true);
                  }}
                  type="primary"
                  style={{ backgroundColor: "black", marginLeft: "0.2rem", height: "25px", display: "flex", alignItems: "center" }}>
                  <ExpandOutlined />
                </Button>
              )
              // <button onClick={() => {setExpandIconActivated(true)}}> Expand ME !</button>
            }

            {
              showExpandIcon && expandIconActivated && (
                <Button
                  type="primary"
                  onClick={() => {
                    setExpandIconActivated(false);
                  }}
                  style={{ backgroundColor: "black", fontSize: "1rem", height: "25px", display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon icon={faCompress} size="sm" style={{ color: "white" }} />
                </Button>
              )
              // <Button onClick={() => {setExpandIconActivated(false)}} type="primary" style={{ backgroundColor: 'black', marginLeft: '0.2rem', height: '25px', display: 'flex', alignItems: 'center'}}>
              //     <ExpandOutlined />
              // </Button>
              // <button onClick={() => {setExpandIconActivated(false)}}> Minimize ME !</button>
            }
          </div>
        </div>
      </Card>

      <ListingsBrowserComponent  listings={listings}></ListingsBrowserComponent>
    </div>
  );
}

export default ListingsComponentNew;
