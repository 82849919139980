import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { Row, Col, Card, Image, Button } from 'antd';
import AntTable from "./tables/AntTable.jsx";
import { Popconfirm } from 'antd';
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";

const IndividualUser = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateViewAllExistingUsers = () => {
        navigate("/admin/existing-users");
    };

    const handleRemove = () => {
        var username = location.state["username"];
        axios
            .post(
                `${process.env.REACT_APP_URL}remove-user`,
                { username: username },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'User removed successfully.' });
                navigate("/admin/existing-users");
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to remove user.' });
                console.error(err);
            });
    }

    const handleUpdateUserAccess = () => {
        var username = location.state["username"];
        axios
            .post(
                `${process.env.REACT_APP_URL}update-user-access`,
                { username: username, business_type: user_business_type },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'User Business Type updated successfully.' });
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to update User Business Type' });
                console.error(err);
            });
    }

    const [listingsTable, setUsersTable] = useState(new Array());
    const [user_business_type, setUserBusinessType] = useState('Select Business Type');
    
    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_URL}view-users`,
                { username: location.state["username"] },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((resp) => {
                setUsersTable(resp.data);
                setUserBusinessType(resp.data['business_type']);
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to get user details' });
                console.log(err);
            });

    }, []);

    
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const nPages = Math.ceil((Object.keys(listingsTable).length) / recordsPerPage)
    const columnInfo = [{name: "Column"}, {name: "Value"}]

    var temp_data = []
    let tableOne = [];
    let tableTwo = [];

    for(let key in listingsTable){
        if(["id", "username", "first_name", "last_name", "email", "account_status", "is_deleted",
            "is_unenrolled", "phone_number", "license_number"].includes(key)){
                tableOne.push({ key: tableOne.length, column: key, value: listingsTable[key].toString() })
        }else if(key == "images"){
            tableTwo.push({key: tableTwo.length, column: key, value: listingsTable[key] ? <Image height={200} src={listingsTable[key]}/> : ""})
        }else if(key == "files"){
            tableTwo.push({key: tableTwo.length, column: key, 
                      value: (listingsTable[key] || []).map(item => 
                                <div display = "flex" style = {{marginBottom: "15px"}}>
                                    <Image height={200} src={item.file}/>
                                </div>)
            })
        }else{
            tableTwo.push({
                key: tableTwo.length,
                column: key,
                value: listingsTable[key].toString()
            })
        }
    }

    return (
        <div style = {{padding: "0px"}}>

            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Existing User Details</h5>
                            <Button onClick = {navigateViewAllExistingUsers} size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Go to Existing Users</Button>
                            <Popconfirm title="Sure to Remove?" onConfirm={() => handleRemove()}>
                                <Button size = "middle" style = {{background: '#FF7575', color: "white", marginLeft: "15px"}}>Remove</Button>
                            </Popconfirm>
                        </div>
                    </Card>
                    {/* <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <h6 style = {{margin: "0px", padding: "0px"}}> Existing User Details </h6>
                    </Card> */}
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px", marginBottom: "20px"}} gutter={[32, 16]}>
                <Col xs = {23} md = {11} >
                    <AntTable data = {tableOne} columnInfo = {columnInfo} actions = {[]} pagination = {false} disableSearch = {true} />
                </Col>
                <Col xs = {23} md = {11} >
                    <AntTable data = {tableTwo} columnInfo = {columnInfo} actions = {[]} pagination = {false} disableSearch = {true} />
                </Col>
            </Row>

            <Row justify = "center" style = {{margin: "20px 0"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Select Business Type to update user access:</h5>
                            <div style={{display: "flex", padding: "0 10px"}}>
                                <select
                                    className="form-select"
                                    value={user_business_type}
                                    onChange={(e) => {
                                        setUserBusinessType(e.target.value)
                                        // dispatch({ type: Actions.OnChange, field: Fields.BUSINESS, value: e.target.value })
                                    }}
                                >
                                    <option value={'cultivator'}>{'Cultivator'}</option>
                                    <option value={'processor'}>{'Processor'}</option>
                                    <option value={'dispensary'}>{'Dispensary'}</option>
                                    <option value={'admin'}>{'Admin'}</option>
                                </select>
                            </div>
                            <Popconfirm title="Sure to Update?" onConfirm={() => handleUpdateUserAccess()}>
                                <Button size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Update</Button>
                            </Popconfirm>
                        </div>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

export default IndividualUser;
