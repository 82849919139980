import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomeComponent from "./screens/Landing/Home";
import ListingsComponentNew from "./screens/Listings/ListingsComponentNew";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Footer2 from "./components/Footer/Footer2";
import LoginComponent from "./screens/Login/Login";
import PasswordResetComponent from "./screens/Login/PasswordReset";
import PasswordResetSuccessComponent from "./screens/Login/PasswordResetSuccess";
import PasswordResetRequestComponent from "./screens/Login/PasswordResetRequest";
import PasswordResetRequestSentComponent from "./screens/Login/PasswordResetRequestSent";
import AdminComponent from "./Admin/Admin";
import RegisterForm from "./Register/RegisterForm";
import Create from "./Listings/Create";
import Edit from "./Listings/Edit";
import SellerProfile from "./Profile/Profile";
import ViewListingComponent from "./screens/View-Listing/ViewListing";
import SettingsComponent from "./screens/Settings/Settings";
import AboutUsComponent from "./screens/AboutUs/AboutUsComponent";
import FAQsComponent from "./screens/Faqs/FAQsComponent";
import TermsAndConditionsComponent from "./screens/TermsAndConditions/TermsAndConditionsComponent";
import { StorageState, checkStorageState, checkVaildAuth, clearStorageState, getCsrfCookieValue, setStorageState } from "./utils";
import axios from "axios";

export const VALID_PATHS_WITHOUT_LOGIN = [
  "/",
  "/login",
  "/register",
  "/about",
  "/termsNconditions",
  "/faqs",
  "/",
  "/",
  "/password/reset/submitted",
  "/password/reset/request",
  "/password/reset/success",
  "/password/reset",
];

const AppRouter = () => {
  const [sessionState, setSessionState] = useState<StorageState>(checkStorageState());
  const navigate = useNavigate();
  let location = useLocation();
  const [searchKey, setSearchKey] = useState("");
  const localSetter = () => {
    setSessionState(checkStorageState());
  };
  const updateOnLoginCheck = (business_type: string, user_name?: string) => {
    setStorageState(business_type, user_name);
    localSetter();
  };
  const updateOnLogout = () => {
    clearStorageState();
    localSetter();
  };
  useEffect(() => {
    localSetter();
    window.addEventListener("storage", localSetter);
    return () => {
      window.removeEventListener("storage", localSetter);
    };
  }, []);

  // Auth Checking
  useEffect(() => {
    const validauth: boolean = checkVaildAuth();
    if (!validauth) {
      axios
        .get(`${process.env.REACT_APP_URL}checklogin`, {
          headers: { "X-CSRFToken": getCsrfCookieValue() },
          withCredentials: true,
        })
        .then((res) => {
          // if auth API check is false
          const auth: boolean = !!res.data?.auth;
          if (!auth) {
            if (!VALID_PATHS_WITHOUT_LOGIN.includes(location.pathname)) {
              const curr_pathname = location.pathname !== "/" ? location.pathname : null;
              const params = new URL(document.location.toString()).searchParams;
              const next = params.get("next") ?? curr_pathname ?? "/listings";
              navigate(`/login?next=${next}`);
            }
          } else {
            const business_type: string = res.data?.business_type ?? "";
            updateOnLoginCheck(business_type);
          }
        })
        .catch((err) => {
          console.error("Failed to check login with error: ", err);
        });
    }
  });

  const navSearchListing = (e: string) => {
    setSearchKey(e);
    navigate(`/listings`);
  };
 
  return (
    <>
      <div className="d-flex flex-column vw-100 vh-100">
        <Navbar navSearchListing={navSearchListing} sessionState={sessionState} updateOnLogout={updateOnLogout} />
        <div className="d-flex flex-column flex-grow-1">
          <Routes>
            <Route element={<HomeComponent sessionState={sessionState} navSearchListing={navSearchListing} />} path="/" />
            {sessionState.loggedIn ? (
              <>
                <Route element={<SettingsComponent updateOnLogout={updateOnLogout} />} path="/settings" />
                <Route element={<ListingsComponentNew  searchKey={searchKey} url="get-all-listings" />} path="/listings" />
                <Route element={<ListingsComponentNew  searchKey={searchKey} url="get-active-bid-listings" />} path="/bids" />
                <Route element={<ListingsComponentNew  searchKey={searchKey} url="get-ended-bid-listings" />} path="/purchased" />
                <Route element={<ListingsComponentNew  searchKey={searchKey} url="get-seller-listings" />} path="/mylistings" />
                <Route element={<Create />} path="/create" />
                <Route element={<ViewListingComponent />} path="/view-listing/:product_id" />
                <Route element={<Edit />} path="/edit/:product_id" />
                <Route element={<SellerProfile />} path="/seller-profile/:seller_id" />
                {sessionState.isAdmin && (
                  <>
                    <Route element={<AdminComponent />} path="/admin/*" />
                  </>
                )}
              </>
            ) : (
              <>
                <Route element={<LoginComponent updateOnLoginCheck={updateOnLoginCheck} />} path="/login" />
                <Route element={<RegisterForm />} path="/register" />
              </>
            )}

            <Route element={<AboutUsComponent />} path="/about" />
            <Route element={<TermsAndConditionsComponent />} path="/termsNconditions" />
            <Route element={<FAQsComponent />} path="/faqs" />
            <Route element={<PasswordResetRequestSentComponent />} path="/password/reset/submitted" />
            <Route element={<PasswordResetRequestComponent />} path="/password/reset/request" />
            <Route element={<PasswordResetSuccessComponent />} path="/password/reset/success" />
            <Route element={<PasswordResetComponent />} path="/password/reset" />

            <Route element={<Navigate to={"/"} replace={true} />} path="/*" />
          </Routes>
        </div>

        {/* <Footer2 /> */}
        <Footer />
      </div>

      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={true}
        draggable={true}
        closeOnClick={true}
        pauseOnFocusLoss={true}
        pauseOnHover={true}
      />
    </>
  );
};

export default AppRouter;
