import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";

const IndividualUser = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateViewAllReviews = () => {
        navigate("/admin/view-all-reviews");
    };


    function DeleteReview() {
        axios
            .post(
                `${process.env.REACT_APP_URL}remove-review`,
                { review_id: location.state.id},
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                navigate("/admin/view-all-reviews");
            })
            
            // .catch((err) => console.log(err));
       
    }

    //TODO this always grabs the first user for some reason
    //location.state["id"]
    const [reviewTable, setReviewTable] = useState("loadingReview");
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_URL}single-review?review_id=` + String(location.state.id),
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((data) => setReviewTable(data.data[0]))
            // .catch((err) => console.log(err));
    }, []);
    

    if(reviewTable === "loadingReview"){
        return(<p>Loading Individual Review Data</p>)
    }else{
    return (

        <div>
            <div className="row">
            <h3 className="text-center">User Details</h3>

                <div className="col-md-3 bg-light border border-dark py-3">
                    <div className="lead px-3">
                        <p>
                            <b>ID: </b> {location.state["id"]}
                            <br/>
                            <b>User Name:</b> {reviewTable.user_name}
                            <br/>
                            <b>Seller Name:</b> {reviewTable.seller_name}
                            <br/>
                            <b>Rating:</b> {String(reviewTable.rating)}


                        </p>
                    </div>
                </div>
              
            
                <div className="col-md-9 bg-light border border-dark py-3">
                    <div className="lead px-3">
                        <p>
                        <b>Review Text:</b> 
                        <br/>
                        {reviewTable.review_text}
                        </p>
                    </div>
                </div>


                    
          

                <div className="d-flex justify-content-between my-3">
                    <button className="btn btn-info col-md-3 mx-5" onClick={navigateViewAllReviews}>
                        Return to all Reviews Page
                    </button>

                    <button
                        className="btn btn-danger col-md-3 mx-5 "
                        onClick={DeleteReview}
                    >
                        Remove this Review from site 
                    </button>
                </div>
            </div>
        </div>
    );
}
}
;


export default IndividualUser;
