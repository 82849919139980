import react from 'react';

export const IntroVideoComponent = () => {
    return (<>
        <div className='bg-gradient p-0 d-flex flex-column justify-content-center align-items-center' style={{ minHeight: '405px' }}>
            <div className=' row d-flex justify-content-center align-items-center' style={{ minWidth: '720px', minHeight: '405px' }}>
                {/* <iframe width='720px' height='405px' className='d-flex flex-grow-1' src="https://nyce-s3-01.s3.amazonaws.com/videos/NY_Cannabis+Exchange_Rev+B.mp4" title="NYCE Intro" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" controls muted sandbox allowFullScreen webkitallowfullscreen mozallowfullscreen></iframe> */}
                <video width="720px" height="405px" controls>
                    <source src="https://nyce-s3-01.s3.amazonaws.com/videos/NY_Cannabis+Exchange_Rev+B.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    </>);
}