import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/images/new-york-cannabis-exchange.png";

const classes = 'bg-light bg-gradient text-muted border-top?';

const Footer_a = () => {
    return <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

        <div className={`d-flex flex-wrap justify-content-evenly align-items-center p-2 ${classes}`}
        // style={{ color: '#f8f9fa'}}
        >
            <div className="d-flex align-items-center">
            <div className="d-flex">
                <div style={{ overflow: 'hidden', position: 'relative', width: '120px', height: '100px'}}>
                    <img src={logo} width="200" height="160" style={{ position: 'absolute', top: '-10px', left: '-40px'}} />
                </div>
            </div>
            <div className="d-flex">
                <div style={{ overflow: 'hidden', position: 'relative', width: '200px', height: '50px'}}>
                    <img src={logo} width="200" height="160" style={{ position: 'absolute', top: '-100px', left: '0px'}} />
                </div>
            </div>
            </div>
            <div className="d-flex flex-column pxx-5">
                <div className="text-uppercase fw-bold">Products</div>
                <Link to="/about" className="text-reset my-1">About Us</Link>
                <Link to="/termsNconditions" className="text-reset my-1">Terms & Conditions</Link>
                <Link to="/faqs" className="text-reset my-1">FAQs</Link>
            </div>
            <div className="d-flex flex-column pxx-5">
                <div className="text-uppercase fw-bold">
                    {'Contact'}
                    <a className="text-reset" href="https://www.instagram.com/newyorkcannabisexchange/" target="_blank">
                        <i className="fa fa-instagram fw-bold mx-3" />
                    </a>
                </div>
                <div className="text-reset my-1">
                    <i className="fa fa-home me-3" />
                    {'New York, NY 10012, US'}
                </div>
                <div className="text-reset my-1">
                    <i className="fa fa-envelope me-3" />
                    <a href="mailto:admin@nycanx.com">{"admin@nycanx.com"}</a>
                </div>
                <div className="text-reset my-1">
                    <i className="fa fa-phone me-3" />
                    <a href="tel:7165031492">{"716-503-1492"}</a>
                </div>
            </div>
            <div className="d-flex align-self-end p-3">
                {'© 2021 Copyright: '}
                <a className="text-reset fw-bold ms-1" href="https://nycanx.com/"> nycanx.com</a>
            </div>
        </div>
    </>;
}


export default Footer_a;
