import { React, useState, useEffect } from "react";
import { Button, Popover } from 'antd';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Settings.css";
import { Tooltip } from 'antd';

import profilepicture from "../../assets/images/Example_Profile_Picture.PNG";

import { getCsrfCookieValue, clearStorageState, passwordValidation } from "../../utils";
import ModalComponent from "../../components/Modal/Modal";

import { showToastUtil, TOAST_TYPE } from '../../components/Banner/BannerToastUtil';

function SettingsComponent(props) {
    const navigate = useNavigate();

    const successfulChange = () => {
        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Profile updated successfully!' });
        // navigate(0);
        setOldPassword("");
        setNewPass1("");
        setNewPass2("");
    };

    // What are we NOT letting them change
    // * Business type
    // * Username
    // * (allow license and EIN for now)

    const [oldpass, setOldPassword] = useState("");
    const [newpass1, setNewPass1] = useState("");
    const [newpass2, setNewPass2] = useState("");

    // const [username, setUsername] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [licensenumber, setLicensenumber] = useState("");
    const [company, setCompany] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [website, setWebsite] = useState("");
    const [phone, setPhone] = useState("");
    const [ein, setEIN] = useState("");
    const [imageURL, setImageURL] = useState(
        "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
    );

    const [showUnenrollModal, toggleUnenrollModal] = useState(false);
    const [showPPModal, setShowPPModal] = useState(false);

    // const [business, setBusiness] = useState("cultivator");
    // const [alerts, setAlerts] = useState([]);
    // const [alertsToggle, setAlertsToggle] = useState("");

    const [username, setUserName] = useState(window.localStorage.getItem("user_name"));

    const [images, setImages] = useState("");

    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_URL}view-users`,
                { username: username },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                setFromData(res.data);
                setImages(res.data.images);
            });
        // .catch((err) => console.log(err));
    }, []);

    const setFromData = (data) => {
        setFirst(data.first_name);
        setLast(data.last_name);
        setPassword(data.password);
        setEmail(data.email);
        setLicensenumber(data.license_number);
        setCompany(data.company_name);
        setStreet(data.street);
        setCity(data.city);
        setState(data.state);
        setZip(data.zip);
        setWebsite(data.website);
        setPhone(data.phone_number);
        setEIN(data.ein_number);
        if (data.images) {
            setImageURL(data.images);
        }
    };

    const changePassword = () => {
        console.log("called")
        // Reset alerts
        setOldpassAlert("");
        setNewpass1Alert("");
        setNewpass2Alert("");
        setPassGeneralAlert("");

        let input_data = {
            old_password: oldpass,
            new_pass1: newpass1,
            new_pass2: newpass2,
        };
        
        const resOldPass = passwordValidation(oldpass);
        const resNewPass1 = passwordValidation(newpass1);
        const resNewPass2 = passwordValidation(newpass2);

        if(resOldPass.status && resNewPass1.status && resNewPass2.status){
            input_data["username"] = window.sessionStorage.getItem("username");
    
            axios
                .post(`${process.env.REACT_APP_URL}editpassword`, input_data, {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    successfulChange();
                })
                .catch((err) => {
                    for (let i in alerts) {
                        if (i in err.response.data) {
                            if (i === "old_password") {
                                setOldpassAlert("Please enter old password");
                            } else if (i === "new_pass1") {
                                setNewpass1Alert("Please enter a new password");
                            } else if (i === "new_pass2") {
                                setNewpass2Alert("Please enter the same new password");
                            } else {
                                alerts[i](err.response.data[i][0]);
                            }
                        }
                    }
                });
        }
        else{
            if(!resOldPass.status) setOldpassAlert(resOldPass.msg);
            if(!resNewPass1.status) setNewpass1Alert(resNewPass1.msg);
            if(!resNewPass2.status) setNewpass2Alert(resNewPass2.msg);
        }
    };

    // Submit Settings Change Form
    const submitForm = () => {
        // Reset alerts
        SetUsernameAlert("");
        SetFirstAlert("");
        SetLastAlert("");
        SetPasswordAlert("");
        SetEmailAlert("");
        SetLicensenumberAlert("");
        SetCompanyAlert("");
        SetStreetAlert("");
        SetCityAlert("");
        SetStateAlert("");
        SetZipAlert("");
        SetWebsiteAlert("");
        SetPhoneAlert("");
        SetEINAlert("");
        SetBusinessAlert("");
        SetSettingsGeneralAlert("");

        let input_data = {
            first_name: first,
            last_name: last,
            password: password,
            license_number: licensenumber,
            company_name: company,
            street: street,
            city: city,
            state: state,
            zip: zip,
            website: website,
            phone_number: phone,
            ein_number: ein,
        };

        input_data["username"] = window.localStorage.getItem("username");

        axios
            .post(`${process.env.REACT_APP_URL}editsettings`, input_data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                successfulChange();
            })
            .catch((err) => {
                // Consolidating. All of the bad responses should throw 404 and be caught as Errors by axios!

                for (let i in alerts) {
                    if (i in err.response.data) {
                        alerts[i](err.response.data[i][0]);
                    }
                }
            });
    };

    const changeProfile = () => {
        axios
            .post(
                `${process.env.REACT_APP_URL}update-profile-pic`,
                { images: document.getElementById("images-upload").files[0] },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                successfulChange();
                setShowPPModal(false);
            });
        // .catch((err) => {
        //     console.log(err);
        // });
    };

    // Alerts
    const [oldpassAlert, setOldpassAlert] = useState("");
    const [newpass1Alert, setNewpass1Alert] = useState("");
    const [newpass2Alert, setNewpass2Alert] = useState("");
    const [passGeneralAlert, setPassGeneralAlert] = useState("");

    const [usernameAlert, SetUsernameAlert] = useState(""); // Not needed
    const [firstAlert, SetFirstAlert] = useState("");
    const [lastAlert, SetLastAlert] = useState("");
    const [passwordAlert, SetPasswordAlert] = useState("");
    const [emailAlert, SetEmailAlert] = useState("");
    const [licensenumberAlert, SetLicensenumberAlert] = useState("");
    const [companyAlert, SetCompanyAlert] = useState("");
    const [streetAlert, SetStreetAlert] = useState("");
    const [cityAlert, SetCityAlert] = useState("");
    const [stateAlert, SetStateAlert] = useState("");
    const [zipAlert, SetZipAlert] = useState("");
    // const [addressAlert, SetAddressAlert] = useState("");
    const [websiteAlert, SetWebsiteAlert] = useState("");
    const [phoneAlert, SetPhoneAlert] = useState("");
    const [einAlert, SetEINAlert] = useState("");
    const [businessAlert, SetBusinessAlert] = useState("");
    const [settingsGenAlert, SetSettingsGeneralAlert] = useState("");

    // k/v pair
    const alerts = {
        new_pass1: setNewpass1Alert,
        new_pass2: setNewpass2Alert,
        old_password: setOldpassAlert,
        pass_general: setPassGeneralAlert,
        username: SetUsernameAlert,
        first_name: SetFirstAlert,
        last_name: SetLastAlert,
        email: SetEmailAlert,
        password: SetPasswordAlert,
        phone_number: SetPhoneAlert,
        license_number: SetLicensenumberAlert,
        ein_number: SetEINAlert,
        website: SetWebsiteAlert,
        company_name: SetCompanyAlert,
        business_type: SetBusinessAlert,
        street: SetStreetAlert,
        city: SetCityAlert,
        state: SetStateAlert,
        zip: SetZipAlert,
        settings_general: SetSettingsGeneralAlert,
    };

    // Enter Forms
    const settingsChangeEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            submitForm();
        }
    };

    const passwordChangeEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            changePassword();
        }
    };

    const handleUnenrollment = () => {
        const url = `${process.env.REACT_APP_URL}unenroll`
        axios.post(url, { username: username }, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        }).then((res) => {
            showToastUtil({ status: TOAST_TYPE.INFO, message: 'Unenrollment successful.' });
            props.updateOnLogout();
            navigate('/');
        })
        .catch((err) => {
            showToastUtil({ status: TOAST_TYPE.ERROR });
            console.log(err);
        });
    }

    const handleNewPwd = (value) => {
        setNewPass1(value);
        validatePassword(value);
    }

    const handleConfirmNewPwd = (value) => {
        setNewPass2(value);
        validateConfirmPassword(value);
    }

    const validatePassword = (value) => {
        
        if (value === "") {
            setNewpass1Alert("Please enter a password");
        }else if(!passwordValidation(value).status){
            setNewpass1Alert(passwordValidation(value).msg);
        }else{
            setNewpass1Alert("");
        }
    }

    const validateConfirmPassword = (value) => {
        if (value === "") {
            setNewpass2Alert("Please enter a password");
        }else if (newpass1 != value) {
            setNewpass2Alert("Password does not match");
        }else{
            setNewpass2Alert("");
        }
    }


    return(
        <div className="row justify-content-center align-items-center mt-5 mb-5">
            <div className="col-sm-12 col-md-10 col-lg-8" style = {{marginTop: "50px"}}>
                <div className="card container-md p-5" id="main" style={{ maxWidth: "750px", borderRadius: "10px", overflow: "visible" }}>
                    
                    <Popover content={
                        <div style={{display: 'inline-flex'}}>
                            <div style={{display: 'block'}}>
                            <input
                                className="form-control"
                                type="file"
                                id="images-upload"
                            />
                            <small id="image-help" className="form-text text-muted">
                                Supported file types: .png or .jpg
                            </small></div>
                            <Button type="primary"
                            onClick={changeProfile} style={{marginLeft: "10px"}}>Update Picture</Button>
                        </div>
                    } 
                    title="Upload profile picture"
                    trigger="click"
                    open = {showPPModal}
                    onOpenChange = {() => {setShowPPModal(true)}}
                    >
                        <span style={{cursor:"pointer"}}>
                        <img
                            src={images && images.length != 0 ? images : profilepicture}
                            className="d-block"
                            width="150"
                            height="150"
                            style={{ position: "absolute", top: "-30px", left: "50%", transform: "translate(-50%, -40%)", borderRadius: "50%", objectFit: "cover" }}
                        ></img></span>
                    </Popover>


                    <div className="row g-5 mb-3">
                        <fieldset className="border p-3" style={{ borderRadius: "5px" }}>
                            <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}> My Profile </legend>

                            <div className="row gy-2 gx-4 align-items-end">
                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">First Name *</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        value={first}
                                        onChange={(e) => setFirst(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{firstAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <p className="label m-0">Last Name *</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        value={last}
                                        onChange={(e) => setLast(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{lastAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                    <label className="labels">Email *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        value={email}
                                        readOnly
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                        style = {{cursor: "not-allowed"}}
                                    />
                                    <p className="error">{emailAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">License Number *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        placeholder="License Number"
                                        value={licensenumber}
                                        onChange={(e) => setLicensenumber(e.target.value.replace(/[^0-9]/g, ""))}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                        style = {{cursor: "not-allowed"}}
                                    />
                                    <p className="error">{licensenumberAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">Company Name *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        placeholder="Company Name"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                        style = {{cursor: "not-allowed"}}
                                    />
                                    <p className="error">{companyAlert}</p>
                                </div>   

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">Website *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Website"
                                        value={website}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{websiteAlert}</p>
                                </div>  

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">Phone Number *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{phoneAlert}</p>
                                </div>  

                                <div className="col-sm-12">
                                    <label className="labels">Street *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Street"
                                        value={street}
                                        onChange={(e) => setStreet(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{streetAlert}</p>
                                </div> 

                                <div className="col-sm-12 col-md-4">
                                    <label className="labels">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{cityAlert}</p>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <label className="labels">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="State"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{stateAlert}</p>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <label className="labels">Zip</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zip"
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value.replace(/[^0-9]/g, ""))}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{zipAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">EIN Number *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        placeholder="Ein Number"
                                        value={ein}
                                        onChange={(e) => setEIN(e.target.value.replace(/[^0-9]/g, ""))}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                        style = {{cursor: "not-allowed"}}
                                    />
                                    <p className="error">{einAlert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <label className="labels">Confirm Current Password*</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        // value=""
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyDown={(e) => settingsChangeEnter(e)}
                                    />
                                    <p className="error">{passwordAlert}</p>
                                </div>
                            </div>

                            <div className="row mt-3 gy-2 gx-4 d-flex justify-content-end">
                                <div className="col-sm-auto">
                                    <button
                                        className="btn btn-outline-primary"
                                        onClick={() => toggleUnenrollModal(true)}
                                        type="button"
                                    >
                                        Unenroll from NYCE
                                    </button>
                                </div>
                                
                                <div className="col-sm-auto">
                                    <button
                                        className="btn btn-primary"
                                        onClick={submitForm}
                                        type="button"
                                    >
                                        Save Profile
                                    </button>
                                    <p className="error">{settingsGenAlert}</p>
                                </div>
                                
                            </div>

                            <ModalComponent
                                showModal={showUnenrollModal}
                                headerText={'Unenrollment from NYCE'}
                                closeCallback={() => toggleUnenrollModal(false)}
                            >
                                <>
                                    <div className="fw-bold py-1 d-flex justify-content-center" style={{ fontSize: '1.25rem' }}>{`Are you sure you want to unenroll?`}</div>
                                    <div className="fw-bold py-1 d-flex justify-content-center">
                                        <Button className="mx-3" onClick={() => handleUnenrollment()} size="large" style={{ background: '#FF7575', color: "white" }}>Unenroll</Button>
                                        <Button className="mx-3" onClick={() => toggleUnenrollModal(false)} size="large" style={{ background: '#738FFF', color: "white" }}>Cancel</Button>
                                    </div>
                                </>
                            </ModalComponent>
                        
                        </fieldset>
                    </div>

                    <div className="row g-5">
                        <fieldset className="border p-3" style={{ borderRadius: "5px" }}>
                            <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}> Change Password </legend>

                            <div className="row gy-2 gx-4 align-items-start">
                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0">Current Password *</p>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        value={oldpass}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        onKeyDown={(e) => passwordChangeEnter(e)}
                                    />
                                    <p className="error">{oldpassAlert}</p>
                                </div>
                           
                                <div className="col-sm-12 col-md-6">
                                    <div style = {{display: "flex", justifyContent: "space-between"}}>
                                        <p className="label m-0">New Password *</p>
                                        <Tooltip style = {{float: "end"}} title={
                                            <>
                                                Password requirements: <br/>
                                                &#x2022; Password Length must be between 8 and 64 <br/>
                                                &#x2022; Contains at least one small alphabet<br/>
                                                &#x2022; Contains at least one capital alphabet <br/>
                                                &#x2022; Contains at least one digit <br/>
                                                &#x2022; Contains no whitespaces <br/>
                                                &#x2022; Contains at least one special character
                                            </>
                                        }>
                                            <span>
                                                <i class="fa fa-info-circle"></i>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        value={newpass1}
                                        onChange={(e) => handleNewPwd(e.target.value)}
                                        onKeyDown={(e) => passwordChangeEnter(e)}
                                    />
                                    <p className="error">{newpass1Alert}</p>
                                </div>
                            </div>

                            <div className="row gy-2 gx-4 align-items-center">

                                <div className="col-sm-12 col-md-6">
                                    <p className="label m-0">Confirm New Password *</p>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        value={newpass2}
                                        onChange={(e) => handleConfirmNewPwd(e.target.value)}
                                        onKeyDown={(e) => passwordChangeEnter(e)}
                                    />
                                    <p className="error">{newpass2Alert}</p>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <button
                                        className="btn btn-primary"
                                        onClick={changePassword}
                                        type="button"
                                        style = {{width: "100%"}}
                                    >
                                        Update Password
                                    </button>
                                    <p className="error">{passGeneralAlert}</p>
                                </div>
                            </div>

                        </fieldset>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default SettingsComponent;