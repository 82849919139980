import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tag, Row, Col, Card, notification } from 'antd';
import AntTable from "./tables/AntTable.jsx";
import IndividualExistingUserComponent from "./IndividualExistingUser.js";
import { getCsrfCookieValue } from "../utils";

//I used this tutorial to create the searchable table
//https://www.youtube.com/watch?v=MY6ZZIn93V8&list=LL&index=2&t=1002s


const ViewAllUsers = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
      api.success({
        message: `User Approval`,
        description:
          'User request has been approved.',
        placement,
        duration: 2
      });
    };

    var SubPage = false;

    const [listingsTable, setUsersTable] = useState([]);

    const getAllUsers = () => {
        axios
        .get(`${process.env.REACT_APP_URL}view-users`, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        })
        .then((data) => setUsersTable(data.data))
    }

    useEffect(() => {
        getAllUsers();
    }, []);


    // function navigateListing(product_id) {
    //     navigate("/admin/individual-listing", { state: { product_id } });
    // }

    // function navigateAdmin() {
    //     navigate("/admin");
    // }

    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = listingsTable.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(listingsTable.length / recordsPerPage)

   const columnInfo = [{name: "User Name", width: "20%"}, {name: "First_Name", width: "20%"}, {name: "Last_Name", width: "20%"}]
   const data = listingsTable.filter(item => item.account_status).map(item => {
    return {
        key: item.username,
        username: item.username,
        first_name: item.first_name,
        last_name: item.last_name,
    }
   })

   console.log("data", data);

//    const handleRemove = (productId) => {
//         console.log("handleRemove called in parent component", productId);
//         axios
//         .post(
//             `${process.env.REACT_APP_URL}delete-listing`,
//             { product_id: productId },
//             {
//                 headers: {
//                     "X-CSRFToken": getCsrfCookieValue(),
//                 },
//                 withCredentials: true,
//             }
//         )
//         getAllUsers();
//    }

   const handleView = (username) => {
    // navigate("/admin/individual-listing", { state: { username } });
    console.log("username in handleview", username);
    navigate("/admin/existing-users/"+username, { state: { username } });
    // console.log("handleView called in parent component", productId);
   } 

    const handleRemove = (username) => {
        console.log("username in handleRemove", username);
        axios
            .post(
                `${process.env.REACT_APP_URL}remove-user`,
                { username: username },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                console.log(res);
                // navigate("/admin/");
                getAllUsers();
                openNotification('topRight');
                // alert("Approved");
            })
            .catch((err) => console.log(err));
            // .then(console.log("deleted User", location.state["Username"]))
            // .catch((err) => console.log(err));

        // getAllUsers();
        // // navigate("/admin/view-all-new-users");
        // alert("Removed");
    }

   return(
        <div style = {{padding: "0px"}}>
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Existing Users </h5>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actions = {['View', 'Remove']} handleView = {handleView} handleRemove = {handleRemove}/>
                </Col>
            </Row>

            
        </div>
    )
};

export default ViewAllUsers;
