import React, { useState, useEffect } from "react";
import logo from "../../assets/images/new-york-cannabis-exchange.png";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getCsrfCookieValue } from "../../utils";

function PasswordResetRequestComponent() {

    const navigate = useNavigate();

    const [userName, setUserName] = useState("");

    const [errAlert, setErrAlert] = useState("");

    const submitPasswordResetRequestEvent = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            submitPasswordResetRequest();
        }
    };


    const submitPasswordResetRequest = () => {
        const data = {
            "username": userName
        };

        axios
            .post(`${process.env.REACT_APP_URL}password/reset/request`, data, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            })
            .then((res) => {
                navigate("/password/reset/submitted", { refresh: true });
            })
            .catch((err) => {
                setErrAlert(err.response.data);
            });
    }


    return (
        <>
            {
                    <>
                        <div className="container-fluid p-0">
                            <div className="mainContainer">
                                <div className="row">
                                    <div className="Auth-form-container">
                                        <form className="Auth-form">
                                            <div className="Auth-form-content">
                                                <h3 className="Auth-form-title">Reset Password</h3>
                                                
                                                <div className="form-group mt-3 ">
                                                    <label>Enter Username/Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control mt-1"
                                                        placeholder="username/email"
                                                        value={userName}
                                                        onChange={(e) => setUserName(e.target.value)}
                                                        onKeyDown={(e) => submitPasswordResetRequestEvent(e)}
                                                    />
                                                    
                                                </div>
                                                <div className="d-grid gap-2 mt-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={submitPasswordResetRequest}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                                <p className="error mt-2">{errAlert}</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                
            }
        </>
    )
}

export default PasswordResetRequestComponent