import React, { useState, useEffect } from "react";

// Creates a form element for the Detailed Attributes:
//  name = Intended label for attribute in HTML
//  tag = Inteded "id" for the elements (needs to be unique)
//  inputTag = Associated input field tag -> used for "Other" input.
//  options = Array of dropdown options. DO NOT PUT "Other" as an option!
//      Should take a list of options and generate a dropdown form w/ "Other" option that is available if selected

function DetailedAttribute({ name, tag, inputTag, options, detail, onChange, value }) {
    // console.log(value);

    // const [drop, setDrop] = useState("");
    // const [other, setOther] = useState("");

    // if (options.includes(value)) {
    //     document.getElementById(inputTag).disabled = true;
    //     // document.getElementById(tag).
    //     // return value;
    //     console.log("in the options!");
    // } else {
    //     document.getElementById(inputTag).disabled = false;
    //     document.getElementById(inputTag).value = value;
    //     value = "Other";
    //     // return "Other";
    //     console.log("hi there");
    // }

    // if (options.includes(value)) {
    //     setVal(value);
    // } else {
    //     console.log("hi");
    // }

    // useEffect(() => {
    //     setVal(value);
    // });

    // useEffect(() => {
    //     onChange(val);
    // });

    // const [select, setSelect] = useState(value); // Selected option
    // const [other, setOther] = useState(false); // Bool trigger for "Other"
    // const [inputVal, setInputVal] = useState(""); // "Other" value

    // NEW IDEA -> I don't even need state here. Update the input once based on when the thing loads, and change the
    // value of "value" on the way in. No useState() w/ vars required.
    // Then, just have logic to track the CURRENT situation with displaying the disabled input, etc.

    // setVal(value);

    // useEffect(() => {
    //     setVal(value);
    // }, []);

    // The way this works, is every time this component changes, it sends a trigger up to the parent
    // It then changes the UPPER value of the parent (Create.js)
    // This change is then propagated back DOWN to this component.
    // The DetailedComp. is then re-rendered w/ those b/c of react DOM checking for changes
    // Set the state based on the change HERE.

    // Adjust value BEFORE it renders for the first time
    let input = "";
    let other = false;

    // Set the default state of the app
    if (
        options.includes(value) ||
        value === "" ||
        value === "Open this select menu" ||
        value === "N/A"
    ) {
    } else {
        input = value;
        value = "Other"; // i.e. set Value to "other"
        other = true;
    }

    // This runs AFTER the page renders, allowing changes to be made.
    // Not really React friendly, but it will have to do.
    useEffect(() => {
        if (!other) {
            document.getElementById(inputTag).disabled = true;
            document.getElementById(inputTag).value = "";
        } else {
            document.getElementById(inputTag).disabled = false;
            if (input === "Other") input = ""; // Get rid of a weird default case (issue if someone puts "Other" manually though!)
            document.getElementById(inputTag).value = input;
        }
    });

    

    return (
        <div className="row p-1 gx-2 gy-2">
            
            { name  &&
                <label htmlFor={tag} className="required">
                    {name}
                </label>
            }

            {/* IS there a better way to get the various elements into the thing? Templating in a prior value
                is going to be tricky if there is multiple cases! For instance, the drop down AND an other clause...
                I'd have to handle BOTH scenarios of if it's not in the actual values, on the set function, it has to set the 
                correct values accordingly... Maybe I can make a better method of it? */}

            <div className="col-md">
                <select
                    className="form-select"
                    // style={{marginRight: "10px"}}
                    aria-label={name}
                    name={tag}
                    id={tag}
                    value={value} //todo add special cases for rendering this as "Other" or not.
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                >
                    <option defaultValue="Default">Open this select menu</option>
                    {options.map((opt, i) => {
                        return <option key={i} value={opt}>{opt}</option>;
                    })}
                    <option value="N/A">N/A</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div className="col-md">
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: other ? "white" : "#E2E2E2"}}
                    aria-label="Other input field"
                    placeholder="Enter other"
                    id={inputTag}
                    disabled={!other}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                />
            </div>
            <small id="itemHelp" className="form-text text-muted">
                {detail}
            </small>
        </div>
    );
}

export default DetailedAttribute;
