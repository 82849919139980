import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import defaultProductImage from '../../assets/images/defaultProductImage.png'
import { Image } from 'antd';

import './Carousel.css'


function Carousel(props) {

    const { images, canDelete } = props;

    const [productImages, setProductImages] = useState(images);
    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        setProductImages(images);
    }, [props.images])

    const setDefaultImage = (index) => {
        let newProductImages = [...productImages];
        newProductImages[index] = defaultProductImage;

        setProductImages(newProductImages);
    }

    const handlePrev = () => {
        const lastIndex = productImages.length - 1;
        const shouldResetIndex = activeIndex === 0;
        const index = shouldResetIndex ? lastIndex : activeIndex - 1;

        setActiveIndex(index);
    }

    const handleNext = () => {
        const lastIndex = productImages.length - 1;
        const shouldResetIndex = activeIndex === lastIndex;
        const index = shouldResetIndex ? 0 : activeIndex + 1;

        setActiveIndex(index);
    }

    const removeImage = () => {
        let newProductImages = [...productImages];
        let removing_image_id = newProductImages[activeIndex].id;
        newProductImages.splice(activeIndex, 1);

        if (activeIndex >= productImages.length - 1) setActiveIndex(productImages.length - 2);
        setProductImages(newProductImages);
        props.removeImage(removing_image_id);
    }


     
   return (
        <div className="row mb-2">
            <div className = "row g-0 justify-content-center">
                <div className = "col d-flex align-items-center justify-content-center" style = {{maxWidth: "18px"}}>
                    
                    { productImages.length > 1 ?
                        <button className="custom-prev-icon d-flex align-items-center justify-content-center border" onClick={handlePrev}> 
                            <FontAwesomeIcon icon={faChevronLeft} size="1x" style = {{color: "white"}} /> 
                        </button> : "" }
                </div>

                

                <div className = "col" style = {{maxWidth: '350px'}}>
                    <div className="position-relative d-flex justify-content-center align-items-center" style={{ minWidth: '350px', maxWidth: '350px', padding: "0px" ,minHeight: '250px', maxHeight: '250px', overflow: 'hidden' }}>
                        <Image src = {(productImages.length >= 1 && productImages[activeIndex].file) || defaultProductImage}/>
                        
                        {/* <img src={ (productImages.length >= 1 && productImages[activeIndex].file) || defaultProductImage} class="d-block w-100" alt= {`loading image`}
                                        onError={() => setDefaultImage(activeIndex)}/> */}
                    {/* <div className="position-relative">
                        <img src={ (productImages.length >= 1 && productImages[activeIndex].file) || defaultProductImage} class="d-block w-100" alt= {`loading image`}
                                onError={() => setDefaultImage(activeIndex)} style={{ height: 250 }}/> */}
                        {
                            canDelete && productImages.length >= 1 && productImages[activeIndex].file &&
                            <i className="position-absolute top-0 end-0" onClick={removeImage}>
                                <FontAwesomeIcon icon={faCircleXmark} size="xl" style = {{color: "red"}} /> 
                            </i>
                        }
                        
                    </div>
                </div>


                <div className="col d-flex align-items-center justify-content-center" style={{ maxWidth: "18px" }}>
                    {productImages.length > 1 ?
                        <button className="custom-next-icon d-flex align-items-center justify-content-center border" onClick={handleNext}>
                            <FontAwesomeIcon icon={faChevronRight} size="1x" style={{ color: "white" }} />
                        </button> : ""}
                </div>


                <div className='d-flex justify-content-center'>
                    {
                        productImages.length > 1 ?

                            productImages.map((productImage, index) => {
                                return (
                                    <div key={index} className="me-2">
                                        <button className={index === activeIndex ? " carousel-indicator indicator-active" : "carousel-indicator"} onClick={() => setActiveIndex(index)}></button>
                                    </div>
                                )
                            })
                            :
                            <div style={{ margin: "12px" }}></div>

                    }
                </div>
            </div>
        </div>
    )
}

export default Carousel