import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";

const UpdateUserAccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [username, SetUsername] = useState("");
    const [access, SetAccess] = useState("");
    const [usernameAlert, SetUsernameAlert] = useState("");
    const [accessAlert, SetAccessAlert] = useState("");
    const [apiAlert, SetApiAlert] = useState("");
    const [missingToggle, SetMissingToggle] = useState("");

    const handleChange = function (value, name) {
        if (name === "username") {
            SetUsername(value);
        }else if (name === "access") {
            SetAccess(value);
        }


    }

    function handleSubmit() {
        SetUsernameAlert("");
        SetAccessAlert("");
        let uiValid = true;
        if (username === "") {
            SetUsernameAlert("Please enter a username");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if (access === "") {
            SetAccessAlert("Please select a access type");
            SetMissingToggle("Please review the form above for errors");
            uiValid = false;
        }
        if(!uiValid)
            return;
        axios
            .post(
                `${process.env.REACT_APP_URL}update-user-access`,
                {username:username,business_type:access},
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'User Business Type updated successfully.' });
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to update User Business Type' });
                console.error(err);
            });

    }

    return (
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <h3 className="row justify-content-center align-items-center mt-5 mb-5">Update User Access</h3>
        <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="col-sm-12 col-md-4">
                <p className="label m-0">Username *</p>
                <input
                    type="text"
                    className="form-control mt-1"
                    value={username}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                    name="username"
                />
                <p className="error">{usernameAlert}</p>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-8">
                <p className="label m-0">  Business Type *</p>
                <select
                    className="form-select col-sm-12 col-md-4"
                    name="access"
                    id="access"
                    style={{backgroundColor: "#EFEFEF"}}
                    // value={business}
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                >
                    <option selected>Select the access type</option>
                    <option value="admin">Admin</option>
                    <option value="cultivator">Cultivator</option>
                    <option value="processor">Processor</option>
                    <option value="dispensary">Dispensary</option>
                </select>
                <p className="error">{accessAlert}</p>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        style = {{float: "left"}}
                    >
                    Update
                    </button>
                </div>
            </div>
            <p className="error">{apiAlert}</p>
        </div>
      </div>
    );
}
;


export default UpdateUserAccess;
