import React, { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import ModalComponent from "../components/Modal/Modal";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { TOAST_TYPE, showToastUtil } from "../components/Banner/BannerToastUtil";
import { GlobalConfiguration, downloadPdfFile } from "../utils";
import { getCsrfCookieValue } from "../utils";
import { Table, Row, Col } from 'antd';

interface Props {
    showModal: boolean
    closeCallback: () => void;
    // unique_id?: any;
}

interface InvoiceHistoryData {
    id: number,
    invoice_date: string,
    invoice_generated_by: string,
    download?: ReactNode
}

enum Views {
    Default,
    Regenerate,
    History,
}

const InvoiceActionsComponent = (props: Props) => {
    const { showModal, closeCallback } = props;
    const location = useLocation();

    const [show, toggleShow] = useState<boolean>(showModal);
    const [productID] = useState<string>(location.state.product_id);
    const [defaultView, setDefaultView] = useState<Views>(Views.Default);
    const [termsText, setTermsText] = useState<string>('');
    const [disclaimerText, setDisclaimerText] = useState<string>('');
    const [emailUserCheckbox, setEmailUserCheckbox] = useState<boolean>(true);
    const [globalConfig, setGlobalConfig] = useState<GlobalConfiguration>({ company: {}, invoice: {} });
    const [invoiceHistoryData, setInvoiceHistoryData] = useState<InvoiceHistoryData[] | null>(null);

    const renderDownloadButtonTable = (text: string, record: any, index: number) => {
        return <Button className="d-flex" onClick={() => downloadInvoice(true, record?.id)} size="middle" style={{ background: '#738FFF', color: "white" }}>{'Download'}</Button>;
    }

    const COLUMN_CONFIG = [
        { title: 'Invoice Date', dataIndex: 'invoice_date', key: 'invoice_date' },
        { title: 'Invoice Generated By', dataIndex: 'invoice_generated_by', key: 'invoice_generated_by' },
        { title: 'Download PDF', dataIndex: '', key: 'download', render: renderDownloadButtonTable },
    ]
    
    useEffect(() => {
        if (defaultView === Views.Regenerate && disclaimerText === '') {
            const url = `${process.env.REACT_APP_URL}get-config/`;
            axios.get(url, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            }).then((response) => {
                const config: GlobalConfiguration = response.data;
                setGlobalConfig(config);
            }).catch(() => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: "Failed to get default global configuration." });
            });
        }
        if (defaultView === Views.History && !invoiceHistoryData) {
            const url = `${process.env.REACT_APP_URL}retrieve_all_invoices/${productID}`;
            axios.get(url, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            }).then((response) => {
                const data: InvoiceHistoryData[] = response.data?.data;
                setInvoiceHistoryData(data);
            }).catch((e) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: "Failed to get invoice history." });
            });
        }
    }, [defaultView]);

    useEffect(() => {
        if (globalConfig.invoice.disclaimer) setDisclaimerText(globalConfig.invoice.disclaimer)
        if (globalConfig.invoice.terms_and_conditions) setTermsText(globalConfig.invoice.terms_and_conditions.join('\n'))
    }, [globalConfig.invoice.disclaimer, globalConfig.invoice.terms_and_conditions]);

    const downloadInvoice = (useInvoiceId: boolean, invoiceId ?: number) => {
        const url = (useInvoiceId && invoiceId !== undefined)
            ? `${process.env.REACT_APP_URL}retrieve_invoice_by_id/${invoiceId}`
            : `${process.env.REACT_APP_URL}retrieve_invoice/${productID}`;
        axios.get(url, {
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            responseType: 'blob', // Add this property
            withCredentials: true,
        }).then((response) => {
            const fileName = `Invoice-${location.state?.product_id}`;
            const successful = downloadPdfFile(response.data, fileName);
            if (!successful) {
                throw Error('');
            } else {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Invoice downloaded successfully!' });
            }
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR });
        });
    }

    const emailInvoice = () => {
        const url = `${process.env.REACT_APP_URL}email_invoice/${productID}`;
        axios.post(url, {}, {
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            withCredentials: true,
        }).then((response) => {
            showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Invoice sent to user's registered email!" });
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR });
        });
    }

    const regenerateInvoice = () => {
        const url = `${process.env.REACT_APP_URL}generate_invoice/${productID}`;
        const payload = {
            terms_and_conditions: termsText.split('\n'),
            disclaimer: disclaimerText,
            email_user: emailUserCheckbox
        };
        axios.post(url, payload, {
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            withCredentials: true,
        }).then((response) => {
            showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Invoice regenrated successfully!" });
            setDefaultView(Views.Default)
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR });
        });
    }

    return (
        <React.Fragment>
            <ModalComponent
                showModal={show}
                headerText={defaultView === Views.Regenerate ? 'Regenerate Invoice' : defaultView === Views.History ? 'Invoice History' : 'Invoice Actions'}
                closeCallback={closeCallback}
            >
                <React.Fragment>
                    <div className="d-flex justify-content-center p-2">{`Product ID: ${productID}`}</div>
                    {
                        defaultView === Views.History ?
                            <>
                                <Row justify="center" style={{ marginTop: "20px" }}>
                                    <Col xs={23} md={22}>
                                        <Table dataSource={invoiceHistoryData ?? []} columns={COLUMN_CONFIG} />
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => setDefaultView(Views.Default)} size="large" style={{ background: '#738FFF', color: "white" }}>{'Go Back'}</Button>
                                    {/* <Button className="d-flex" onClick={() => regenerateInvoice()} size="large" style={{ background: '#1ABE00', color: "white" }}>{'Regenerate'}</Button> */}
                                </div>

                            </> : defaultView === Views.Regenerate ? <>
                                <div className="p-2">
                                    <label className="required">Terms And Conditions</label>
                                    <textarea
                                        className="form-control"
                                        name={''}
                                        placeholder="Enter Terms and Conditions"
                                        required={true}
                                        // style={{minHeight: '120px'}}
                                        rows={5}
                                        value={termsText}
                                        onChange={(e) => setTermsText(e.target.value)}
                                    />
                                </div>
                                <div className="p-2" style={{ minWidth: '00px' }}>
                                    <label className="required">Disclaimer</label>
                                    <textarea
                                        className="form-control"
                                        name={''}
                                        placeholder="Enter Disclaimer Text"
                                        required={true}
                                        // style={{minHeight: '120px'}}
                                        rows={5}
                                        value={disclaimerText}
                                        onChange={(e) => setDisclaimerText(e.target.value)}
                                    />
                                </div>
                                <div className="p-2">
                                    <input
                                        type="checkbox"
                                        className="d-inline-flex form-check-input p-2"
                                        // id="auction-checkmark"
                                        checked={emailUserCheckbox}
                                        onChange={(e) => setEmailUserCheckbox(!emailUserCheckbox)}
                                        style={{ marginLeft: "5px" }}
                                    />
                                    <div className="d-inline-flex mx-3">{'Email Invoice to User'}</div>
                                </div>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => setDefaultView(Views.Default)} size="large" style={{ background: '#738FFF', color: "white", marginRight: "15px" }}>{'Go Back'}</Button>
                                    <Button className="d-flex" onClick={() => regenerateInvoice()} size="large" style={{ background: '#1ABE00', color: "white" }}>{'Regenerate'}</Button>
                                </div>
                            </> : <>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => emailInvoice()} size="large" style={{ background: '#738FFF', color: "white" }}>{'Email Invoice to User'}</Button>
                                </div>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => downloadInvoice(false)} size="large" style={{ background: '#738FFF', color: "white" }}>{'Download Invoice'}</Button>
                                </div>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => setDefaultView(Views.Regenerate)} size="large" style={{ background: '#738FFF', color: "white" }}>{'Regenerate Invoice'}</Button>
                                </div>
                                <div className="d-flex justify-content-center p-2">
                                    <Button className="d-flex" onClick={() => setDefaultView(Views.History)} size="large" style={{ background: '#738FFF', color: "white" }}>{'View Invoice History'}</Button>
                                </div>
                            </>
                    }
                </React.Fragment>

            </ModalComponent>

        </React.Fragment>
    );
};

export default InvoiceActionsComponent