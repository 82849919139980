import React,{ useState,useEffect } from 'react';
import ListingNew from './ListingNew';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";

function ListingCard(props) {
  
  const listing = props.listing;
  let sellerRating = "N/A";

  // what states do we have for the cards?
  // 1. Listing has not yet started. (Start Date:)
  // 2. Listing has started. (End Date:)
  // 3. Listing time is over but not marked is_auc_running=false (Processing Winner)
  // 4. is_auc_running = false (Listing Over)

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_URL}reviews`, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
    }).then((data) => { 
        let ratings = data.data.filter((rating) => rating.seller_name === listing.seller_id); 
        if(ratings.length != 0){
          const sum = ratings.reduce((acc, value) => {
            return acc + value;
          });
          sellerRating = sum / ratings.length;
          sellerRating = Math.round(sellerRating * 2) / 2;
        }
    });
  }, [props.listing]);

  const isListingCompleted = () => {
    return !listing.is_auc_running;
  }

  const isProcessingWinner = () => {
    return Date.now() > new Date(listing.end_datetime)
  }

  const isListingStarted = () => {
    return (new Date(listing.start_datetime) <= Date.now() && Date.now() <= new Date(listing.end_datetime));
  }

  const getBidPrice = () => {
    return listing.auction_enable ?  `$ ${listing.curr_auction_price}` : "";
  }

  const getBinPrice = () => {
    return listing.bin_enable ?  `$ ${listing.bin_price}` : "";
  }

  const readableTime = (date) => {
    let d1 = new Date(date), d2 = new Date();

    let diff = d2 > d1 ? new Date(d2-d1) : new Date(d1 - d2);
    let hour = diff.getUTCHours(), min = diff.getUTCMinutes(), sec = diff.getUTCSeconds(), day = diff.getUTCDate() - 1;
     
    return `${day}d ${hour}h ${min}m ${sec}s`;
  }

  // TODO images = listing.images // need BE changes to display multiple in the card


  if(isListingCompleted()){

    return(
      <ListingNew
        id={listing.product_id}
        title={listing.name}
        bid_price={getBidPrice()}
        bin_price={getBinPrice()}
        seller_rating = {sellerRating}
        images={listing.images}
        time={"Listing Completed"}
        quality={listing.quality}
        lot_weight = {listing.lot_weight}
        lot_units = {listing.weight_unit}
        seeds_plants = {listing.seeds_plants}
        finished_products = {listing.finished_products}
        is_auc_running={listing.is_auc_running}
      />
    )

  }else if(isProcessingWinner()){

    return (
      <ListingNew
        id={listing.product_id}
        title={listing.name}
        bid_price={getBidPrice()}
        bin_price={getBinPrice()}
        seller_rating = {sellerRating}
        images={listing.images}
        time={"Processing Listing"}
        quality={listing.quality}
        lot_weight = {listing.lot_weight}
        lot_units = {listing.weight_unit}
        seeds_plants = {listing.seeds_plants}
        finished_products = {listing.finished_products}
        is_auc_running={listing.is_auc_running}
      />
    );

  }else if(isListingStarted()){
   
    return (
      <ListingNew
        id={listing.product_id}
        title={listing.name}
        bid_price={getBidPrice()}
        bin_price={getBinPrice()}
        seller_rating = {sellerRating}
        images={listing.images}
        time={<p className = "my-auto"> Ends in {<br/>} {<FontAwesomeIcon icon={faHourglass} size="sm" />} {readableTime(listing.end_datetime)} </p>}
        quality={listing.quality}
        lot_weight = {listing.lot_weight}
        lot_units = {listing.weight_unit}
        seeds_plants = {listing.seeds_plants}
        finished_products = {listing.finished_products}
         is_auc_running={listing.is_auc_running}
      />
    );

  }else{

    return (
      <ListingNew
        id={listing.product_id}
        title={listing.name}
        bid_price={getBidPrice()}
        bin_price={getBinPrice()}
        seller_rating = {sellerRating}
        images={listing.images}
        time={<p className = "my-auto"> Starts in {<br/>} {<FontAwesomeIcon icon={faHourglass} size="sm" />} {readableTime(listing.start_datetime)} </p>}
        quality={listing.quality}
        lot_weight = {listing.lot_weight}
        lot_units = {listing.weight_unit}
        seeds_plants = {listing.seeds_plants}
        finished_products = {listing.finished_products}
        is_auc_running={listing.is_auc_running}
      />
    );

  }
}

export default ListingCard
