import axios from 'axios';
import react, { useEffect, useState } from 'react';
import { getCsrfCookieValue } from '../utils';
import { showToastUtil, TOAST_TYPE } from '../components/Banner/BannerToastUtil';
import RichTextEditor from '../components/RichTextEditor/RichTextEditor';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';

interface IProps { }

type PlaceholderObj = { [k: string]: string }

interface TemplateConfigObject {
    name: string
    subject: string
    content: string
    placeholders?: string
    placeholders_obj?: PlaceholderObj
}

enum TemplateTypes {
    Account_Activation = 'Account_Activation',
    Auction_Winner = 'Auction_Winner',
    Auction_Expired = 'Auction_Expired',
    Auction_Outbid = 'Auction_Outbid',
    User_Registration = 'User_Registration',
    Terms_And_Conditions = 'Terms_And_Conditions',
    About_Us = 'About_Us'
}

const getMenuItemsFromObjectKeyValues = (obj: PlaceholderObj, displayType?: number) => {
    const items: MenuProps['items'] = Object.entries(obj).map(([key, val]) => {
        return {
            key: key,
            label: displayType === 1 ? `${key}: ${val}` : val,
        }
    });
    return items;
}

const MailTemplatesView = (props: IProps) => {

    const [templateType, setTemplateType] = useState<string>(TemplateTypes.Account_Activation);
    const [emailSubject, setEmailSubject] = useState<string>('');
    const [editorHtmlContent, setEditorHtmlContent] = useState<string>('');
    const [placeholders, setPlaceholders] = useState<PlaceholderObj>({});
    const [selectedPlaceholderKey, setSelectedPlaceholderKey] = useState<string>('');
    let _updatedhtmlContent = '';

    const getEmailTemplate = async (name: string) => {
        axios.get(`${process.env.REACT_APP_URL}mail-template`, {
            params: { name: templateType },
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            withCredentials: true,
        }).then((resp) => {
            let data = resp.data as TemplateConfigObject;
            let { subject, content, placeholders = '', placeholders_obj = {} } = data;
            const placeholdersArr: PlaceholderObj[] = placeholders ? Array.from(JSON.parse(placeholders)) : [];
            placeholdersArr.map((record) => {
                placeholders_obj = {
                    ...placeholders_obj,
                    ...record
                }
            });
            setEmailSubject(subject);
            setEditorHtmlContent(content);
            onEditorUpdate(content);
            setPlaceholders(placeholders_obj);
            setSelectedPlaceholderKey(Object.keys(placeholders_obj)[0] ?? '');
            _updatedhtmlContent = content;
        }).catch((e) => {
            console.error(e);
            setEmailSubject('');
            setEditorHtmlContent('');
            onEditorUpdate('');
            setPlaceholders({});
            showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to retrieve Email Template.' });
        })
    }

    const updateEmailTemplate = async () => {
        if (_updatedhtmlContent === '') {
            showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please check inputs. Content cannot be empty.' });
            return;
        }
        const payload: TemplateConfigObject = {
            'name': templateType,
            'subject': emailSubject,
            'content': _updatedhtmlContent,
        }
        axios.post(`${process.env.REACT_APP_URL}mail-template`, payload, {
            params: { name: templateType },
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            withCredentials: true,
        }).then(() => {
            showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Email Template updated successfully!' });
        }).catch((e) => {
            console.error(e);
            showToastUtil({ status: TOAST_TYPE.ERROR });
        })
    }

    const onEditorUpdate = (htmlcontent: string) => {
        _updatedhtmlContent = htmlcontent;
        // console.log(_updatedhtmlContent);
    }

    const onPlaceHolderSelect = (key: string) => {
        setSelectedPlaceholderKey(key);
        const ph = `{{${key}}}`;
        window.navigator.clipboard.writeText(ph);
        showToastUtil({ status: TOAST_TYPE.INFO, message: `Placeholder ${ph} copied to clipboard!` });
    }

    useEffect(() => {
        getEmailTemplate(templateType);
    }, [templateType]);


    return (
        <div className='mail-templates-view d-flex flex-column' style={{ padding: '1.5rem' }}>

            <div className='d-flex flex-row align-items-center p-3'>
                <label className='h4 m-0'>Select Template:</label>
                <Dropdown
                    menu={{
                        items: getMenuItemsFromObjectKeyValues(TemplateTypes),
                        onSelect: (p) => setTemplateType(p.key),
                        selectable: true,
                    }}
                    trigger={['click']}
                >
                    <div className='d-flex form-select' style={{ width: 'auto', marginLeft: '1rem' }}>{templateType}</div>
                </Dropdown>
            </div>

            { templateType !== TemplateTypes.Terms_And_Conditions &&
                <>
                    <div className='d-flex flex-row align-items-center p-3'>
                        <label className='h4 m-0'>Email Subject:</label>
                        <input
                            type="text"
                            className="d-flex flex-grow-1 form-control"
                            style={{ width: 'auto', marginLeft: '1rem' }}
                            value={emailSubject}
                            onChange={(e) => setEmailSubject(e.target.value.trim())}
                            name="firstName"
                        />
                    </div>

                    <div className='d-flex flex-row align-items-center p-3'>
                        <label className='h4 m-0'>Select Placeholder to copy to clipboard:</label>
                        <Dropdown
                            menu={{
                                items: getMenuItemsFromObjectKeyValues(placeholders, 1),
                                onClick: (p) => onPlaceHolderSelect(p.key),
                                selectable: true,
                            }}
                            trigger={['click']}
                        >
                            <div className='d-flex flex-grow-1 form-select'
                                style={{ width: 'auto', marginLeft: '1rem' }}
                            >{`${selectedPlaceholderKey}: ${placeholders[selectedPlaceholderKey] ?? ''}`}</div>
                        </Dropdown>
                    </div>
                </>
            }

            <RichTextEditor
                htmlContentString={editorHtmlContent}
                onUpdateCallback={onEditorUpdate}
            />

            <div className='d-flex flex-row justify-content-center align-items-center p-3'>
                <button
                    type="button"
                    onClick={updateEmailTemplate}
                    className="btn btn-primary"
                >{'Update Template'}</button>
            </div>

        </div>
    );
}

export default MailTemplatesView;
