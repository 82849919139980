export interface GlobalConfiguration {
    [k: string]: any,
    company: {
        [k: string]: any,
        logo?: string,
        name?: string,
        address_line_1?: string,
        address_line_2?: string,
        city?: string,
        state?: string,
        zip_code?: string,
        phone?: string,
        email?: string,
        currency?: string,
    }
    invoice: {
        [k: string]: any,
        disclaimer?: string,
        terms_and_conditions?: string[],
        auto_email?: boolean,
    }
}

export const downloadCSVfile = (response: any, fileName: string) => {
    try {
        const blob = new Blob([response], { type: "data:text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.download = fileName + '.csv';
        downloadLink.href = blobURL;
        downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(":");
        downloadLink.click();
        return true
    } catch (error) {
        return false
    }
};

export const downloadPdfFile = (response: Blob, fileName: string) => {
    try {
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.download = fileName + '.pdf';
        a.href = url;
        a.target = '_self';
        a.click();
        setTimeout(function () {
            a.remove();
            URL.revokeObjectURL(url);
        }, 100);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkIfLoggenInUserIsAdmin = () => {
    const isAdmin = window.localStorage.getItem("business_type") === "admin";
    return isAdmin;
}

export interface StorageState {
    loggedIn: boolean;
    isDispensary: boolean;
    isAdmin: boolean;
}

export const checkVaildAuth = (): boolean => {
    const authtime: string = window.localStorage.getItem('validauth') ?? '';
    let valid: boolean = false;
    if (authtime !== '') {
        // Check if the current time is 3 hours past stored session time
        const oldtime = new Date(authtime).getTime();
        const currtime = new Date().getTime();
        const diff = currtime - oldtime;
        if (diff < 10800000) { // 3 hours in milliseconds
            valid = true;
        }
    }
    return valid;
}

export const checkStorageState = (): StorageState => {
    const business_type = window.localStorage.getItem("business_type");
    // const validauth = window.localStorage.getItem("validauth");
    // const user_name = window.localStorage.getItem("user_name");
    const loggedIn = business_type !== null && business_type !== '';
    const isDispensary = loggedIn && business_type === 'dispensary';
    const isAdmin = loggedIn && business_type === 'admin';
    return { loggedIn, isDispensary, isAdmin };
}

export const setStorageState = (business_type: string, user_name?: string) => {
    window.localStorage.setItem("validauth", (new Date()).toISOString());
    window.localStorage.setItem("business_type", business_type);
    if (user_name && user_name !== '') {
        window.localStorage.setItem("user_name", user_name);
    }
    return;
}

export const clearStorageState = () => {
    window.localStorage.clear();
    return;
}

// get cookie value
export function getCookie(name: string) {
    let cookieValue: string = '';
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getCsrfCookieValue() {
    let cookieValue: string = getCookie('csrftoken');
    return cookieValue;
}


export const passwordValidation = (arg: string) => {
    var status = false;
    var msg = '';
    const arg_len = arg.length;
    const len_btw_8_64 = 8<=arg_len && arg_len<=64;
    const atleastOneDigit = new RegExp(/(?=.*\d)/, 'g').test(arg);
    const atleastOneSmallChar = new RegExp(/(?=.*[a-z])/, 'g').test(arg);
    const atleastOneCapitalChar = new RegExp(/(?=.*[A-Z])/, 'g').test(arg);
    const atleastOneSpecialChar = new RegExp(/(?=.*\W)/, 'g').test(arg);
    const notContainWhiteSpaces = new RegExp(/^(?![\s\S]*\s)/, 'g').test(arg);
    if(len_btw_8_64 && atleastOneDigit && atleastOneSmallChar && atleastOneCapitalChar && atleastOneSpecialChar && notContainWhiteSpaces) status = true
    else{
        if(!len_btw_8_64){
            msg = "Password Length must be between 8 and 64";
        }
        else if(!atleastOneSmallChar){
            msg = 'Enter atleast one small alphabet';
        }
        else if(!atleastOneCapitalChar){
            msg = 'Enter atleast one capital alphabet';
        }
        else if(!atleastOneDigit){
            msg = "Enter atleast one digit";
        }
        else if(!atleastOneSpecialChar){
            msg = 'Enter atleast one special character';
        }
        else if(!notContainWhiteSpaces){
            msg = 'White Spaces are not allowed';
        }
    }
    return {
        "status": status,
        "msg": msg
    };
}