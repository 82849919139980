import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { Row, Col, Card, Image, Button } from 'antd';
import AntTable from "./tables/AntTable.jsx";
import { Popconfirm } from 'antd';
import "./IndividualNewUser.css"
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";

const IndividualUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigateViewAllUsers = () => {
        navigate("/admin/new-users", {});
    };

    const handleApprove = () => {
        var username = location.state["username"];
        let input_data = {
            username: username,
        };
        axios
            .post(`${process.env.REACT_APP_URL}verify`, input_data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'User approved successfully.' });
                navigateViewAllUsers();
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to approve user.' });
                console.error(err);
            });
    } 

    function handleReject() {
        axios
            .post(
                `${process.env.REACT_APP_URL}reject-user`,
                { username: location.state["username"] },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'User rejected successfully.' });
                navigateViewAllUsers();
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to reject user.' });
                console.error(err);
            });
    }

    const [listingsTable, setUsersTable] = useState([]);

    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_URL}view-users`,
                { username: location.state["username"] },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )

            .then((resp) => {
                setUsersTable(resp.data);
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Failed to get user details' });
                console.log(err);
            });

    }, []);

    
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const nPages = Math.ceil((Object.keys(listingsTable).length) / recordsPerPage)
    const columnInfo = [{name: "Column"}, {name: "Value"}]

    let tableOne = [];
    let tableTwo = [];

    
    for(let key in listingsTable){
        if(["id", "username", "first_name", "last_name", "email", "account_status", "is_deleted",
            "is_unenrolled", "phone_number", "license_number"].includes(key)){
                tableOne.push({ key: tableOne.length, column: key, value: listingsTable[key].toString() })
        }else if(key == "images"){
            tableTwo.push({key: tableTwo.length, column: key, value: listingsTable[key] ? <Image height={200} src={listingsTable[key]}/> : ""})
        }else if(key == "files"){
            tableTwo.push({key: tableTwo.length, column: key, 
                      value: (listingsTable[key] || []).map(item => 
                                <div display = "flex" style = {{marginBottom: "15px"}}>
                                    <Image height={200} src={item.file}/>
                                </div>)
            })
        }else{
            tableTwo.push({
                key: tableTwo.length,
                column: key,
                value: listingsTable[key].toString()
            })
        }
    }

    return (
        <div style = {{padding: "0px"}}>
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> New User Details</h5>
                            <Button onClick = {navigateViewAllUsers} size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Go to New Users</Button>
                            <Popconfirm title="Sure to Approve?" onConfirm={() => handleApprove()}>
                                <Button size = "middle" style = {{background: 'rgb(26 190 0)', color: "white", marginLeft: "15px"}}>Approve</Button>
                            </Popconfirm>
                            <Popconfirm title="Sure to Reject?" onConfirm={() => handleReject()}>
                                <Button size = "middle" style = {{background: '#FF7575', color: "white", marginLeft: "15px"}}>Reject</Button>
                            </Popconfirm>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px", marginBottom: "20px"}} gutter={[32, 16]}>
                
                <Col xs = {23} md = {11} style = {{maxHeight: "200px !important"}}>
                    <AntTable data = {tableOne} columnInfo = {columnInfo} actions = {[]} pagination = {false} disableSearch = {true}/> 
                </Col>
                <Col xs = {23} md = {11}>
                    <AntTable data = {tableTwo} columnInfo = {columnInfo} actions = {[]} pagination = {false} disableSearch = {true}/>
                </Col>
                
            </Row>
        </div>
    );
};

export default IndividualUser;
