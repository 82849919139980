import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "../Common/Carousel";
import { Modal, Rate } from "antd";

import "./ListingNew.css";
import CustomPopover from "../../Admin/modal/CustomPopover";
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";
import { TOAST_TYPE, showToastUtil } from "../../components/Banner/BannerToastUtil";

function ListingNew(props) {
  const { id, title, bid_price, bin_price, images, time, quality, lot_weight, lot_units, seeds_plants, finished_products, seller_rating,is_auc_running } =
    props;
  const [badgeColor, setBadgeColor] = useState("#0dcaf0");

  const navigate = useNavigate();
  const location = useLocation();
  // console.warn(location)
  useEffect(() => {
    switch (quality) {
      case "A+":
        setBadgeColor("#84d25d");
        break;
      case "A":
        setBadgeColor("#99d27c");
        break;
      case "A-":
        setBadgeColor("#Bbf1a1");
        break;
      case "B+":
        setBadgeColor("#7fbbd0");
        break;
      case "B":
        setBadgeColor("#B1e2f3");
        break;
      case "B-":
        setBadgeColor("#C4e8f5");
        break;
      case "C+":
        setBadgeColor("#Dae081");
        break;
      case "C":
        setBadgeColor("#F4f9a6");
        break;
      case "C-":
        setBadgeColor("#Edf1b6");
        break;
      default:
        setBadgeColor("#Bfbfb9");
        break;
    }
  }, [props.quality]);

  const printDate = (date, addExtraDays = 0) => {
    // Confirm date is actually a Date object
    let d = new Date(date);
    d.setDate(d.getDate() + addExtraDays);
    let local = d.toLocaleDateString("en-us");
    let [month, day, year] = local.split("/");

    // Fix the date for front-end purposes.
    if (day.length == 1) day = "0" + day;
    if (month.length == 1) month = "0" + month;

    let output = [year, month, day].join("-");
    return output;
  };

  const printTime = (date, addExtraHours = 0) => {
    let d = new Date(date);
    d.setHours(d.getHours() + addExtraHours);

    // let output = d.toISOString().substring(11, 16);
    let output = d.toTimeString().substring(0, 5);
    return output;
  };
  const [aucEnable, setAucEnable] = useState(false);
  const [binEnable, setBinEnable] = useState(false);
  const [aucPrice, setAucPrice] = useState("");
  const [binPrice, setBinPrice] = useState("");
  const [startASAP, setStartASAP] = useState(true);
  const [startDate, setStartDate] = useState(printDate(new Date()));
  const [endDate, setEndDate] = useState(printDate(new Date(), 5));
  const [startTime, setStartTime] = useState(printTime(new Date(), 1));
  const [endTime, setEndTime] = useState(printTime(new Date()));
  const [modal, setmodal] = useState(false);

  const handleClick = () => {
    navigate(`/view-listing/${id}`);
  };
  const handleRelist = async () => {
    console.log("handleRelist called in parent component", id);
    let formData = new FormData(); // Add everything to the FormData attribute
    formData.append("auction_price", aucPrice);
    formData.append("bin_price", binPrice);
    if (!startASAP) {
      formData.append("start_datetime", new Date(startDate + " " + startTime).toISOString());
    } else {
      formData.append("start_datetime", "");
    }
    formData.append("end_datetime", new Date(endDate + " " + endTime).toISOString());
    formData.append("product_id", id);
    console.warn(Object.fromEntries(formData));
    await axios
      .post(`${process.env.REACT_APP_URL}relist-Auctioned-Item`, formData, {
        headers: {
          "X-CSRFToken": getCsrfCookieValue(),
        },
        withCredentials: true,
      })
      .then((res) => {
        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: "Your Item is relist successfully!" });
        setmodal(false);
        setTimeout(() => {
        navigate("/listings");
        }, 2000);
      })
      .catch((error) => {
        showToastUtil({ status: TOAST_TYPE.ERROR });
        setmodal(false);
      });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "400px", maxHeight: "700px" }}>
      <div className="card mb-3 mt-3 shadow-lg" style={{ borderRadius: "15px" }}>
        {/* Card Header */}
        <div className="card-header d-flex flex-row justify-content-between align-items-center">
          <h5 className="custom-flex-ellipsis" title={title}>
            {title}
          </h5>
          <p className="badge badge-pill my-auto" style={{ textAlign: "right", borderRadius: "10px", backgroundColor: badgeColor }}>
            {quality}
          </p>
        </div>

        <div className="card-body">
          <div className="card-image-container">
            <Carousel images={images} />
          </div>

          <div className="card-content">
            {/* Bid */}
            <div className="row">
              <p className="card-title pricing-card-title text-left col">Bid Price:</p>
              <p className="col custom-flex-ellipsis" title={bid_price || "N/A"}>
                {bid_price || "N/A"}
              </p>
            </div>

            <div className="row">
              <p className="card-title pricing-card-title text-left col">Buy Price:</p>
              <p className="col custom-flex-ellipsis" title={bin_price || "N/A"}>
                {bin_price || "N/A"}
              </p>
            </div>

            <div className="row">
              <p className="card-title pricing-card-title text-left col">Seeds/plants:</p>
              <p className="col custom-flex-ellipsis" title={seeds_plants}>
                {seeds_plants}
              </p>
            </div>

            <div className="row">
              <p className="card-title pricing-card-title text-left col">Lot Weight:</p>
              <p className="col custom-flex-ellipsis" title={`${lot_weight} ${lot_units}`}>{`${lot_weight} ${lot_units}`}</p>
            </div>

            <div className="row">
              <p className="card-title pricing-card-title text-left col">Finished Product:</p>
              <p className="col custom-flex-ellipsis" title={finished_products}>
                {finished_products}
              </p>
            </div>

            <div className="row">
              <p className="card-title pricing-card-title text-left col">Seller Rating:</p>
              {seller_rating == "N/A" ? (
                <p className="col">N/A</p>
              ) : (
                <Rate className="col" disabled allowHalf defaultValue={seller_rating} />
              )}
            </div>

            <div className="row mt-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="vstack"> {time} </div>
               {location.pathname == "/mylistings" && !is_auc_running && (
                  <button style={{ zIndex: 9999 }} type="button" className="btn btn-md btn-outline-primary" onClick={() => setmodal(true)}>
                    Relist
                  </button>
                )}
                <button type="button" className="btn btn-md btn-outline-primary" onClick={handleClick}>
                  View details
                </button>
              </div>
            </div>
            <Modal
              title="Relist item"
              open={modal}
              onOk={handleRelist}
              onCancel={() => setmodal(false)}
              okText="Submit"
              cancelText="Cancel">
              <CustomPopover
                aucEnable={aucEnable}
                setAucEnable={setAucEnable}
                binEnable={binEnable}
                setBinEnable={setBinEnable}
                aucPrice={aucPrice}
                setAucPrice={setAucPrice}
                binPrice={binPrice}
                setBinPrice={setBinPrice}
                startASAP={startASAP}
                setStartASAP={setStartASAP}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                startTime={startTime}
                setEndDate={setEndDate}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingNew;
