import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { readableTime } from "../Listings/ListingsComponent";
import Carousel from "../Common/Carousel";

import { getCsrfCookieValue } from "../../utils";
import { checkIfLoggenInUserIsAdmin } from "../../utils"
import { showToastUtil, TOAST_TYPE } from '../../components/Banner/BannerToastUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass, faUser } from '@fortawesome/free-solid-svg-icons'

import { downloadPdfFile } from "../../utils";

import "react-multi-carousel/lib/styles.css";
import "./ViewListing.css";

function ViewListingComponent() {
    // Get the params ONCE (constantly refreshing the page?)
    // const [routeParams, setRouteParams] = useState({});
    // const [p, setP] = useState(true);
    // if (p) {
    //     setP(false);
    //     var rP = useParams();
    //     setRouteParams(rP);
    //     console.log(routeParams);
    // }

    const [admin, setAdmin] = useState(false);
    const [isFlashing, setIsFlashing] = useState(false);
    const [flashColor, setFlashColor] = useState("");

    const triggerFlashAnimation = (color) => {
        setFlashColor(color);
        setIsFlashing(true);
        setTimeout(() => {
            setIsFlashing(false);
        }, 1000);
    };


    // I actually don't know how this is working?
    let routeParams = useParams();
    // console.log(routeParams);

    const [bid, setBid] = useState("");

    const [alerts, setAlerts] = useState([]);
    const [alertsToggle, setAlertsToggle] = useState("");

    //Load sample entry from DB
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [thc, setThc] = useState("");
    const [cbd, setCbd] = useState("");
    const [quality, setQuality] = useState("");
    const [seeds, setSeeds] = useState("");
    const [extracts, setExtracts] = useState("");
    const [Finished, setFinished] = useState("");
    const [Strain, setStrain] = useState("");
    const [biomass, setBiomass] = useState("");
    const [env, setEnv] = useState("");
    const [trim, setTrim] = useState("");
    const [weight, setWeight] = useState("");
    const [auc, setAuc] = useState("");
    const [bin, setBin] = useState("");
    const [transportation, setTransportation] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [auctionEnabled, setAuctionEnabled] = useState(false);
    const [binEnabled, setBinEnabled] = useState(false);
    const [responseDATA, setResponseDATA] = useState({});
    const [username, setUserName] = useState(window.localStorage.getItem("user_name"));
    const [product_id, setProductId] = useState(routeParams.product_id);
    const [product_in_list, setProductInList] = useState(false);
    const [productIsHotItem, setProductHotIemFlag] = useState(false);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [expired, setExpired] = useState(false);
    const navigate = useNavigate();
    const [isOriginialuser, setOriginalUser] = useState(false);

    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [product_image, setImage] = useState();

    // Load in listing data from the DB
    const getListingById = () => {
        axios
            .get(`${process.env.REACT_APP_URL}listings?product_id=${routeParams.product_id}`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                // Once we get the data for one listing, populate the entire page.
                // -----------(Set the page variables) ------------

                var temp = res.data;
                console.log("response data", temp);
                setResponseDATA(temp);
                setName(temp.name);
                setCbd(temp.cbd);
                setQuality(temp.quality);
                setAuctionEnabled(temp.auction_enable);
                setBinEnabled(temp.bin_enable);
                setEndTime(temp.end_datetime);
                setStartTime(temp.start_datetime);
                setIsAucRunning(temp.is_auc_running);
                setProductHotIemFlag(temp.is_hot_listing);
                setOriginalUser(temp.originalUser);

                getCurrentBid();
                getPastBids();
                checkIfListingIsInFavorites();

                setImages([...temp.images]);
                setFiles([...temp.files]);

                // ------------------------
            })
            .catch((error) => {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: `Failed to get listing details for product ID: ${routeParams.product_id}` });
                console.log(error);
            });
    };

    const checkIfListingIsInFavorites = async () => {
        return await axios
            .get(`${process.env.REACT_APP_URL}checkFromFavorites?&product_id=${product_id}`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })

            // .get('${process.env.REACT_APP_URL}checkFromFavorites', {params: {username: username, product_id: product_id}})

            .then((res) => {
                if (res.data["isInList"]) {
                    setProductInList(true);
                }
            })
            .catch((err) => console.log(err));
    };

    const addToFavorites = () => {
        const data = {
            username: username,
            product_id: product_id,
        };
        axios
            .post(`${process.env.REACT_APP_URL}addToFavorites`, data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data["isInList"]) {
                    setProductInList(true);
                }
            })
            .catch((err) => console.log(err));
    };

    const removeFromFavorites = async () => {
        const data = {
            // username: username,
            product_id: product_id,
        };

        await axios
            .post(`${process.env.REACT_APP_URL}removeFromFavorites`, data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                if (!res.data["isInList"]) {
                    setProductInList(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const toggleHotListing = async ({ setAsHot = false }) => {
        // admin only
        if (!admin) return;
        const url = `${process.env.REACT_APP_URL}set-hot-listing`;
        const data = {
            // username: username,
            product_id: product_id,
            is_hot_listing: `${setAsHot}`
        };
        await axios
            .post(url, data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data == 'Success.') {
                    setProductHotIemFlag(setAsHot);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getListingById();
        setAdmin(checkIfLoggenInUserIsAdmin());
    }, []);

    // ---------- Fixing Timer ----------

    const [endTime, setEndTime] = useState(""); // Hold the value from the API call & WS Updates
    const [startTime, setStartTime] = useState("");
    const [viewTime, setViewTime] = useState("Loading...");
    const [isAucRunning, setIsAucRunning] = useState(false);
    const [listingHeadline, setListingHeadline] = useState("");
    const [hasListingStarted, setHasListingStarted] = useState(false);
    const [showBidHistory, setShowBidHistory] = useState(false); // Use this to show bid history once the listing starts
    const [noBidsYet, setNoBidsYet] = useState(true);

    // Start the timer         // Update the count down every 1 second
    useEffect(() => {
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            var theTime;

            // Check if the currentTime <= startTime. If so, list Start Time @ ____
            // If it's NOT, check that currentTime <= endTime for countdown (as normal).
            if (now <= new Date(startTime)) {
                setListingHeadline("Starts In:");
                theTime = startTime;
                setHasListingStarted(false);
            } else if (now > new Date(endTime) || !isAucRunning) {
                // If listing exceeds endTime OR is purchased, remove options to bid
                setListingHeadline("");
                theTime = endTime;
                setHasListingStarted(false);
                setShowBidHistory(true);
            } else {
                setListingHeadline("Ends In:");
                theTime = endTime;
                setHasListingStarted(true);
                setShowBidHistory(true);
            }

            // Find the distance between now and the count down date
            var distance = new Date(theTime).getTime() - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (!isAucRunning) {
                setViewTime("LISTING PURCHASED");
            } else if (distance < 0) {
                clearInterval(x);
                setViewTime("LISTING EXPIRED");
            } else if (theTime === null) {
                setViewTime("Loading");
            } else {
                setViewTime(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
            }
        }, 1000);
        return () => clearInterval(x);
    });

    const handleUpdate = () => {
        navigate(`/edit/${responseDATA.product_id}`);
    };


    const downloadInvoice = () => {
        const url = `${process.env.REACT_APP_URL}retrieve_invoice/${product_id}`;
        axios.get(url, {
            headers: { "X-CSRFToken": getCsrfCookieValue() },
            responseType: 'blob', // Add this property
            withCredentials: true,
        }).then((response) => {
            const fileName = `Invoice-${product_id}`;
            const successful = downloadPdfFile(response.data, fileName);
            if (!successful) {
                throw Error('');
            } else {
                showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Invoice downloaded successfully!' });
            }
        }).catch(() => {
            showToastUtil({ status: TOAST_TYPE.ERROR });
        });
    }

    //** Working on WebSockets and Bids */

    /* Sources
     * Socket.io (how to close sockets on page change) -> https://socket.io/how-to/use-with-react-hooks
     * Medium article -> https://medium.com/@abhi.sharm.414/live-whiteboard-with-django-react-and-websockets-7cae864ed6c5
     */

    const socketRef = useRef(); // Guide uses "const socketRef" and then assigns with "socketRef.ws"...?

    useEffect(() => {
        const listing = routeParams.product_id;
        socketRef.ws = new WebSocket(`${process.env.REACT_APP_WS_URL}ws/listing/` + listing + "/");

        socketRef.ws.onmessage = (e) => {
            var data = JSON.parse(e.data);
            console.log("Data Received:", data);

            // Reset alert messages
            setAlertsToggle("");
            setAlerts([]);

            // Handle errors
            if ("errors" in data) {
                let temp = [];

                for (let i in data.errors) {
                    console.log(i);
                    temp.push(i + ": " + data.errors[i]);
                }

                console.log(temp);

                //setAlertsToggle("Form Submission Errors");
                setAlerts(temp);
            }

            /* Update the bid w/ new version */

            /* Anything with valid bids must be within the if else block below.
                Otherwise, it will be run on EVERY bid regardless of it's a valid one.*/

            // On BIN
            if (data.message.bid_value === "expired") {
                if (data.message.notif === true) {
                    // Only notify for bidder.
                    showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Buyout Successful !!' });
                } else {
                    setIsAucRunning(false);
                    setNoBidsYet(false);
                    setPastBids((pastBids) => [
                        { bin_purchase: true, bid_time: data.message.bid_time },
                        ...pastBids,
                    ]);
                }
            } else {
                if (data.message.notif === true) {
                    // Only notify for bidder.
                    showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Bid Successful !!' });

                } else {
                    setAuc(data.message.bid_value);
                    setEndTime(data.message.end_datetime);

                    console.log("PRINTING BID DATA");
                    console.log(data.message);
                    console.log(pastBids);

                    setNoBidsYet(false);
                    setPastBids((pastBids) => [
                        {
                            bid_value: data.message.bid_value,
                            bid_time: data.message.bid_time,
                            bin_purchase: false,
                        },
                        ...pastBids,
                    ]);
                    triggerFlashAnimation("rgba(0, 255, 0, 0.5)"); // Light green
                }
            }
        };

        // This return triggers when the page changes... I.e., close the socket!
        return () => {
            console.log("WS closed");
            socketRef.ws.close();
        };
    }, []);

    const wsBid = (socketRef, data) => {
        //console.log(socketRef.ws);
        socketRef.ws.send(
            JSON.stringify({
                message: data,
            })
        );
    };

    const addBid = (socketRef) => {
        let data = {
            product_id: routeParams.product_id,
            bid_value: bid,
            bin_purchase: false,
        };

        wsBid(socketRef, data);
    };

    // Enter press
    const addBidEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            let data = {
                product_id: routeParams.product_id,
                bid_value: bid,
                bin_purchase: false,
            };

            wsBid(socketRef, data);
        }
    };

    const buyOut = (socketRef) => {
        let data = {
            product_id: routeParams.product_id,
            bid_value: 0,
            bin_purchase: true,
        };

        console.log("BIN CLICKED");
        wsBid(socketRef, data);
    };

    // todo - remove this method and add in error popups to WS
    /*const sendBid = (data) => {
        axios
            .post("${process.env.REACT_APP_URL}bid", data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log(res);

                setAlertsToggle("");
                setAlerts([]);

                if ("SUCCESS" in res.data) {
                    setAuc(res.data["curr_auction_price"]);
                } else {
                    console.log("\n\n\nERROR");
                    let temp = [];

                    for (let i in res.data) {
                        temp.push(i + ": " + res.data[i]);
                    }

                    setAlertsToggle("Form Submission Errors");
                    setAlerts(temp);
                }
                toast.success('Bid Successful !!')
                getCurrentBid();
            })
            .catch((err) => console.log(err));
    };*/

    // Update Bid (and time !) on first page load (non-WS)
    const getCurrentBid = async () => {
        await axios
            .get(`${process.env.REACT_APP_URL}bid?product_id=${routeParams.product_id}`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => {
                // console.log(data);
                // console.log("hi there, this is working");

                setAuc(data.data["curr_auction_price"]);
            })
            .catch((err) => console.log(err));
    };

    const [pastBids, setPastBids] = useState([]);

    const getPastBids = async () => {
        await axios
            .get(`${process.env.REACT_APP_URL}getbids?product_id=${routeParams.product_id}`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                // console.log("PAST BIDS");
                // console.log(res);

                let temp = [];

                for (let i in res.data) {
                    // console.log(res.data[i]);
                    temp.push(res.data[i]);
                }

                // Reverse order of history
                temp = temp.reverse();

                // console.log(res.data);

                if (temp.length == 0) {
                    setNoBidsYet(true);
                } else {
                    setNoBidsYet(false);
                }
                setPastBids([...temp]); // This is important to DIRECTLY set the pastBids, not give it a reference
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /* ------------- END WEBSOCKET WORK ------------- */

    const handleSeller = () => {
        navigate(`/seller-profile/${responseDATA.seller_id}`);
    };


    const ref1 = useRef(null);

    const hasUserBidded = () => {
        let bidder_ids = pastBids.map((pastBid) => pastBid.bidder_id);
        return bidder_ids.includes(username);
    }

    const getUserLatestBid = () => {
        let userBidAmounts = pastBids.filter((pastBid) => pastBid.bidder_id == username).map((bid) => parseInt(bid.bid_value));
        userBidAmounts.sort();
        return userBidAmounts[userBidAmounts.length - 1];
    }


    return (
        <div className="container-fluid p-4">
            <div className="row mb-1 align-items-start gx-4 gy-3">
                <div className="col-auto">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <h4>{responseDATA ? responseDATA.name : null}</h4>
                        </div>

                        <div className="col-auto">
                            {product_in_list ? (
                                <i className="fa fa-heart me3" title='Remove from Favorites List' style={{ fontSize: "1.5rem", cursor: 'pointer' }} color="red" onClick={() => removeFromFavorites()}></i>
                            ) : (
                                <i className="fa fa-heart-o me3" title='Add to Favorites List' style={{ fontSize: "1.5rem", cursor: 'pointer' }} color="red" aria-hidden="true" onClick={() => addToFavorites()} />
                            )}
                        </div>
                        <div className="col-auto">
                            {admin && (
                                <div
                                    className={`${productIsHotItem ? 'hot-list-icon' : 'hot-list-outline-icon'}`}
                                    style={{ fontSize: "1.5rem", cursor: 'pointer' }}
                                    title={`${productIsHotItem ? 'Remove from' : 'Add to'} Hot Listings`}
                                    color={productIsHotItem ? 'red' : 'blue'}
                                    onClick={() => toggleHotListing({ setAsHot: !productIsHotItem })}
                                ></div>
                            )}
                        </div>
                    </div>

                    <div className="row align-items-center mt-2 mb-3">
                        <div className="col-auto">
                            <p className='my-auto fw-bold'> Listed by  </p>
                        </div>

                        <div className="col-auto p-0">
                            <button className="btn btn-outline-primary btn-sm" style={{ borderRadius: "10px", padding: "0px 5px 0px 5px" }} onClick={handleSeller}>

                                {responseDATA ?
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faUser} />
                                        <p className="my-auto" style={{ marginLeft: "5px" }} >{responseDATA.seller_id}</p>
                                    </div>
                                    : null}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-auto">
                    {["LISTING PURCHASED", "LISTING EXPIRED"].includes(viewTime) ? (
                        <p> {viewTime} </p>
                    ) : (
                        <div className="d-flex">
                            <p className="fw-bold" style={{ marginRight: "10px" }}>{listingHeadline}</p>
                            <p className="ml-4"> {<FontAwesomeIcon icon={faHourglass} size="sm" />} {viewTime}</p>
                        </div>
                    )}
                </div>

                <div className="col">
                    <p> <b>Current highest bid:</b>  ${auc}</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    {responseDATA ? responseDATA.description : null}
                </div>
            </div>

            <div className="row justify-content-between gx-5">
                <div className="col-auto p-3" >
                    <div className="card shadow-sm" style={{ borderRadius: "10px" }}>
                        <div className="card-body">
                            <div className="row mt-3 justify-content-center" >
                                <Carousel images={images} />
                            </div>

                            <div className="row justify-content-center mb-2">
                                <div className="col-auto">
                                    <h6 className="priceText"> {" "} Starting bid price: ${responseDATA ? responseDATA.auction_price : null} </h6>
                                    {/* {   auctionEnabled && !hasUserBidded ? (
                                            <h6 className="priceText"> {" "} Starting bid price: ${responseDATA ? responseDATA.auction_price : null} </h6>
                                        ) : auctionEnabled ?
                                        (
                                            <h6 className="priceText"> {" "} Your last bid price: ${getUserLatestBid()} </h6>
                                        ) :
                                    null} */}
                                </div>
                            </div>

                            <div className="row mr-0">
                                {/* !isOriginialuser && */}
                                {!isOriginialuser && auctionEnabled && hasListingStarted && (
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <input type="number" className="p-2 ml-5 mr-3 border w-100" placeholder="Enter bid value" style={{ borderRadius: "5px", height: "36px" }}
                                                value={bid} onChange={(e) => setBid(e.target.value)} onKeyDown={(e) => addBidEnter(e)}
                                            />
                                        </div>
                                        <div className="col-auto p-0">
                                            <button type="submit" className="btn btn-primary" onClick={() => addBid(socketRef)} style={{ height: "36px", borderRadius: "7px" }} >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Alerts (both front-end and back-end provided!) */}
                            <div className="row mb-2 mt-2">
                                <div className="col-1"></div>
                                <div className="col-8">
                                    <h3 className="" style={{ color: "red" }}>
                                        {alertsToggle}
                                    </h3>
                                    {alerts.map((a) => {
                                        return (
                                            <l style={{ color: "red" }}>
                                                {a.split(": ")[1]}
                                            </l>
                                        );
                                    })}
                                </div>
                                <div className="col-3"></div>
                            </div>

                            <div className="row">
                                {/* !isOriginialuser && */}
                                {!isOriginialuser && auctionEnabled && hasListingStarted && (
                                    <div className="row align-items-center">
                                        <div className="col p-0">
                                            {responseDATA.bin_price ?
                                                <button type="submit" className="btn btn-warning w-100" onClick={() => buyOut(socketRef)} style={{ borderRadius: "7px", marginLeft: "10px" }}>
                                                    {`Buyout at $ ${responseDATA.bin_price}`}
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-warning w-100" style={{ borderRadius: "7px", marginLeft: "10px" }}>
                                                    {'No Buyout available'}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )}

                                {
                                    (isOriginialuser && isAucRunning) && (
                                        <div>
                                            <button type="button" className="w-100 btn btn-md btn-primary" onClick={handleUpdate} style={{ borderRadius: "7px" }} >
                                                Edit listing
                                            </button>
                                        </div>
                                    )

                                }
                                {
                                    !isAucRunning && (
                                        //true && (
                                        <>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <button type="button" className="d-flex-inline btn btn-md btn-warning m-3" onClick={() => downloadInvoice()} style={{ borderRadius: "7px" }} >
                                                    Download Invoice
                                                </button>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>


                </div>

                <div className="col-md-4" >
                    <fieldset className="p-3" style={{ borderStyle: "solid", borderRadius: "5px", borderColor: "black", borderWidth: "2px" }}>
                        <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}> Product Details </legend>
                        <div className="p-1">
                            <div className="p-0" style={{ backgroundColor: "#E2E2E2" }} ref={ref1}>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="col-6 exclude-col"><p className="ms-3 m-1">THC Content</p></td>
                                            <td className="col-6 exclude-col" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? `${responseDATA.thc}%` : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">CBD Content</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? `${responseDATA.cbd}%` : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Quality Level</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.quality : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Seeds/Plants</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.seeds_plants : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Extracts</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.extracts : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Finished Products</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.finished_products : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Strain Type</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.strain : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Biomass</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.biomass : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Environment</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.environment : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Trim</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.trim : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1">Lot Weight</p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? `${responseDATA.lot_weight} ${responseDATA.weight_unit}` : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1"> Transportation Details </p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.transportation : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td className="col-6"><p className="ms-3 m-1"> Departure Zip Code </p></td>
                                            <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{responseDATA ? responseDATA.zip_code : null}</p></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <div className="col-md-4" >
                    <fieldset className="p-3 h-100" style={{ borderStyle: "solid", borderRadius: "5px", borderColor: "black", borderWidth: "2px" }}>
                        <legend className="float-none w-auto p-2" style={{ fontSize: "1rem", fontWeight: 450 }}> Bid History </legend>
                        <div className="p-1">
                            <div className="p-0 h-100" style={{ backgroundColor: "#E2E2E2", overflow: "scroll", maxHeight: ref1.current ? ref1.current.offsetHeight : "auto" }}>
                                <table className="table table-bordered my-auto">
                                    <tbody>
                                        {pastBids.map((b, index) => {
                                            const isFlashClass = isFlashing && index === 0 ? "flash-animation" : "";
                                            const flashStyle = isFlashing && index === 0 ? { "--flash-color": flashColor } : {};

                                            if (b.bin_purchase) {
                                                return (
                                                    <tr className={isFlashClass} style={flashStyle}>
                                                        <td className="col-4">
                                                            <p className="ms-3 m-1"> ${responseDATA ? responseDATA.bin_price : null}{" "}  buyout at</p>
                                                        </td>
                                                        <td className="col-8" style={{ backgroundColor: "white" }}>
                                                            <p className="ms-3 m-1">{readableTime(new Date(b.bid_time))}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return (
                                                    <tr className={isFlashClass} style={flashStyle}>
                                                        <td className="col-4">
                                                            <p className="ms-3 m-1"> ${b.bid_value}</p>
                                                        </td>
                                                        <td className="col-8" style={{ backgroundColor: "white" }}>
                                                            <p className="ms-3 m-1">{readableTime(new Date(b.bid_time))}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

        </div>
    )

}

export default ViewListingComponent;
