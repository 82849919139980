import React, { useEffect, useState } from "react";

import ListingCard from "./ListingCard";
import { Pagination, Row } from 'antd';
import "./Listings.css";
import "./ListingBrowserComponent.css"

interface IProps {
    listings: any[]
    defaultPageSize ?: number
}

const ListingsBrowserComponent = (props: IProps) => {
    // const { listings: all_listings_, defaultPageSize = 12 } = props;
    // let all_listings: any[] = [];
    // for (let i=1; i <= 500; i++) {
    //     all_listings.push({
    //         ...all_listings_[0],
    //         name: i,
    //         images: [],
    //         key: i
    //     })
    // }
    // remove all above commented code (only added for demo)

    const { listings: all_listings, defaultPageSize = 12 } = props;
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);

    const [listingsInRange, setListingsInRange] = useState<any[]>([])
    const [listingsCount, setListingsCount] = useState(all_listings.length);

    useEffect(() => {
        setListingsCount(all_listings.length);
        const inrange = all_listings.slice((page - 1) * pageSize, page * pageSize);
        setListingsInRange([...inrange]);
    }, [all_listings]);

    useEffect(() => {
        const inrange = all_listings.slice((page - 1) * pageSize, page * pageSize);
        setListingsInRange([...inrange]);
    }, [page, pageSize]);    
    return (
        <div className="container-fluid p-0">
            <div className="g-5 d-flex flex-wrap flex-row">
                {
                    listingsCount === 0 ? (
                        <div className="mt-5 mb-5" style={{ height: "200px" }}>
                            <h3 style={{ textAlign: "center" }}>No Products Found</h3>
                        </div>
                    ) : <>
                        {listingsInRange.map((l) => {
                            return (
                                // <div className="col-sm-12 col-md-6 col-lg-4 col-3-xxl">
                                <div className="d-inline-flex px-3"> 
                                    <ListingCard listing={l} />
                                </div>
                            );
                        })}
                    </>
                }
            </div>
            { listingsCount !== 0 &&
                <div className="d-flex align-items-center justify-content-center m-0">
                    <Pagination
                        total={listingsCount}
                        pageSize={pageSize}
                        pageSizeOptions={[5, 10, 20, 50, 100]}// not working - fail library
                        current={page}
                        onChange={(page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        }}
                        showTotal={(total, [lo, hi]) => <>
                            <div style={{ float: 'right' }}>{`${lo}-${hi} of ${total}`}</div>
                        </>}
                    />
                </div>
            
            }
        </div>
    );
}

export default ListingsBrowserComponent;