import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import listingTypes from "../json_information/listing_info.json";
import DetailedAttribute from "./DetailedAttributeComponent";
import DragAndDrop from "../screens/Common/DragAndDrop";
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";
import { getCsrfCookieValue } from "../utils";

function Create() {
    const navigate = useNavigate();

    const handleClearPage = () => {
        navigate(0);
    };

    const printDate = (date, addExtraDays = 0) => {
        // Confirm date is actually a Date object
        let d = new Date(date);
        d.setDate(d.getDate() + addExtraDays);
        let local = d.toLocaleDateString('en-us');
        let [month, day, year] = local.split("/");

        // Fix the date for front-end purposes.
        if (day.length == 1) day = "0" + day;
        if (month.length == 1) month = "0" + month;

        let output = [year, month, day].join("-");
        return output;
    };

    const printTime = (date, addExtraHours = 0) => {
        let d = new Date(date);
        d.setHours(d.getHours() + addExtraHours);

        // let output = d.toISOString().substring(11, 16);
        let output = d.toTimeString().substring(0, 5);
        return output;
    };

    // Start Date Trigger
    const [startASAP, setStartASAP] = useState(true);

    const [startDate, setStartDate] = useState(printDate(new Date()));
    const [endDate, setEndDate] = useState(printDate(new Date(), 5));
    const [startTime, setStartTime] = useState(printTime(new Date(), 1));
    const [endTime, setEndTime] = useState(printTime(new Date()));

    /* Handle an upload when Submit is clicked
        ToDo:
            - UI notification when the form fails
            - Product ID is included in this form... Maybe it should be handled on the backend?
            - Handle Cookie information to include "who" uploaded the listing for tracking information. 
            - Upload MULTIPLE Images. Currently it's just one. This needs to be addressed on Django as well.
    */

    // Better React Setup / converting to UseStates
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [thc, setThc] = useState("");
    const [cbd, setCbd] = useState("");
    const [quality, setQuality] = useState("");
    const [seeds, setSeeds] = useState("N/A");
    const [extracts, setExtracts] = useState("N/A");
    const [finished, setFinished] = useState("N/A");
    const [strain, setStrain] = useState("N/A");
    const [biomass, setBiomass] = useState("N/A");
    const [env, setEnv] = useState("N/A");
    const [trim, setTrim] = useState("N/A");
    const [weightUnit, setWeightUnit] = useState("");
    const [weightInput, setWeightInput] = useState("");
    const [aucEnable, setAucEnable] = useState(false);
    const [binEnable, setBinEnable] = useState(false);
    const [aucPrice, setAucPrice] = useState("");
    const [binPrice, setBinPrice] = useState("");
    const [transportation, setTransportation] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    // User must re-enter OR not needed for input
    // const [time, setTime] = useState("");
    // const [auctionEnabled, setAuctionEnabled] = useState(false);
    // const [responseDATA, setResponseDATA] = useState({});
    // const [image, setImage] = useState(null);

    const uploadListing = (manySubmissions) => {
        let data = new FormData(); // Add everything to the FormData attribute

        // Add the form elements to the FormData() class
        data.append("name", name);
        data.append("description", desc);
        data.append("thc", thc);
        data.append("cbd", cbd);
        // DetailedElements
        data.append("quality", quality);
        data.append("seeds_plants", seeds);
        data.append("extracts", extracts);
        data.append("finished_products", finished);
        data.append("strain", strain);
        data.append("biomass", biomass);
        data.append("environment", env);
        data.append("trim", trim);
        //Weight
        data.append("weight_unit", weightUnit);
        data.append("lot_weight", weightInput);

        // Images and files - todo get multiple working
        let imagesUpload = images;
        const ImageTypes =["image/jpeg", "image/png"]

        // console.log("imageUpload", imagesUpload);

        if (imagesUpload.length === 0) {
            // console.log("NO IMAGES");
            data.append("images", "");
        } else {
            for (let i in imagesUpload) {
                if(ImageTypes.includes(imagesUpload[i].type)){
                    data.append(`image${i}`, imagesUpload[i]);
                }
            }
        }

        let filesUpload = files;

        if (filesUpload.length === 0) {
            // console.log("NO FILES");
            data.append("files", "");
        } else {
            // console.log("FILES", filesUpload.files);
            for (let i in filesUpload) {
                if(["application/pdf"].includes(filesUpload[i].type)){
                    data.append(`file${i}`, filesUpload[i]);
                }
                // console.log("UPLOADED 1 FILE");
            }
        }

        // Auction Info
        data.append("auction_enable", aucEnable);
        data.append("auction_price", aucPrice);

        // BIN Info
        data.append("bin_enable", binEnable);
        data.append("bin_price", binPrice);

        // Start Date
        data.append("start_datetime", new Date(startDate + " " + startTime).toISOString());
        // End Date
        data.append("end_datetime", new Date(endDate + " " + endTime).toISOString());
        data.append("start_asap", startASAP);

        // Transportation
        data.append("transportation", transportation);
        data.append("zip_code", zipcode);

        /* --------------- INPUT CHECKING AND ALERTS -------------- */
        let uiValid = true;
        let starting = Date();
        let ending = Date();

        // {API tag --> Alert message}
        let alert_messages = {
            name: "Name",
            description: "Description",
            thc: "THC Content %",
            cbd: "CBD Content %",
            quality: "Quality level",
            seeds_plants: "Seeds/Plants",
            extracts: "Extracts",
            finished_products: "Finished products",
            strain: "Strain Type",
            biomass: "Biomass",
            environment: "Environment",
            trim: "Trim",
            weight_unit: "Lot weight unit",
            lot_weight: "Lot weight value",
            images: "Image upload",
            files: "File upload",
            auction_enable: "Enable timed auction",
            auction_price: "Auction starting price",
            bin_enable: "Enable buy-it-now",
            bin_price: "BIN price",
            start_datetime: "Invalid start date or start time (check all inputs)",
            end_datetime: "Invalid end date or end time (check all inputs)",
            transportation: "Transportation",
            zip_code: "Zip code",
            seller_id: "Seller ID (ensure you are logged in)",
        };

        // Reset ALL alerts
        for (let i in alertsKV) {
            alertsKV[i]("");
        }

        for (const pair of data.entries()) {
            let key = pair[0];
            let value = pair[1];

            // This should tie right into the k/v alerts array below...

            // Check for default "Open this select menu" (this is front-end only)
            if (value === "Open this select menu") {
                alertsKV[key](alert_messages[key] + " (select an option)");
                uiValid = false;
            }

            // Invalid Date/time problems (will catch if inputs are bad)
            if (key === "start_datetime") {
                if (value === "null") {
                    alertsKV[key](alert_messages[key]);
                    uiValid = false;
                    starting = null;
                } else {
                    starting = new Date(value);
                }
            }
            if (key === "end_datetime") {
                if (value === "null") {
                    alertsKV[key](alert_messages[key]);
                    uiValid = false;
                    ending = null;
                } else {
                    ending = new Date(value);
                }
            }
        }

        // Confirm the start date is < end date
        if (starting !== null && ending !== null && starting >= ending) {
            alertsKV["start_datetime"]("Starting date/time is AFTER or equal to ending date/time");
            uiValid = false;
        }

        // Didn't select auction OR bin (todo - THIS MUST HAPPEN ON THE BACKEND AS WELL)
        if (!(data.get('auction_enable') == 'true' || data.get('bin_enable') == 'true')) {
            alertsKV["auction_enable"]("Must enable a timed auction and/or buy-it-now");
            uiValid = false;
        }

        // BIN price less than auction start price
        if (data.get('bin_enable') == 'true' && data.get('auction_enable') == 'true') {
            let bin_price = Number(data.get('bin_price')), auction_price = Number(data.get('auction_price'));
            if (bin_price < auction_price) {
                alertsKV["bin_enable"]("But-it-now price must be greater than starting price");
                uiValid = false;
            }
        }

        // Send the POST req if there are no errors with inputs!
        // Otherwise, show the alerts.
        if (uiValid) {
            // Testing
            let submitData = data;
            // let submitData = testData;

            axios
                .post(`${process.env.REACT_APP_URL}listings`, submitData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if ("SUCCESS" in res.data) {
                        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Listing created successfully.' });
                        if (!manySubmissions) {
                            navigate("/mylistings");
                        }
                    } else {
                        for (let i in res.data) {
                            alertsKV[i](alert_messages[i] + ": " + res.data[i]);
                        }
                    }
                })
                .catch((err) => {
                    showToastUtil({ status: TOAST_TYPE.ERROR });
                    console.log(err);
                });
        }
    };


    // Populate the Autofill section
    // todo - update with security in the future.
    const [priorListings, setPriorListings] = useState([]);

    // This is making two calls? I suppose it's fine for now, test in production.
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}get-user-listings`, {
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            })
            .then((data) => {
                setPriorListings(data.data);
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR });
                console.log(err);
            });
    }, []);

    // Then template in priorListings into the form with the same syntax as ListingBrowser!
    // Backend route time!
    const fillCreatePage = (product_id, name) => {
        // Optional replacement, but the first item in the autofilled form will BE the title, so not really necessary
        // document.getElementById("dropdownMenuButton1").innerHTML = name;

        // Call Axios, and fill the page!
        // I should probably use useStates and update the whole page...
        axios
            .get(`${process.env.REACT_APP_URL}listings`, {
                params: { product_id: product_id },
                headers: { "X-CSRFToken": getCsrfCookieValue() },
                withCredentials: true,
            })
            .then((res) => {
                console.log("Data for the item", res.data);
                // -----------(Set the page variables) ------------

                // Two scenarios.
                // 1. Call setQuality from fillCreatePage. This causes {quality} to re-render the DetailedAttribute and put the value into the correct position.
                // 2. Click on the DetailedAttribute internal, this calls the provided onChange{} method OF setQuality, which is called INSIDE the detailedAttribute, and passes the value selected UP to the parent setQuality, which then causes a re-render of {quality} and the above to occur.
                setQuality(res.data.quality);
                setSeeds(res.data.seeds_plants);
                setExtracts(res.data.extracts);
                setFinished(res.data.finished_products);
                setStrain(res.data.strain);
                setBiomass(res.data.biomass);
                setEnv(res.data.environment);
                setTrim(res.data.trim);

                setName(res.data.name);
                setDesc(res.data.description);
                setCbd(res.data.cbd);
                setThc(res.data.thc);
                setWeightUnit(res.data.weight_unit);
                setWeightInput(res.data.lot_weight);

                // Need some more careful handling for these flags -> if a prior listing does NOT have these, it might be null returned!
                if (res.data.auction_price === null) {
                    setAucPrice("");
                } else {
                    setAucPrice(res.data.auction_price);
                }

                if (res.data.bin_price === null) {
                    setBinPrice("");
                } else {
                    setBinPrice(res.data.bin_price);
                }

                setAucEnable(res.data.auction_enable);
                setBinEnable(res.data.bin_enable);

                setTransportation(res.data.transportation);
                setZipcode(res.data.zip_code);
            })
            .catch((err) => {
                showToastUtil({ status: TOAST_TYPE.ERROR });
                console.log(err);
            });
    };



    const clearPage = () => {
        handleClearPage();
        // setQuality("");
        // setSeeds("");
        // setExtracts("");
        // setFinished("");
        // setStrain("");
        // setBiomass("");
        // setEnv("");
        // setTrim("");
        // setName("");
        // setDesc("");
        // setCbd("");
        // setThc("");
        // setWeightUnit("");
        // setWeightInput("");
        // setAucEnable("");
        // setBinEnable("");
        // setAucPrice("");
        // setBinPrice("");
        // setTransportation("");
        // setZipcode("");
    };

    // Error Alerting
    const [nameAlert, setNameAlert] = useState("");
    const [descAlert, setDescAlert] = useState("");
    const [thcAlert, setThcAlert] = useState("");
    const [cbdAlert, setCbdAlert] = useState("");
    const [qualityAlert, setQualityAlert] = useState("");
    const [seedsAlert, setSeedsAlert] = useState("");
    const [extractsAlert, setExtractsAlert] = useState("");
    const [finishedProdAlert, setFinishedProdAlert] = useState("");
    const [strainAlert, setStrainAlert] = useState("");
    const [biomassAlert, setBiomassAlert] = useState("");
    const [envAlert, setEnvAlert] = useState("");
    const [trimAlert, setTrimAlert] = useState("");
    const [weightUnitAlert, setWeightUnitAlert] = useState("");
    const [weightAmtAlert, setWeightAmtAlert] = useState("");
    const [imagesAlert, setImagesAlert] = useState("");
    const [filesAlert, setFilesAlert] = useState("");
    const [aucEnableAlert, setAucEnableAlert] = useState("");
    const [binEnableAlert, setBinEnableAlert] = useState("");
    const [aucPriceAlert, setAucPriceAlert] = useState("");
    const [binPriceAlert, setBinPriceAlert] = useState("");
    const [startDateAlert, setStartDateAlert] = useState("");
    const [endDateAlert, setEndDateAlert] = useState("");
    const [transpoAlert, setTranspoAlert] = useState("");
    const [zipAlert, setZipAlert] = useState("");
    const [sellerIdAlert, setSellerIdAlert] = useState("");

    // k/v pair w/ serializer
    const alertsKV = {
        name: setNameAlert,
        description: setDescAlert,
        thc: setThcAlert,
        cbd: setCbdAlert,
        quality: setQualityAlert,
        seeds_plants: setSeedsAlert,
        extracts: setExtractsAlert,
        finished_products: setFinishedProdAlert,
        strain: setStrainAlert,
        biomass: setBiomassAlert,
        environment: setEnvAlert,
        trim: setTrimAlert,
        weight_unit: setWeightUnitAlert,
        lot_weight: setWeightAmtAlert,
        images: setImagesAlert,
        files: setFilesAlert,
        transportation: setTranspoAlert,
        zip_code: setZipAlert,
        seller_id: setSellerIdAlert,
        start_datetime: setStartDateAlert,
        end_datetime: setEndDateAlert,
        auction_enable: setAucEnableAlert,
        bin_enable: setBinEnableAlert,
        auction_price: setAucPriceAlert,
        bin_price: setBinPriceAlert,
    };

    return(
        <div className="container-fluid p-4">

           <div className="row mb-5 gx-5 gy-2">
                <div className="col-auto">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Autofill Prior Listings
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ maxHeight: "200px", height: "auto", overflow: "auto" }}>
                            {priorListings.map((l) => {
                                return (
                                    <li>
                                        <button className="dropdown-item" value={l.product_id} onClick={(e) => fillCreatePage(e.target.value, e.target.innerHTML)}>
                                            {l.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div className="col-auto">
                    <button className="btn btn-secondary" onClick={clearPage}>
                        Clear Inputs
                    </button>
                </div>

           </div>

            <div className="row mb-3 align-items-center gx-4 gy-3">
                <div className="col-auto">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="form-group mb-2">
                                <label htmlFor="Name" className="required">
                                    Item Name
                                </label> 
                                <input  type="text" className="form-control" value={name} placeholder="Enter item name" onChange={(e) => setName(e.target.value)} style={{ backgroundColor: "white"}}/>
                                <p className="error">{nameAlert}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-8 col-lg-9 ">
                    <div className="form-group mb-3">
                        <label htmlFor="Description" className="required">
                            Description
                        </label>
                        <textarea type = "text" className="form-control" style = {{width: "100%", backgroundColor: "white"}} value={desc}  onChange={(e) => setDesc(e.target.value)}
                                    placeholder="Enter description here"/>
                        <p className="error">{descAlert}</p>
                    </div>
                </div> 
            </div>

            <div className="row justify-content-center gx-5">
                <div className="col-sm-10 col-md-6 col-lg-5 p-3" >
                   <div className="row p-4">
                        <div className="card shadow-sm mb-5" style = {{borderRadius: "10px"}}>
                            <div className="card-body">
                                <div className="m-2 mt-3">
                                    <div className="row mb-5 justify-content-center">
                                        <div>
                                            <DragAndDrop fileList = {images} setFileList = {setImages} accept = ".png, .jpg, .jpeg"/>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div>
                                            <DragAndDrop fileList = {files} setFileList = {setFiles} accept = ".pdf"/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <fieldset className="p-3" style = {{borderStyle: "solid", borderRadius: "5px", borderColor:"black", borderWidth: "2px"}}>
                            <legend className="float-none w-auto p-2" style = {{fontSize: "1rem", fontWeight: 450}}> Pricing & Purchase Details </legend>
                            <div className="p-1">
                                <div className= "p-0" style={{ backgroundColor: "#E2E2E2" }}>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="col-4 exclude-col"><p className="ms-3 m-0 required">Enable timed auction</p></td>
                                                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                    <div className = "row gx-4 gy-2 align-items-center">
                                                        <div className="col-auto">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="auction-checkmark"
                                                                checked= {aucEnable}
                                                                onChange={(e) => {
                                                                    setAucEnable(!aucEnable);
                                                                    setAucPrice("");
                                                                }}
                                                                style={{ marginLeft: "5px" }}
                                                            />
                                                        </div>
                                                        <div className = "col-auto">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-label="Auction input"
                                                                placeholder="Enter starting price"
                                                                id="auction-price"
                                                                disabled={!aucEnable}
                                                                value={aucPrice}
                                                                onChange={(e) => setAucPrice(e.target.value.replace(/[^0-9]/g, ""))}
                                                                style = {aucEnable ? {backgroundColor: "white"} : {backgroundColor: "#E2E2E2"}}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <p className="error m-0">{aucEnableAlert}</p>
                                                    <p className="error m-0">{aucPriceAlert}</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="col-4 exclude-col"><p className="ms-3 m-0 required">Enable buy-it-now </p></td>
                                                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                    <div className = "row gx-4 gy-2 align-items-center">
                                                        <div className="col-auto">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="bin-checkmark"
                                                                checked={binEnable}
                                                                onChange={(e) => {
                                                                    setBinEnable(!binEnable);
                                                                    setBinPrice("");
                                                                }}
                                                                style={{ marginLeft: "5px" }}
                                                            />
                                                        </div>
                                                        <div className = "col-auto">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-label="BIN input"
                                                                placeholder="Enter BIN price"
                                                                id="bin-price"
                                                                disabled={!binEnable}
                                                                value={binPrice}
                                                                onChange={(e) => setBinPrice(e.target.value.replace(/[^0-9]/g, ""))}
                                                                style = {binEnable ? {backgroundColor: "white"} : {backgroundColor: "#E2E2E2"}}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <p className="error m-0">{binEnableAlert}</p>
                                                    <p className="error m-0">{binPriceAlert}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className = "row m-4">
                                        <div className="col-md text-center justify-content-center">
                                            <div className="form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="startdate-checkmark"
                                                    checked={startASAP}
                                                    onChange={() => setStartASAP(!startASAP)}
                                                />
                                            </div>
                                            <label
                                                className="form-check-label required"
                                                htmlFor="startdate-checkmark"
                                            >
                                                Start Listing ASAP
                                            </label>
                                            <small className="row text-center justify-content-center">
                                                De-select for custom start date/time.
                                            </small>
                                        </div>
                                    </div>

                                    <table className="table table-bordered">
                                        <tbody>
                                            {!startASAP &&
                                                <tr>
                                                    <td className="col-4 exclude-col"><p className="ms-3 m-0 required">Start Date & Time </p></td> 
                                                    <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                        <div className = "row gx-4 gy-2 align-items-center">
                                                            <div className="col-auto">
                                                                <input
                                                                    type="date"
                                                                    id="start-date"
                                                                    className="form-control"
                                                                    value={startDate}
                                                                    onChange={(e) => setStartDate(e.target.value)}
                                                                    style = {{backgroundColor: "white"}}
                                                                />
                                                            </div>
                                                            <div className = "col">
                                                                <input
                                                                    type="time"
                                                                    id="start-time"
                                                                    className="form-control"
                                                                    value={startTime}
                                                                    onChange={(e) => setStartTime(e.target.value)}
                                                                    style = {{backgroundColor: "white"}}
                                                                />
                                                            </div>
                                                            <p className="error m-0">{startDateAlert}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            <tr>
                                                <td className="col-4 exclude-col"><p className="ms-3 m-0 required">End Date & Time </p></td> 
                                                <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                    <div className = "row gx-4 gy-2 align-items-center">
                                                        <div className="col-auto">
                                                            <input
                                                                type="date"
                                                                id="end-date"
                                                                className="form-control"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                style = {{backgroundColor: "white"}}
                                                            />
                                                        </div>
                                                        <div className = "col">
                                                            <input
                                                                type="time"
                                                                id="end-time"
                                                                className="form-control"
                                                                value={endTime}
                                                                onChange={(e) => setEndTime(e.target.value)}
                                                                style = {{backgroundColor: "white"}}
                                                            />
                                                        </div>
                                                        <p className="error m-0">{endDateAlert}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </fieldset>
                   </div>
                   
                </div>
            
                <div className="col-lg-7 p-3 col-lg-pull-8" >
                    <fieldset className="p-3" style = {{borderStyle: "solid", borderRadius: "5px", borderColor:"black", borderWidth: "2px"}}>
                        <legend className="float-none w-auto p-2" style = {{fontSize: "1rem", fontWeight: 450}}> Product Details </legend>
                        <div className="p-1">
                            <div className= "p-0" style={{ backgroundColor: "#E2E2E2"}}>
                                <table className="table table-bordered" >
                                    <tbody style= {{borderRadius: "10px"}}>
                                        <tr>
                                            <td className="col-4 exclude-col"><p className="ms-3 m-1 required">THC Content</p></td>
                                            <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="thc"
                                                    placeholder="Enter THC Content %"
                                                    value={thc}
                                                    onChange={(e) => setThc(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style={{ backgroundColor: "white" }}
                                                />
                                                <p className="error m-0"> {thcAlert} </p>
                                            </td>
                                            
                                        </tr>
                                    
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">CBD Content</p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="cbd"
                                                    placeholder="Enter CBD Content %"
                                                    value={cbd}
                                                    onChange={(e) => setCbd(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style={{ backgroundColor: "white"}}
                                                />
                                                <p className="error m-0"> {cbdAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Quality Level</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="quality"
                                                    inputTag="quality-input"
                                                    options={listingTypes.quality_level}
                                                    value={quality}
                                                    onChange={(e) => setQuality(e)}
                                                />
                                                <p className="error m-0"> {qualityAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Seeds/Plants</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="seeds-plants"
                                                    inputTag="seeds-plants-input"
                                                    options={listingTypes.seed_plants}
                                                    value={seeds}
                                                    onChange={(e) => setSeeds(e)}
                                                />
                                                <p className="error m-0"> {seedsAlert} </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Extracts</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="extracts"
                                                    inputTag="extracts-input"
                                                    options={listingTypes.extracts}
                                                    value={extracts}
                                                    onChange={(e) => setExtracts(e)}
                                                />
                                                <p className="error m-0"> {extractsAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Finished Products</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="finished-products"
                                                    inputTag="finished-products-input"
                                                    options={listingTypes.finished_products}
                                                    value={finished}
                                                    onChange={(e) => setFinished(e)}
                                                />
                                                <p className="error m-0"> {finishedProdAlert} </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Strain Type</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="strain"
                                                    inputTag="strain-input"
                                                    options={listingTypes.strain_type}
                                                    value={strain}
                                                    onChange={(e) => setStrain(e)}
                                                />
                                                <p className="error m-0"> {strainAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Biomass</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="biomass"
                                                    inputTag="biomass-input"
                                                    options={listingTypes.biomass}
                                                    value={biomass}
                                                    onChange={(e) => setBiomass(e)}
                                                />
                                                <p className="error m-0"> {biomassAlert} </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Environment</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="environment"
                                                    inputTag="environment-input"
                                                    options={listingTypes.environment}
                                                    value={env}
                                                    onChange={(e) => setEnv(e)}
                                                />
                                                <p className="error m-0"> {envAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Trim</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="trim"
                                                    inputTag="trim-input"
                                                    options={listingTypes.trim}
                                                    value={trim}
                                                    onChange={(e) => setTrim(e)}
                                                />
                                                <p className="error m-0"> {trimAlert} </p>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Lot Weight</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <div className="row p-1 gx-2 gy-2">
                                                    <div className="col-md">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Weight option"
                                                            name="weight"
                                                            id="weight"
                                                            value={weightUnit}
                                                            onChange={(e) => setWeightUnit(e.target.value)}
                                                        >
                                                            <option defaultValue="Default">Open this select menu</option>
                                                            <option value="grams">Grams (g)</option>
                                                            <option value="ounces">Ounces (oz)</option>
                                                            <option value="pounds">Pounds (lb)</option>
                                                            <option value="N/A">N/A</option>
                                                        </select>
                                                        <p className="error m-0"> {weightUnitAlert} </p>
                                                    </div>
                                                    <div className="col-md">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="Other input field"
                                                            placeholder="Enter numeric weight"
                                                            id="weight-input"
                                                            value={weightInput}
                                                            style = {{backgroundColor: "white"}}
                                                            onChange={(e) => setWeightInput(e.target.value.replace(/[^0-9]/g, ""))}
                                                        />
                                                        <p className="error m-0"> {weightAmtAlert} </p>
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required"> Transportation Details </p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <textarea
                                                    className="form-control"
                                                    id="transportation"
                                                    rows="4"
                                                    placeholder="Enter transportation details here"
                                                    value={transportation}
                                                    onChange={(e) => setTransportation(e.target.value)}
                                                    style = {{backgroundColor: "white"}}
                                                ></textarea>
                                                <p className="error m-0">{transpoAlert}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required"> Departure Zip Code </p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="zip-code"
                                                    placeholder="Enter zip code"
                                                    value={zipcode}
                                                    onChange={(e) => setZipcode(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style = {{backgroundColor: "white"}}
                                                />
                                                <p className="error m-0">{zipAlert}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* <div className="d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary" onClick={() => uploadListing()}>
                                    Submit Edit Request
                                </button>
                            </div> */}

                            <div className="row gy-2 mt-5 mb-2 justify-content-end">
                                <div className="col-auto">
                                    <button type="submit"  className="btn btn-primary" onClick={() => uploadListing(true)} >
                                        List Item And Submit Another
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary" onClick={() => uploadListing(false)} >
                                        List Single Item
                                    </button>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    )
}

export default Create;