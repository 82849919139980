import React, {useEffect, useState} from 'react';
import './AboutUs.css';
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";
import { IntroVideoComponent } from './IntroVideo';

function AboutUsComponent() {
const [staticContent, setStaticContent] = useState();
  const fetchContent = () => {
        axios
        .get(`${process.env.REACT_APP_URL}static-content?name=About_Us`, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        })
        .then((data) => {
            setStaticContent(data.data.content);
        })
        .catch((err) => console.log(err));
   }
   if(staticContent == null)
     fetchContent();
    return (
        <>
            <div className='container-fluid p-0'>
                <div className='mainContainer'>
                    <div className='row'>
                        {/* <div className='col-6 mb-5 '>
                            <img class = 'img-fluid'src={defaultProductImage} width="500" height="380" />
                        </div> */}
                        <div className='col-12 mb-10 '>
                            <div className='textContainer'>
                                <h1>About Us</h1>
                                <IntroVideoComponent />
                                <div className="content" dangerouslySetInnerHTML={{__html: staticContent}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUsComponent