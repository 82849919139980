import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { getCsrfCookieValue } from "../../utils";
import ListingCard from "../Listings/ListingCard";

const CarouselComponent = ({ content, data, favourites }) => {
    const [dynamicData, setDynamicData] = useState([]);
    const [data1, setData1] = useState([]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30,
        },
    };

    const getData = () => {
        if (favourites) {
            Promise.all(
                data?.map((f) =>
                    axios.get(`${process.env.REACT_APP_URL}listings?product_id=${f.product_id}`, {
                        headers: {
                            "X-CSRFToken": getCsrfCookieValue(),
                        },
                        withCredentials: true,
                    })
                )
            )
                .then((data) =>
                    data.map((e) => {
                        // Injects the image data into the ListingCard component correctly
                        var data = e.data;
                        if (data.images !== null && data.images.length > 0)
                            data["image"] = data.images[0].file;

                        return { comp: <ListingCard listing={e.data}></ListingCard> };
                    })
                )
                .then((data) => setDynamicData(data));
        } else {
            console.log(data);

            var dynamic_data = [];

            data?.map((e) => {
                const tempObject = {
                    comp: <ListingCard listing={e}></ListingCard>,
                };
                dynamic_data.push(tempObject);
            });

            // I strongly dislike the way this file was designed...
            setData1([...dynamic_data]);
        }

        // setDynamicData(dynamic_data)
    };

    useEffect(() => {
        // Get the data (if necessary for favorites functionality), and set the components.
        getData();

        // This line breaks everything
        // setData1(content === "static" ? static_data : dynamicData);
    }, []);

    //

    return (
        <>
            <Carousel
                swipeable={false}
                draggable={false}
                // showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {favourites === true
                    ? dynamicData.map((e, i) => {
                          return <div key={i} className="l-3">{e.comp}</div>;
                      })
                    : data1.map((e, i) => {
                          return <div key={i} className="l-3">{e.comp}</div>;
                      })}
            </Carousel>
        </>
    );
};

export default CarouselComponent;
