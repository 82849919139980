import React from 'react';
import { Link } from "react-router-dom";
function PasswordResetSuccessComponent() {
    return (
        <>
            <div className="card vw-100">
                <div className="card-body text-center">Your password has been reset successfully.
                <br></br>
                    <Link to="/login">
                        <p className="log-in text-right mt-2">Go to Login</p>
                    </Link>
                </div>

            </div>
        </>
    )
}

export default PasswordResetSuccessComponent