import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCsrfCookieValue } from "../utils";
import { Row, Col, Card, Button } from 'antd';
import InvoiceActionsComponent from './InvoiceActionsComponent'

const IndividualUser = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateViewAllListings = () => {
        navigate("/admin", { state: '1' });
    };

    const readableTime = (date) => {
        let day = date.toDateString();
        // console.log(date.toTimeString().split(" ")[0].split(":"));
    
        let [hours, minutes, seconds] = date.toTimeString().split(" ")[0].split(":");
    
        hours = parseInt(hours);
        let ampm = "A.M.";
    
        if (hours == 12) {
            ampm = "P.M.";
        } else if (hours > 12) {
            hours -= 12;
            ampm = "P.M";
        } else if (hours == 0) {
            ampm = "A.M.";
            hours = 12;
        }
    
        return day + " " + hours + ":" + minutes + " " + ampm + " ";
    };

    function DeleteListing() {
        axios
            .post(
                `${process.env.REACT_APP_URL}delete-listing`,
                { product_id: location.state.product_id },
                {
                    headers: {
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                }
            )
            // .then(console.log("deleted Listing", location.state))
            // .catch((err) => console.log(err));

        navigate("/admin/view-all-listings");
    }

    const [listingTable, setListingTable] = useState("loadingListing");
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}listings?product_id=` + String(location.state.product_id), {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((data) => setListingTable(data.data))
            // .catch((err) => console.log(err));
    }, []);

    const [showInvoiceActionsPopup, toggleInvoiceActionsPopup] = useState(false);
    const showInvoiceActions = () => {
        toggleInvoiceActionsPopup(true);
    }
    const onCloseInvoiceActions = () => {
        toggleInvoiceActionsPopup(false);
    }

    return(
        <React.Fragment>
        <div style = {{padding: "20px"}}>
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> Auction List</h5>
                            <Button onClick = {navigateViewAllListings} size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Go back to Auction List</Button>
                            {
                                listingTable?.is_auc_running === false &&
                                <Button onClick = {showInvoiceActions} size = "middle" style = {{background: '#738FFF', color: "white", marginLeft: "15px"}}>Invoice Actions</Button>
                            }
                            <Button onClick = {DeleteListing} size = "middle" style = {{background: '#FF7575', color: "white", marginLeft: "15px"}}>Delete Listing</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row justify = "center" style = {{marginTop: "10px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <Row justify = "space-around">
                            <Col xs = {24} md = {10}>
                                <fieldset className="p-3" style = {{borderStyle: "solid", borderRadius: "5px", borderColor:"black", borderWidth: "2px"}}>
                                    <legend className="float-none w-auto p-2" style = {{fontSize: "1rem", fontWeight: 450}}> Bidding and Seller Details </legend>
                                    <div className="p-1">
                                        <div className= "p-0" style={{ backgroundColor: "#E2E2E2" }}>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-6 exclude-col"><p className="ms-3 m-1">Product ID</p></td>
                                                        <td className="col-6 exclude-col" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{location.state.product_id}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Current Auction Price</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{String(listingTable.auction_price)}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Title</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.name}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Description</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.description}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Start Datetime</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{readableTime(new Date(listingTable.start_datetime))}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">End Datetime</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{readableTime(new Date(listingTable.end_datetime))}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Auction Enabled</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{String(listingTable.auction_enable)}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Auction Price</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.auction_price}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Buy it now enabled</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{String(listingTable.bin_enable)}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">BIN price</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.bin_price}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Auction running</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{String(listingTable.is_auc_running)}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1"> Seller ID </p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.seller_id}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1"> Departure Zip Code </p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.zip_code}</p></td>
                                                    </tr>
                                                    

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>      
                                </fieldset>
                            </Col>
                            <Col xs = {24} md = {10}>
                                <fieldset className="p-3" style = {{borderStyle: "solid", borderRadius: "5px", borderColor:"black", borderWidth: "2px"}}>
                                    <legend className="float-none w-auto p-2" style = {{fontSize: "1rem", fontWeight: 450}}> Product Details </legend>
                                    <div className="p-1">
                                        <div className= "p-0" style={{ backgroundColor: "#E2E2E2" }}>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-6 exclude-col"><p className="ms-3 m-1">THC Content</p></td>
                                                        <td className="col-6 exclude-col" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.thc}%</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">CBD Content</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.cbd}%</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Quality Level</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.quality}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Seeds/Plants</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.seeds_plants}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Extracts</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.extracts}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Finished Products</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.finished_products}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Strain Type</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.strain}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Biomass</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.biomass}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Enviornment</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.environment}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Trim</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.trim}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1">Lot Weight</p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.lot_weight} {listingTable.weight_unit}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1"> Transportation Details </p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.seller_id}</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-6"><p className="ms-3 m-1"> Departure Zip Code </p></td>
                                                        <td className="col-6" style={{ backgroundColor: "white" }}><p className="ms-3 m-1">{listingTable.zip_code}</p></td>
                                                    </tr>
                                                    

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>      
                                </fieldset>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
        {
            showInvoiceActionsPopup && 
            <InvoiceActionsComponent showModal={showInvoiceActionsPopup} closeCallback={() => onCloseInvoiceActions()} />
        }
        </React.Fragment>
    )

    return (
        <div>
            <div className="row">
                <div className="col-md-2 bg-light border border-dark py-3">
                    <h3 className="text-center">User Details</h3>
                    <hr />
                    <div className="lead px-3">
                        <p>
                        
                            <b>Seller Id: </b> {listingTable.seller_id}
                            <br />
                            <b>Zip Code: </b> {listingTable.zip_code}
                            <br />
                        </p>
                    </div>
                </div>

                <div className="col-md-10 bg-light border border-dark py-3">
                    {/*Product detail text  */}
                    <div className="lead px-3">
                        <p>
                            <div className="row">
                                <div className="col-md-5">
                                    <h3 className="text-center">Auction Details</h3>
                                    <hr />
                                    <b>Product ID: </b>{location.state.product_id}
                                    <br />
                                    <b>Current Auction Price: </b>{String(listingTable.auction_price)}
                                    <br />
                                    <b>Name: </b> {listingTable.name}
                                    <br />
                                    <b>Description: </b> {listingTable.description}
                                    <br />
                                    <b>Start Datetime: </b>{readableTime(new Date(listingTable.start_datetime))}
                                    <br />
                                    <b>End Datetime:  </b>{readableTime(new Date(listingTable.end_datetime))}
                                    <br />
                                    <b>Auction Enabled: </b>{String(listingTable.auction_enable)}
                                    <br />
                                    <b>Auction Price: </b>{listingTable.auction_price}
                                    <br />
                                    <b>Buy it Now Enabled: </b>{String(listingTable.bin_enable)}
                                    <br />
                                    <b>BIN Price:  </b>{listingTable.bin_price}
                                    <br />
                                    <b>Auction Running:  </b>{String(listingTable.is_auc_running)}
                                </div>
                                <div className="col-md-5">
                                    <h3 className="text-center">Product Details</h3>
                                    <hr />
                                    <b>Biomass: </b> {listingTable.biomass}
                                    <br />
                                    <b>THC: </b> {listingTable.thc}%
                                    <br />
                                    <b>CBD: </b> {listingTable.cbd}%
                                    <br />
                                    <b>Enviornment: </b> {listingTable.environment}
                                    <br />
                                    <b>Extracts: </b> {listingTable.extracts}
                                    <br />
                                    <b>Finished Products: </b> {listingTable.finished_products}
                                    <br />
                                    <b>Lot Weight: </b> {listingTable.lot_weight}
                                    <br />
                                    <b>Quality: </b> {listingTable.quality}
                                    <br />
                                    <b>Seeds/Plants: </b> {listingTable.seeds_plants}
                                   
                                    <br />
                                    <b>Strain: </b> {listingTable.strain}
                                    <br />
                                    <b>Trim: </b> {listingTable.trim}
                                    <br />
                                    <b>Weight Unit: </b> {listingTable.weight_unit}
                                    <br />
                                </div>
                                <div className="col-md-2">
                                    <h3 className="text-center">Other</h3>
                                    <hr />
                                    {/* Files : TODO
                                    <br /> */}
                                    <b>ID: </b> {listingTable.id}
                                    <br />
                                    <b>Transportation: </b> {listingTable.transportation}
                                    <br />
                                    {/* Images: {listingTable.images} */}
                               
                                    <br />
                                </div>
                            </div>
                        </p>
                    </div>
                </div>

                <div className="d-flex justify-content-between my-3">
                    <button
                        className="btn btn-info col-md-3 mx-5"
                        onClick={navigateViewAllListings}
                    >
                        Return to all Listings Page
                    </button>

                    <button className="btn btn-danger col-md-3 mx-5 " onClick={DeleteListing}>
                        Remove this Listing from site 
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IndividualUser;
