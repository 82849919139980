import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tag, Row, Col, Card } from 'antd';
import AntTable from "./tables/AntTable.jsx";
import { Button, Divider, notification, Space } from 'antd';
import { getCsrfCookieValue } from "../utils";

//I used this tutorial to create the searchable table
//https://www.youtube.com/watch?v=MY6ZZIn93V8&list=LL&index=2&t=1002s

const ViewAllUsers = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
      api.success({
        message: `User Approval`,
        description:
          'User request has been approved.',
        placement,
        duration: 2
      });
    };
    
    // navigate("/admin", { state: '3' });

    const [listingsTable, setUsersTable] = useState([]);

    const getAllUsers = () => {
        axios
        .get(`${process.env.REACT_APP_URL}unregister-user`, {
            headers: {
                "X-CSRFToken": getCsrfCookieValue(),
            },
            withCredentials: true,
        })
        .then((data) => setUsersTable(data.data))
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = listingsTable.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(listingsTable.length / recordsPerPage)

   const columnInfo = [{name: "User Name", width: "20%"}, {name: "First_Name", width: "20%"}, {name: "Last_Name", width: "20%"}]

   const data = listingsTable.filter(item => item.is_deleted != true).map(item => {
    return {
        key: item.username,
        username: item.username,
        first_name: item.first_name,
        last_name: item.last_name,
    }
   })

   console.log("data", data);

   const handleReject = (username) => {
        axios
        .post(
            `${process.env.REACT_APP_URL}reject-user`,
            { username: username },
            {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            }
        ).then((res) => {
            console.log("res", res);
            getAllUsers();
        })
   }

   const handleView = (username) => {
    // navigate("/admin/individual-listing", { state: { username } });
    console.log("username in handleview", username);
    navigate("/admin/new-users/"+username, { state: { username } });
    // console.log("handleView called in parent component", productId);
   } 

    const handleApprove = (username) => {
        console.log("username in handleApprove", username);
        let input_data = {
            username: username,
        };
        axios
            .post(`${process.env.REACT_APP_URL}verify`, input_data, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                console.log(res);
                // navigate("/admin/");
                getAllUsers();
                openNotification('topRight');
                // alert("Approved");
            })
            .catch((err) => console.log(err));
    } 

   return(
        <div style = {{padding: "0px"}}>
            {contextHolder}
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {24} md = {22}>
                    <Card style={{ width: "100%"}} bodyStyle = {{padding: "10px"}}>
                        <div style = {{display: "flex", alignItems: "center"}}>
                            <h5 style = {{margin: "0px", padding: "5px"}}> New Users </h5>
                        </div>
                    </Card>
                </Col>
            </Row>
            
            <Row justify = "center" style = {{marginTop: "20px"}}>
                <Col xs = {23} md = {22}>
                    <AntTable data = {data} columnInfo = {columnInfo} actions = {['View', 'Approve', 'Reject']} handleView = {handleView} handleApprove = {handleApprove} handleReject = {handleReject} actionWidth = "20%"/>
                </Col>
            </Row>
        </div>
    )
};

export default ViewAllUsers;
