import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DetailedAttribute from "./DetailedAttributeComponent";
import { getCsrfCookieValue } from "../utils";
import listingTypes from "../json_information/listing_info.json";
import { showToastUtil, TOAST_TYPE } from "../components/Banner/BannerToastUtil";
import Carousel from "../screens/Common/Carousel"


function Edit() {
    let routeParams = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [thc, setThc] = useState("");
    const [cbd, setCbd] = useState("");
    const [quality, setQuality] = useState("");
    const [seeds, setSeeds] = useState("");
    const [extracts, setExtracts] = useState("");
    const [finished, setFinished] = useState("");
    const [strain, setStrain] = useState("");
    const [biomass, setBiomass] = useState("");
    const [env, setEnv] = useState("");
    const [trim, setTrim] = useState("");
    const [weightUnit, setWeightUnit] = useState("");
    const [weightInput, setWeightInput] = useState("");
    const [aucEnable, setAucEnable] = useState(false);
    const [binEnable, setBinEnable] = useState(false);
    const [aucPrice, setAucPrice] = useState("");
    const [binPrice, setBinPrice] = useState("");
    const [transportation, setTransportation] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [reasons, setReasons] = useState("");
    const [startASAP, setStartASAP] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [alertsToggle, setAlertsToggle] = useState("");
    const [images, setImages] = useState([]);

    const printDate = (date) => {
        // Confirm date is actually a Date object
        let d = new Date(date);
        let local = d.toLocaleDateString();
        let [month, day, year] = local.split("/");

        let output = [year, month, day].join("-");
        // console.log(output);
        return output;
    };

    const printTime = (date) => {
        let d = new Date(date);

        // let output = d.toISOString().substring(11, 16);
        let output = d.toTimeString().substring(0, 5);
        // console.log(output);
        return output;
    };

    const [startDate, setStartDate] = useState(printDate(new Date()));
    const [endDate, setEndDate] = useState(printDate(new Date()));
    const [startTime, setStartTime] = useState(printTime(new Date()));
    const [endTime, setEndTime] = useState(printTime(new Date()));

    // Extra UI so that the endDate is visually 5 days further than startDate
    const [d, setD] = useState(true);
    if (d) {
        setD(false);
        let temp = new Date(startDate + " " + startTime);
        temp.setDate(temp.getDate() + 5);

        setEndDate(printDate(temp));
    }

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL}editlisting?product_id=${routeParams.product_id}`, {
                headers: {
                    "X-CSRFToken": getCsrfCookieValue(),
                },
                withCredentials: true,
            })
            .then((res) => {
                setFromData(res.data);
            })
            .catch((error) => {
                showToastUtil({ status: TOAST_TYPE.ERROR });
                console.error(error);
            });
    }, []);

    const setFromData = (data) => {
        setName(data.name);
        setDesc(data.description);
        setThc(data.thc);
        setCbd(data.cbd);
        setQuality(data.quality);
        setSeeds(data.seeds_plants);
        setExtracts(data.extracts);
        setFinished(data.finished_products);
        setStrain(data.strain);
        setBiomass(data.biomass);
        setEnv(data.environment);
        setTrim(data.trim);
        setWeightUnit(data.weight_unit);
        setWeightInput(data.lot_weight);
        setAucEnable(data.auction_enable);
        setAucPrice(data.auction_price);
        setBinEnable(data.bin_enable);
        setBinPrice(data.bin_price);
        setTransportation(data.transportation);
        setZipcode(data.zip_code);
        setImages(data.images);
        // setReasons(data.reason_for_change);
        // setStartASAP(false);
        // console.log(data);
    };
    /* Handle an upload when Submit is clicked
        ToDo:
            - UI notification when the form fails
            - Product ID is included in this form... Maybe it should be handled on the backend?
            - Handle Cookie information to include "who" uploaded the listing for tracking information. 
            - Upload MULTIPLE Images. Currently it's just one. This needs to be addressed on Django as well.
    */
   
    const missingFields = () => {
        let fields = {name: "name", desc: "description", thc: "THC Content", cbd: "CBD Content", quality: "Quality Level",
                        seeds:  "Seeds/Plants", extracts: "Extracts", finished: "Finished Products", strain: "Strain Type",
                        biomass: "Biomass", env: "Environment", trim: "Trim", weightInput: "Lot Weight", transportation: "Transportation Details",
                        zipcode: "Departure Zip Code", reasons: "Reasoning for Change"} 
        
        let missing_fields = []

        for (const key in fields) {
            if(eval(key) == undefined || eval(key) == ""){
                
                missing_fields.push(fields[key]);
            }
        }

        if(missing_fields.length != 0){
            setAlertsToggle("Missing Fields");
            setAlerts(missing_fields);

            return true;
        }

        return false;
    }


    const uploadListing = () => {
        if(missingFields()) return;
        
        let data = new FormData(); // Add everything to the FormData attribute

        data.append("name", name);
        data.append("description", desc);
        data.append("thc", thc);
        data.append("cbd", cbd);

        data.append("quality", quality);
        data.append("seeds_plants", seeds);
        data.append("extracts", extracts);
        data.append("finished_products", finished);
        data.append("strain", strain);
        data.append("biomass", biomass);
        data.append("environment", env);
        data.append("trim", trim);

        //Weight
        data.append("weight_unit", weightUnit);
        data.append("lot_weight", weightInput);
        data.append("images", JSON.stringify(images));


        // Transportation
        data.append("transportation", transportation);
        data.append("zip_code", zipcode);
        data.append("reason_for_change", reasons);
        data.append("product_id", routeParams.product_id);
        let imagesUpload = document.getElementById("images-upload");
        const ImageTypes =["image/jpeg", "image/png"]
        if (imagesUpload.files.length === 0) {
            // console.log("NO IMAGES");
            data.append("new_images", "");
        } else {
            for (let i in imagesUpload.files) {
                if(ImageTypes.includes(imagesUpload.files[i].type)){
                    data.append(`new_image${i}`, imagesUpload.files[i]);
                }
            }

        }

        /* --------------- INPUT CHECKING AND ALERTS -------------- */
        let alert_var = [];
        let pricing_enabled = false;
        let starting = Date();
        let ending = Date();

        // {API tag --> Alert message}
        let alert_messages = {
            name: "Name",
            description: "Description",
            thc: "THC Content %",
            cbd: "CBD Content %",
            quality: "Quality level",
            seeds_plants: "Seeds/Plants",
            extracts: "Extracts",
            finished_products: "Finished products",
            strain: "Strain Type",
            biomass: "Biomass",
            environment: "Environment",
            trim: "Trim",
            weight_unit: "Lot weight unit",
            lot_weight: "Lot weight value",
            images: "Image upload",
            files: "File upload",
            auction_enable: "Enable timed auction",
            auction_price: "Auction starting price",
            bin_enable: "Enable buy-it-now",
            bin_price: "BIN price",
            start_datetime: "Invalid start date or start time (check all inputs)",
            end_datetime: "Invalid end date or end time (check all inputs)",
            transportation: "Transportation",
            zip_code: "Zip code",
            seller_id: "Seller ID (ensure you are logged in)",
            reason_for_change: "Reasoning for change",
            general: "General alert",
            product_id: "Product ID",
        };


        for (const pair of data.entries()) {
            let key = pair[0];
            let value = pair[1];

            // Check for default "Open this select menu" (this is front-end only)
            if (value === "Open this select menu") {
                alert_var.push(alert_messages[key] + " (select an option)"); // Automatically fills in error field.
            }

            // Invalid Date/time problems (will catch if inputs are bad)
            // if (key === "start_datetime") {
            //     if (value === "null") {
            //         alert_var.push(alert_messages[key]);
            //         starting = null;
            //     } else {
            //         starting = new Date(value);
            //     }
            // }
            // if (key === "end_datetime") {
            //     if (value === "null") {
            //         alert_var.push(alert_messages[key]);
            //         ending = null;
            //     } else {
            //         ending = new Date(value);
            //     }
            // }
        }

        // Confirm the start date is < end date
        // if (starting !== null && ending !== null && starting >= ending) {
        //     alert_var.push("Starting date/time is AFTER or equal to ending date/time");
        // }

        // Didn't select auction OR bin cont.
        // if (!pricing_enabled) {
        //     alert_var.push("Must enable a timed auction and/or buy-it-now");
        // }

        // Send the POST req if there are no errors with inputs!
        // Otherwise, show the alerts.
        if (alert_var.length !== 0) {
            setAlertsToggle("Form Submission Errors:");
            setAlerts(alert_var);
        } else {
            setAlertsToggle("");
            setAlerts([]);

            // Testing
            let submitData = data;
            // let submitData = testData;

            axios
                .post(`${process.env.REACT_APP_URL}editlisting`, submitData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-CSRFToken": getCsrfCookieValue(),
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if ("SUCCESS" in res.data) {
                        showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Update requested successfully, pending Admin approval.' });
                        navigate(`/view-listing/${routeParams.product_id}`);
                    } else {
                        let temp = [];

                        for (let i in res.data) {
                            temp.push(alert_messages[i] + ": " + res.data[i]);
                        }

                        setAlertsToggle("Form Submission Errors");
                        setAlerts(temp);
                    }
                })
                .catch((err) => {
                    showToastUtil({ status: TOAST_TYPE.ERROR });
                    console.log(err);
                });
        }
    };


    const removeImage = (id) => {
      setImages((oldState)=>oldState.filter((image)=> image.id !== id))
    }


    return(
        <div className="container-fluid p-4">
            <div className="row mb-3 align-items-center gx-4 gy-3">
                <div className="col-auto">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="form-group mb-2">
                                    <label htmlFor="Name" className="required">
                                        Name
                                    </label>
                                    <input type = "text" className="form-control" value={name}  onChange={(e) => setName(e.target.value)}
                                        style={{ backgroundColor: "white"}} />
                                </div>
                            </div>
                        </div>
                </div>
        
                <div className="col-md-8 col-lg-9 ">
                    <div className="form-group mb-3">
                        <label htmlFor="Description" className="required">
                            Description
                        </label>
                        <textarea type = "text" className="form-control" style = {{width: "100%", backgroundColor: "white"}} value={desc}  onChange={(e) => setDesc(e.target.value)}/>
                    </div>
                </div> 
            </div>


            <div className="row justify-content-center gx-5">
                <div className="col-sm-10 col-md-6 col-lg-4 p-3 " >
                    <div className="card shadow-sm" style = {{borderRadius: "10px"}}>
                        <div className="card-body">
                            <div className="m-2 mt-3">
                                <Carousel images = {images} canDelete = {true} removeImage = {removeImage}/>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="images-upload"
                                    accept="image/x-png,image/jpg,image/jpeg,image/svg"
                                    multiple
                                />
                                <small id="image-help" className="form-text text-muted">
                                    Supported file types: .jpeg, .png, .jpg, and .svg
                                </small>
                            </div>
                            
                        </div>        
                    </div> 
                </div>

                <div className="col-lg-8 p-3 col-lg-pull-8" >
                    <fieldset className="p-3" style = {{borderStyle: "solid", borderRadius: "5px", borderColor:"black", borderWidth: "2px"}}>
                        <legend className="float-none w-auto p-2" style = {{fontSize: "1rem", fontWeight: 450}}> Product Details </legend>
                        <div className="p-1">
                            <div className= "p-0" style={{ backgroundColor: "#E2E2E2" }}>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="col-4 exclude-col"><p className="ms-3 m-1 required">THC Content</p></td>
                                            <td className="col-8 exclude-col p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="thc"
                                                    placeholder="Enter THC Content %"
                                                    value={thc}
                                                    onChange={(e) => setThc(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style={{ backgroundColor: "white" }}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">CBD Content</p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="cbd"
                                                    placeholder="Enter CBD Content %"
                                                    value={cbd}
                                                    onChange={(e) => setCbd(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style={{ backgroundColor: "white"}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Quality Level</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="quality"
                                                    inputTag="quality-input"
                                                    options={listingTypes.quality_level}
                                                    value={quality}
                                                    onChange={(e) => setQuality(e)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Seeds/Plants</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="seeds-plants"
                                                    inputTag="seeds-plants-input"
                                                    options={listingTypes.seed_plants}
                                                    value={seeds}
                                                    onChange={(e) => setSeeds(e)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Extracts</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="extracts"
                                                    inputTag="extracts-input"
                                                    options={listingTypes.extracts}
                                                    value={extracts}
                                                    onChange={(e) => setExtracts(e)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Finished Products</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="finished-products"
                                                    inputTag="finished-products-input"
                                                    options={listingTypes.finished_products}
                                                    value={finished}
                                                    onChange={(e) => setFinished(e)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Strain Type</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="strain"
                                                    inputTag="strain-input"
                                                    options={listingTypes.strain_type}
                                                    value={strain}
                                                    onChange={(e) => setStrain(e)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Biomass</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="biomass"
                                                    inputTag="biomass-input"
                                                    options={listingTypes.biomass}
                                                    value={biomass}
                                                    onChange={(e) => setBiomass(e)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Environment</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="environment"
                                                    inputTag="environment-input"
                                                    options={listingTypes.environment}
                                                    value={env}
                                                    onChange={(e) => setEnv(e)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Trim</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <DetailedAttribute
                                                    tag="trim"
                                                    inputTag="trim-input"
                                                    options={listingTypes.trim}
                                                    value={trim}
                                                    onChange={(e) => setTrim(e)}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required">Lot Weight</p></td>
                                            <td className="col-8" style={{ backgroundColor: "white" }}>
                                                <div className="row p-1 gx-2 gy-2">
                                                    <div className="col-md">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Weight option"
                                                            name="weight"
                                                            id="weight"
                                                            value={weightUnit}
                                                            onChange={(e) => setWeightUnit(e.target.value)}
                                                        >
                                                            <option defaultValue="Default">Open this select menu</option>
                                                            <option value="grams">Grams (g)</option>
                                                            <option value="ounces">Ounces (oz)</option>
                                                            <option value="pounds">Pounds (lb)</option>
                                                            <option value="N/A">N/A</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            aria-label="Other input field"
                                                            placeholder="Enter numeric weight"
                                                            id="weight-input"
                                                            value={weightInput}
                                                            style = {{backgroundColor: "white"}}
                                                            onChange={(e) => setWeightInput(e.target.value.replace(/[^0-9]/g, ""))}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required"> Transportation Details </p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <textarea
                                                    className="form-control"
                                                    id="transportation"
                                                    rows="4"
                                                    placeholder="Enter transportation details here"
                                                    value={transportation}
                                                    onChange={(e) => setTransportation(e.target.value)}
                                                    style = {{backgroundColor: "white"}}
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4"><p className="ms-3 m-1 required"> Departure Zip Code </p></td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="zip-code"
                                                    placeholder="Enter zip code"
                                                    value={zipcode}
                                                    onChange={(e) => setZipcode(e.target.value.replace(/[^0-9]/g, ""))}
                                                    style = {{backgroundColor: "white"}}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4">
                                                <p className="ms-3 m-1 required"> Reasoning for Change </p>
                                            </td>
                                            <td className="col-8 p-1" style={{ backgroundColor: "white" }}>
                                                <textarea
                                                    className="form-control"
                                                    id="reason"
                                                    rows="4"
                                                    placeholder="Enter reason for change here"
                                                    value={reasons}
                                                    onChange={(e) => setReasons(e.target.value)}
                                                    style = {{backgroundColor: "white"}}
                                                ></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary" onClick={() => uploadListing()}>
                                    Submit Edit Request
                                </button>
                            </div>

                            {alerts.length > 0 && 
                                <div className=" mb-3 mt-3 ">
                                    <h5 className="" style={{ color: "red" }}>
                                        {alertsToggle}
                                    </h5>
                                    {alerts.map((a) => {
                                        return <l className="required">{a}</l>;
                                    })}
                                </div>
                            }

                        </div>
                            
                    </fieldset>
                </div>

            </div>
        </div>

    )

}

export default Edit;